/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import StandardProps from '../../../theme/standard-props';
import { RequireOnlyOne } from '../../../types/util-types';
import Tooltip from '../../standard-components/elements/tooltip';
import { Flex } from '../../standard-components/layout/flex-box';
import Paragraph from '../../standard-components/typography/paragraph';

interface IOrderRowItem {
  tooltip?: boolean;
  item?: string;
  children?: any;
}
//Type That Forces that either one or the other be required.
type TOrderRowItem = RequireOnlyOne<IOrderRowItem, 'item' | 'children'> &
  StandardProps;

const OrderRowItem = ({
  item,
  tooltip,
  children,
  ...styles
}: TOrderRowItem) => {
  const [show, setShow] = useState(false);
  const [mousePosition, setMousePosition] = useState({
    xPos: 0,
    yPos: 0,
  });

  const handleMouseEnter = (event: any) => {
    if (tooltip && !show) {
      setMousePosition({
        xPos: event.clientX - event.target.offsetLeft,
        yPos: event.clientY - event.target.offsetTop,
      });
      setShow(true);
    }
  };
  const handleMouseLeave = (event: any) => {
    if (tooltip && show) {
      setShow(false);
    }
  };

  return (
    <>
      {children ? (
        children
      ) : (
        <Paragraph
          {...styles}
          borderRadius="0"
          m="0"
          px="0"
          variant="card_item_body"
        >
          <Flex
            noDefaultStyle
            position="relative"
            justifyContent="center"
            addCss={css`
              justify-content: center;
            `}
          >
            <span
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              css={css`
                &:hover {
                  cursor: ${tooltip && 'help'};
                }
              `}
            >
              {tooltip && (
                <Tooltip
                  yPos={mousePosition.yPos}
                  xPos={mousePosition.xPos}
                  show={show}
                  orientation="right"
                  text={item!}
                />
              )}
              {item && (item.length <= 20 ? item : `${item!.slice(0, 15)}...`)}
            </span>
          </Flex>
        </Paragraph>
      )}
    </>
  );
};

export default OrderRowItem;
