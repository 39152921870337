import React from 'react';
import { Product } from '../../../store/orders/orders-types';
import Image from '../../standard-components/elements/image';
import Grid from '../../standard-components/layout/grid';
import OrderRowItem from './OrderRowItem';

const ProductRowItem = ({ ...product }: Product) => {
  return (
    <Grid
      noDefaultStyle
      alignContent="center"
      gridTemplateColumns="repeat(7, 1fr)"
      alignItems="center"
      textAlign="center"
      width="100%"
    >
      <Image
        mx="auto"
        isSquare
        maxHeight="86px"
        maxWidth="86px"
        src={`${product.catalogueImage}`}
        alt="Order product catalogue"
      />
      <OrderRowItem tooltip item={product.productName} />
      <OrderRowItem item={product.variant.fabric} />
      <OrderRowItem item={product.variant.size} />
      <OrderRowItem item={product.variant.color} />
      <OrderRowItem item={`R${product.productPrice}`} />
      <OrderRowItem item={`${product.variant.quantity}`} />
    </Grid>
  );
};

export default ProductRowItem;
