import React, { useEffect, useMemo, useState } from 'react';
import { useGetAllOrdersQuery } from '../../store/orders/orders-api';
import { Order, OrderStatus } from '../../store/orders/orders-types';
import theme from '../../theme/theme';
import { dynamicSort } from '../../utils/orderSort';
import OrderHeader from '../orders/order-header';
import OrderItem from '../orders/order-item';
import { Flex } from '../standard-components/layout/flex-box';
import Heading from '../standard-components/typography/heading';

const Orders = ({ storeID }: { storeID: string }) => {
  const [sortBy, setSortBy] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('');
  const [showProducts, setShowProducts] = useState('');
  const { data, isLoading } = useGetAllOrdersQuery(storeID, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  useEffect(() => {
    console.log(searchBy);
  }, [searchBy]);

  const orderList = useMemo(() => {
    let orderData: Order[];
    if (data instanceof Array) {
      orderData = [...data];
    } else {
      orderData = data ? [data] : [];
    }
    let sortValue = '';

    if (sortBy.includes('buyer')) {
      sortValue = sortBy.split('.')[1] as 'email' | 'name';
    } else if (sortBy.includes('products')) {
      sortValue = 'length';
    } else if (sortBy.includes('timestamp')) {
      sortValue = sortBy.split('.')[1] as 'date' | 'time';
    } else {
      sortValue = 'length';
    }

    return orderData.sort(dynamicSort(sortValue));
  }, [data, sortBy]);

  //Grab retailer details FK from orders list
  const detailsFK =
    orderList?.length > 0 ? orderList[0].seller.retailerDetailsFK : undefined;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor={theme.colors.BGAdmin}
    >
      <Heading variant="card_heading" level="sub" m={0} p={0}>
        Order Management
      </Heading>
      <OrderHeader
        setFilterParameter={(name: string) => setFilterBy(name)}
        setSortParameter={(name: string) => setSortBy(name)}
        setSearchParameter={(name: string) => setSearchBy(name)}
        detailsFK={detailsFK}
      />
      {!isLoading && data && (
        <Flex flex="1" flexDirection="column" overflowY="scroll">
          {/* TODO: Add Data transform on Redux call */}
          {orderList instanceof Array
            ? orderList.map(
                (row, index) =>
                  row.status[row.status.length - 1] &&
                  row.status[row.status.length - 1].status !==
                    OrderStatus.placed && (
                    <OrderItem
                      setShowProducts={(refNumber) =>
                        setShowProducts(refNumber)
                      }
                      showProducts={showProducts === row.referenceNumber}
                      key={`order-item-${index}`}
                      {...row}
                    />
                  )
              )
            : !(data instanceof Array) &&
              [data].map(
                (row, index) =>
                  row.status[row.status.length - 1] &&
                  row.status[row.status.length - 1].status !==
                    OrderStatus.placed && (
                    <OrderItem
                      setShowProducts={(refNumber) =>
                        setShowProducts(refNumber)
                      }
                      showProducts={showProducts === row.referenceNumber}
                      key={`order-item-${index}`}
                      {...row}
                    />
                  )
              )}
        </Flex>
      )}
    </Flex>
  );
};

export default Orders;
