import { css } from '@emotion/react';
import { useMemo } from 'react';
import ErrorMessage from '../components/standard-components/elements/error';
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
} from '../store/auth/auth-helper';

const ErrorHandler = ({ error }: any) => {
  const errMsg = useMemo(() => {
    if (isFetchBaseQueryError(error)) {
      return 'error' in error ? error.error : JSON.stringify(error.data);
    } else if (isErrorWithMessage(error)) {
      // you can access a string 'message' property here
      return error.message;
    }
  }, [error]);
  const getErrorMessage = () => {
    let msg;
    try {
      msg =
        'msg' in JSON.parse(errMsg!)
          ? JSON.parse(errMsg!).msg.message ===
            'User validation failed: email: Email already in use.'
            ? 'Email already in use'
            : JSON.parse(errMsg!).msg.message
          : JSON.parse(errMsg!).message ?? errMsg;
      return msg;
    } catch (err) {
      if (errMsg === 'TypeError: Failed to fetch')
        return 'Failed to connect to Vincii servers';
      return errMsg;
    }
  };
  return errMsg ? (
    <ErrorMessage
      width="100%"
      textAlign="center"
      addCss={css`
        text-align: center;
      `}
      error={getErrorMessage()}
    />
  ) : null;
};

export default ErrorHandler;
