import { appApi } from '../app-api';
import {
  ICreateRetailerRequest,
  IOnboardingFormRequest,
  IUpdateRetailerRequest,
} from './onboarding-types';

export const onboardingApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createRetailerStore: builder.mutation({
      query: (payload: Partial<IOnboardingFormRequest>) => {
        const {
          storeName,
          website,
          instagram,
          facebook,
          storeBackground,
          shopDescription,
        } = payload;
        const body: Partial<ICreateRetailerRequest> = {
          storeName,
          website,
          instagram,
          facebook,
          storeBackground,
          storeAddress: {
            city: payload.shopCity!,
            province: payload.shopSuburb!,
          },
          status: 'pending',
          storeBio: shopDescription,
          backdrop: '',
          boosted: true,
        };
        return {
          url: '/retailer-stores',
          method: 'post',
          body,
        };
      },
    }),
    updateRetailerDetails: builder.mutation({
      query: (body: Partial<IUpdateRetailerRequest>) => {
        return {
          url: '/retailer-details',
          method: 'post',
          body,
        };
      },
    }),

    submitOnboardingForm: builder.mutation({
      query: (body: IOnboardingFormRequest) => ({
        url: '/onboardings',
        method: 'post',
        body,
      }),
    }),
  }),
});

export const {
  useSubmitOnboardingFormMutation,
  useCreateRetailerStoreMutation,
  useUpdateRetailerDetailsMutation,
} = onboardingApi;
