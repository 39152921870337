/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { MouseEventHandler } from 'react';
import StandardCSS from '../../../../theme/standard-css';
import DefaultCss from '../../../../theme/standard-default-css';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';

const CheckBox = ({
  name,
  label,
  value,
  addCss,
  onClick,
  isChecked,
  placeholder,
  noDefaultStyle,
  ...styles
}: {
  name?: string;
  label?: string;
  value?: string;
  isChecked?: boolean;
  placeholder?: string;
  noDefaultStyle?: boolean;
  addCss?: SerializedStyles;
  onClick?: MouseEventHandler<HTMLInputElement>;
} & StandardProps) => {
  return (
    <div className="form-group">
      <input
        value={value}
        type="checkbox"
        checked={isChecked}
        onClick={onClick}
        name={name}
        css={css`
          ${!noDefaultStyle && DefaultCss()}
          ${StandardCSS(styles)}
          cursor: pointer;
          max-width: 15px;
          max-height: 15px;
          ${addCss}

          ${isChecked === true &&
          css`
            border: none;

            background-color: theme.colors.secondaryCta;
          `}
        `}
      />
      {label && (
        <label
          className="card-input-label"
          css={css`
            font-weight: ${theme.fontWeights.semiBold};
            color: ${theme.colors.supportingText};
            font-size: ${theme.fontSizes.normal};

            &:focus {
            }
          `}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
