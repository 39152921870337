import React, { forwardRef } from 'react';
import { useAppSelector } from '../../store/hooks';
import StandardProps from '../../theme/standard-props';
import theme from '../../theme/theme';
import ErrorMessage from '../standard-components/elements/error';
import InputField from '../standard-components/elements/input-field';
import InputLabel from '../standard-components/elements/input-label';

const OnboardingInputField = forwardRef<
  HTMLInputElement,
  {
    name: string;
    label: string;
    error: string;
    placeholder: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  } & StandardProps
>(({ onBlur, onChange, onFocus, label, error, placeholder, name }, ref) => {
  const onboardingFormData: any = useAppSelector(
    (state) => state.onboarding.formData
  );

  return (
    <InputField
      m="0"
      p="0"
      mt={theme.margins.m}
      mb={theme.margins.m}
      pb={theme.margins.xs}
      fontSize={theme.fontSizes.medium}
      htmlId={name}
      placeholder={placeholder}
      defaultValue={onboardingFormData[name]}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      name={name}
      ref={ref}
    >
      {error && <ErrorMessage error={error} />}
      <InputLabel
        p="0"
        label={label}
        htmlFor={name}
        position="absolute"
        top="0"
        fontSize={theme.fontSizes.small}
        fontWeight={theme.fontWeights.bold}
        left="0"
      />
    </InputField>
  );
});

export default OnboardingInputField;
