import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { SingleImageDropzone } from '../dropzone';
import FormWrapper from '../form-wrapper';
import Image from '../standard-components/elements/image';
import Container from '../standard-components/layout/container';
import Heading from '../standard-components/typography/heading';

const ProductEditImagePreview = ({
  catalogueImage,
  cropFrontView,
  cropBackView,
  detailView,
  packshotView,
  handleUploadConfirm,
  imageEditCallBack,
}: {
  catalogueImage?: string;
  cropFrontView?: string;
  cropBackView?: string;
  detailView?: string;
  imageEditCallBack: (name: string, required: boolean) => void;
  packshotView?: string;
  handleUploadConfirm: (image: Blob, name: string) => void;
}) => {
  const handleImageEdit = (name: string, required: boolean) => {
    setPreview((preview) => ({ ...preview, [name]: null }));
    imageEditCallBack && imageEditCallBack(name, required);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<{ name: string; image: Blob }[]>([]);
  const [preview, setPreview] = useState<{ [key: string]: string | null }>({
    catalogueImage: null,
    cropFrontView: null,
    cropBackView: null,
    detailView: null,
    packshotView: null,
  });
  const getBlobFromUrl = (imageUrl: string, name: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', `${imageUrl}?${Date.now()}`, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve({
          name,
          image: request.response,
        });
      };
      request.onerror = reject;
      request.send();
    });
  };

  useEffect(() => {
    setPreview({
      catalogueImage: catalogueImage ?? null,
      cropFrontView: cropFrontView ?? null,
      cropBackView: cropBackView ?? null,
      detailView: detailView ?? null,
      packshotView: packshotView ?? null,
    });
    setLoading(true);
    (async () => {
      try {
        const results = await Promise.all([
          catalogueImage && getBlobFromUrl(catalogueImage, 'catalogueImage'),
          cropFrontView && getBlobFromUrl(cropFrontView, 'cropFrontView'),
          cropBackView && getBlobFromUrl(cropBackView, 'cropBackView'),
          detailView && getBlobFromUrl(detailView, 'detailView'),
          packshotView && getBlobFromUrl(packshotView, 'packshotView'),
        ]);
        const imagesResult = results
          .filter((image) => {
            return image && (image !== '' || image !== undefined);
          })
          .filter((image: { name: string; image: Blob }) => {
            return image.image && image.image.type.includes('image');
          });
        imagesResult && setImages(imagesResult);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [catalogueImage, cropBackView, cropFrontView, detailView, packshotView]);
  return (
    <FormWrapper my="0" py="0" columns={2}>
      {preview.catalogueImage ? (
        <Preview
          loading={loading}
          name="catalogueImage"
          description="Catalogue Image *"
          images={images}
          handleImageEdit={handleImageEdit}
          required
        />
      ) : (
        <SingleImageDropzone
          description="Catalogue Image *"
          name="catalogueImage"
          imageMinHeight={432}
          imageMinWidth={300}
          imageMaxHeight={1152}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
          required
        />
      )}
      {preview.detailView ? (
        <Preview
          loading={loading}
          name="detailView"
          description="Image 2 *"
          images={images}
          handleImageEdit={handleImageEdit}
          required
        />
      ) : (
        <SingleImageDropzone
          description="Image 2 *"
          name="detailView"
          imageMinHeight={432}
          imageMinWidth={300}
          imageMaxHeight={1152}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
          required
        />
      )}
      {preview.cropFrontView ? (
        <Preview
          loading={loading}
          name="cropFrontView"
          images={images}
          description="Image 3 *"
          handleImageEdit={handleImageEdit}
        />
      ) : (
        <SingleImageDropzone
          description="Image 3 *"
          name="cropFrontView"
          imageMinHeight={432}
          imageMinWidth={300}
          imageMaxHeight={1152}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
        />
      )}
      {preview.cropBackView ? (
        <Preview
          loading={loading}
          name="cropBackView"
          description="Image 4 *"
          images={images}
          handleImageEdit={handleImageEdit}
        />
      ) : (
        <SingleImageDropzone
          description="Image 4 *"
          name="cropBackView"
          imageMinHeight={432}
          imageMinWidth={300}
          imageMaxHeight={1152}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
        />
      )}

      {preview.packshotView ? (
        <Preview
          loading={loading}
          description="Image 5"
          name="packshotView"
          images={images}
          handleImageEdit={handleImageEdit}
        />
      ) : (
        <SingleImageDropzone
          description="Image 5"
          name="packshotView"
          imageMinHeight={432}
          imageMinWidth={300}
          imageMaxHeight={1152}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
        />
      )}
    </FormWrapper>
  );
};

const Preview = ({
  name,
  loading,
  required = false,
  images,
  description,
  handleImageEdit,
}: {
  name: string;
  required?: boolean;
  loading: boolean;
  description: string;
  images: { name: string; image: Blob }[];
  handleImageEdit: (name: string, required: boolean) => void;
}) => {
  return (
    <Container
      width={`${480}px`}
      height={`${322}px`}
      m={0}
      mb="60px"
      p={0}
      addCss={css`
        aspect-ratio: ${480} / ${322};
      `}
      position="relative"
    >
      <Heading variant="nav_body">{description}</Heading>
      <Container
        right="2rem"
        top="1rem"
        position="absolute"
        m="0"
        p="0"
        addCss={css`
          &:hover {
            cursor: pointer;
          }
        `}
      >
        <FaEdit size={25} onClick={() => handleImageEdit(name, required)} />
      </Container>
      {images.find((image) => image?.name === name) && (
        <Image
          m={0}
          src={URL.createObjectURL(
            images.find((image) => image?.name === name)?.image!
          )}
          alt="preview"
          objectFit="contain"
          addCss={css`
            border-radius: '8px';
          `}
          //height: calc(${imageMinHeight} / 4) px;
          // width: calc(${imageMinWidth} / 4) px;
        />
      )}
    </Container>
  );
};
export default ProductEditImagePreview;
