import { createContext, ReactNode, Reducer, useMemo, useReducer } from 'react';
import { IMyShopAction, IMyShopContext, IMyShopState } from './types';

export const MyShopContext = createContext<IMyShopContext | null>(null);

const initialState: IMyShopState = {
  isLoading: false,
  isSaved: false,
  productPageToShow: 'LIST PRODUCTS',
  productEditDefault: null,
};

//Reducer
const reducer: Reducer<IMyShopState, IMyShopAction<any>> = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        productPageToShow: 'ADD PRODUCT',
      };
    case 'EDIT': {
      return {
        ...state,
        productPageToShow: 'EDIT PRODUCT',
        productEditDefault: action.payload,
      };
    }
    case 'LIST': {
      return {
        ...state,
        productPageToShow: 'LIST PRODUCTS',
      };
    }
    default:
      return state;
  }
};
const MyShopContextProvider = ({ children }: { children: ReactNode }) => {
  const [myShopState, dispatch] = useReducer(reducer, initialState);
  const store = useMemo(() => ({ ...myShopState, dispatch }), [myShopState]);
  return (
    <MyShopContext.Provider value={store}>{children}</MyShopContext.Provider>
  );
};

export default MyShopContextProvider;
