/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { MouseEventHandler } from 'react';
import StandardProps from '../../theme/standard-props';
import theme from '../../theme/theme';
import Form from '../standard-components/elements/form';
import Grid from '../standard-components/layout/grid';

const FormWrapper = ({
  children,
  columns,
  onSubmit,
  ...styles
}: {
  children?: any;
  addCss?: SerializedStyles;
  onSubmit?: MouseEventHandler<HTMLFormElement>;
  columns?: number;
} & StandardProps) => {
  const handleSubmitForm: MouseEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(e);
    }
  };
  return (
    <Form
      hideSubmitButton
      onSubmit={handleSubmitForm}
      alignContent="flex-start"
      px="0"
      py="0"
      my="0"
      mx="0"
    >
      <Grid
        mx="0"
        my="0"
        py="0"
        px={theme.padding.standard}
        justifyText="flex-start"
        gridTemplateColumns={
          columns ? `repeat(${columns}, 1fr)` : `repeat(4, 1fr)`
        }
        gridRowGap="0.5rem"
        gridColumnGap="1.5rem"
        alignContent="flex-start"
        {...styles}
        cursor="default"
      >
        {children}
      </Grid>
    </Form>
  );
};

export default FormWrapper;
