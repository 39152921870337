/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { Product } from '../../store/products/product-types';
import theme from '../../theme/theme';
import Image from '../standard-components/elements/image';
import Heading from '../standard-components/typography/heading';
import Paragraph from '../standard-components/typography/paragraph';
// import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';
import Container from '../standard-components/layout/container';
import { css } from '@emotion/react';
import { useDeleteProductMutation } from '../../store/products/product-api';
import { useAppDispatch } from '../../store/hooks';
import { notificationActions } from '../../store/notifications/notification-slice';
import { Flex } from '../standard-components/layout/flex-box';
import ReactLoading from 'react-loading';
import { FaEdit } from 'react-icons/fa';
import { MyShopContext } from '../../context/my-shop/MyShopContext';

const ProductPreviewCard = ({ product }: { product: Product }) => {
  const [deleteProduct, { isLoading: isLoadingDelete }] =
    useDeleteProductMutation();
  const notificationDispatch = useAppDispatch();
  const { dispatch: productPageToDisplay } = useContext(MyShopContext)!;

  const handleDeleteProduct = async () => {
    if (window.confirm('Are you sure you want to delete product?')) {
      try {
        const response = await deleteProduct({ _id: product._id }).unwrap();
        if (response)
          notificationDispatch(
            notificationActions.pushNotification({
              message: 'Product Successfully Deleted',
              type: 'success',
              autoClose: true,
              closeTime: 1000,
              positionY: 'top',
              positionX: 'center',
            })
          );
      } catch (err) {
        notificationDispatch(
          notificationActions.pushNotification({
            message:
              'Product Delete Failed, Try Again or Contact Your Vincii Representative',
            type: 'error',
            autoClose: true,
            closeTime: 3000,
            positionY: 'top',
            positionX: 'center',
          })
        );
      }
    }
  };
  return (
    <Flex
      flexDirection="column"
      backgroundColor={theme.colors.veryLightGrey}
      p={0}
      m={0}
      addCss={css`
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        max-height: 600px;
      `}
    >
      <Heading variant="card_item_heading" m={0} p={theme.padding.s}>
        {product.productName}
      </Heading>
      <Container m={0} p={0} alignContent="flex-end">
        <Paragraph p={0} variant="card_item_cta" textAlign="left !important">
          {`R ${product.productPrice}.00`}
        </Paragraph>
      </Container>

      <Image
        objectFit="cover"
        m="0"
        src={`${product.catalogueImage}?${Date.now()}`}
        alt={`Image of ${product.productName}`}
        onClick={() => productPageToDisplay({ type: 'EDIT', payload: product })}
        addCss={css`
          border-radius: ${theme.borderRadius.normal};
          cursor: pointer;
          aspect-ratio: 1/1.35;
        `}
      />
      <Flex p="0" alignItems="flex-end" m={0} height="100%" mb="10px">
        <Container
          my="0"
          mr={theme.margins.xs}
          p="0"
          addCss={css`
            &:hover {
              cursor: pointer;
            }
          `}
        >
          <FaEdit
            size={20}
            onClick={() =>
              productPageToDisplay({ type: 'EDIT', payload: product })
            }
            color={theme.colors.supportingText}
          />
        </Container>
        <Container
          my="0"
          mr={theme.margins.xs}
          p="0"
          addCss={css`
            &:hover {
              cursor: pointer;
            }
          `}
        >
          {isLoadingDelete ? (
            <ReactLoading
              type="spinningBubbles"
              height="1.%rem"
              width="1.5rem"
              color={theme.colors.primaryCta}
            />
          ) : (
            <RiDeleteBin5Line
              size={20}
              onClick={handleDeleteProduct}
              color={theme.colors.supportingText}
            />
          )}
        </Container>
      </Flex>
    </Flex>
  );
};

export default ProductPreviewCard;
