/** @jsxImportSource @emotion/react */
import Paragraph from '../../standard-components/typography/paragraph';
import FormWrapper from '../../form-wrapper';
import Card from '../../standard-components/elements/card';
import { OnboardingContext } from '../../../context/onboarding/OnboardingContext';
import Image from '../../standard-components/elements/image';
import { css } from '@emotion/react';
import patterns from '../../../constants/validation-regex';
import { SingleImageDropzone, SinglePDFDropzone } from '../../dropzone';
import { useContext } from 'react';
import OnboardingInputField from '../../onboarding-input';
import ToggleSwitch from '../../standard-components/elements/toggle-switch';
import { Flex } from '../../standard-components/layout/flex-box';
import Heading from '../../standard-components/typography/heading';
import theme from '../../../theme/theme';

const ShopOverview = ({
  register,
  errors,
  useVinciiColor,
  setUseVinciiColor,
}: {
  register: any;
  errors: any;
  useVinciiColor: boolean;
  setUseVinciiColor: () => void;
}) => {
  const { onboardingDispatch } = useContext(OnboardingContext)!;
  const handleUploadConfirm = (image: Blob, name: string) => {
    onboardingDispatch({
      type: 'image_upload',
      payload: {
        key: name,
        data: image,
      },
    });
  };
  const handleDocumentConfirm = (document: any) => {
    onboardingDispatch({
      type: 'document_upload',
      payload: {
        key: 'sizingGuide',
        data: document,
      },
    });
  };

  return (
    <>
      <Card heading="Shop Overview">
        <Paragraph>
          Please enter an email and phone number that you actively use. We'll
          send notifications about ongoing orders to this email.
        </Paragraph>
        <FormWrapper columns={3}>
          <OnboardingInputField
            label="Shop Name *"
            placeholder="Enter a shop name"
            error={errors.storeName}
            {...register('storeName', { required: false })}
          />
          <OnboardingInputField
            label="Company Email *"
            placeholder="name@example.com"
            error={errors.customerRequestEmail}
            {...register('customerRequestEmail', { required: false })}
          />
          <OnboardingInputField
            label="Contact Number *"
            placeholder="Enter a contact number"
            error={errors.customerRequestNumber}
            {...register('customerRequestNumber', { required: false })}
          />
        </FormWrapper>
        <Paragraph>
          We'll include these in your Bio. If you don't have one just leave it
          blank.
        </Paragraph>
        <FormWrapper columns={3}>
          <OnboardingInputField
            label="Website"
            placeholder="Enter website url"
            {...register('website')}
          />
          <OnboardingInputField
            label="Instagram"
            placeholder="https://www.instagram.com/username"
            {...register('instagram')}
          />
          <OnboardingInputField
            label="Facebook"
            placeholder="https://www.facebook.com/username"
            {...register('facebook')}
          />
        </FormWrapper>
      </Card>
      <Card heading="Logo Image, Banner Image and Brand colour">
        <Paragraph>
          We need a hex code for your brand colour. We'll' use this as the
          background colour of your store. For example #402AB2 is the hex code
          for Vincii Purple.
        </Paragraph>
        <FormWrapper columns={2} mb="0" pb="0">
          <OnboardingInputField
            label="Brand colour *"
            placeholder="#402AB2"
            error={errors.storeBackground}
            {...register('storeBackground', { required: false })}
          />
        </FormWrapper>
        <FormWrapper columns={1} mb="0" pb="0">
          <Flex
            pb={theme.padding.standard}
            flexDirection="column"
            noDefaultStyle
          >
            <Heading py="0" m="0" level="sub" variant="card_sub_heading">
              Would you prefer to use Vincii color scheme for your store?
            </Heading>
            <Flex noDefaultStyle flex={1} alignItems="flex-end">
              <Paragraph
                color={theme.colors.primaryCta}
                fontWeight="500 !important"
                pb="2px"
                my="0"
              >
                Use Vincii Colours?
              </Paragraph>
              <ToggleSwitch
                name="variantSwitch"
                isOn={useVinciiColor}
                handleToggle={setUseVinciiColor}
              />
            </Flex>
          </Flex>
        </FormWrapper>
        <Paragraph>
          Upload your logo and banner images. A demo of the Logo and Banner is
          shown on the right of each image Drop Zone. Please ensure that your
          banner image has an aspect ratio of 2:1 and your logo has an aspect
          ratio of 1:1. Upload the image and confirm once you are happy with the
          preview.
        </Paragraph>
        <FormWrapper my="0" py="0" columns={2}>
          <SingleImageDropzone
            description="Logo Image*"
            previewRound
            name="logo"
            imageMinHeight={200}
            imageMinWidth={200}
            imageMaxHeight={800}
            imageMaxWidth={800}
            onUploadConfirm={handleUploadConfirm}
            minFileSize={10000}
          />
          <Image
            src={`${process.env.REACT_APP_CDN_HOST}/images/logo-demo.jpg`}
            height="480px"
            width="200px"
            objectFit="scale-down"
            alt="Back Arrow"
            addCss={css`
              cursor: pointer;
            `}
          />
          <SingleImageDropzone
            description="Banner Image*"
            name="banner"
            imageMinHeight={200}
            imageMinWidth={400}
            imageMaxHeight={800}
            imageMaxWidth={1600}
            onUploadConfirm={handleUploadConfirm}
            minFileSize={20000}
          />
          <Image
            src={`${process.env.REACT_APP_CDN_HOST}/images/banner-demo.jpg`}
            alt="Back Arrow"
            height="533px"
            width="200px"
            objectFit="scale-down"
            addCss={css`
              cursor: pointer;
            `}
          />
        </FormWrapper>
      </Card>
      <Card heading="Optional Sizing Guide">
        <Paragraph>
          Upload a PDF for your store's Sizing Guide. For example: large shorts
          could have 34inch waist.
        </Paragraph>
        <FormWrapper my="0" py="0" columns={2}>
          <SinglePDFDropzone onUploadConfirm={handleDocumentConfirm} />
        </FormWrapper>
      </Card>
    </>
  );
};

export default ShopOverview;
