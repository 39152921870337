import Card from '../standard-components/elements/card';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import Button from '../standard-components/elements/button';
import theme from '../../../src/theme/theme';

const Feedback = () => (
  <Flex flexDirection="column" overflowY="hidden" m="0" width="100%">
    <Card heading="Feedback">
      <Paragraph>
        Please report any difficulties or suggest changes by clicking on the
        button below and filling out the form.
      </Paragraph>
      <Paragraph>
        Please don't hesitate to contact your Vincii representative if you have
        any questions.
      </Paragraph>
      <Paragraph>Thanks for joining us on this journey.</Paragraph>
      <a
        href="https://feedback.vincii.co.za/"
        target="https://feedback.vincii.co.za/"
        rel="noreferrer"
      >
        <Button
          color={theme.colors.primaryCta}
          fontSize={theme.fontSizes.medium}
        >
          FEEDBACK
        </Button>
      </a>
    </Card>
  </Flex>
);

export default Feedback;
