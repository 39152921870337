export type TGetAllOrdersResponse = Order | Order[];
export interface Order {
  _id: string;
  referenceNumber: string;
  storeFK: string;
  orderTotal: string;
  status: Status[];
  seller: Seller;
  buyer: Buyer;
  products: Product[];
  transactionId: string;
  timeStamp: Date;
  __v: number;
  aramexWaybill: {
    change_description?: any;
    label_print: string;
    status_code: number;
    status_description: string;
    waybill_number: string;
  };
}

export interface Buyer {
  name: string;
  deliveryAddress: Address;
  contactNumber: string;
  userFK: string;
  email: string;
  _id: string;
}

export interface Address {
  streetNumber: string;
  city: string;
  suburb: string;
  province?: string;
  zipCode: string;
  _id: string;
}

export interface Product {
  productFK: string;
  productName: string;
  productPrice: number;
  catalogueImage: string;
  variant: {
    size: string;
    color: string;
    fabric: string;
    quantity: number;
  };
  _id: string;
}

export interface Seller {
  name: string;
  pickupAddress: Address;
  retailerStoresFK: string;
  retailerDetailsFK: string;
  contactNumber: string;
  _id: string;
}

export interface Status {
  status: string;
  date: Date;
  _id: string;
}

export enum OrderStatus {
  placed = 'PLACED',
  paid = 'PAID',
  missed = 'RETAILER_MISSED',
  declined = 'RETAILER_DECLINED',
  refunded = 'REFUNDED',
  confirmed = 'RETAILER_CONFIRMED',
  waybillCreated = 'WAYBILL_CREATED',
  collectionTriggered = 'COLLECTION_TRIGGERED',
  itemCollected = 'ITEM_COLLECTED',
  itemDelivered = 'ITEM_DELIVERED',
  retailerDue = 'RETAILER_DUE',
  retailerPaid = 'RETAILER_PAID',
  acceptedOrderStale = 'ACCEPTED_ORDER_STALE',
  retailerMissedFlag = 'RETAILER_MISSED_FLAG',
  paymentUnsuccesful = 'PAYMENT_UNSUCCESFUL',
}
