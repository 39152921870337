/* @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { css } from '@emotion/react';
import theme from '../../theme/theme';
import Image from '../standard-components/elements/image';
import Resizer from 'react-image-file-resizer';
import Heading from '../standard-components/typography/heading';
import Container from '../standard-components/layout/container';
import Paragraph from '../standard-components/typography/paragraph';
//import Button from '../standard-components/elements/button';
/*NOTE:
We are going to need a redux implementation to handle this. 
The idea is to push each image, with the correct name and other data,
to redux/context once the user confirms the image. 
Then we can upload in one go.
*/
//TODO: Add clear button
interface RejectionError {
  code: string;
  message: string;
}
interface RejectionFile {
  file: any;
  errors: RejectionError[];
}
const RejectionText = ({
  errors,
  minSize,
  maxSize,
}: {
  errors: RejectionFile[];
  minSize?: number;
  maxSize?: number;
}) => {
  const getMessage = useMemo(() => {
    const baseMessage = 'File upload rejected:';
    if (minSize) {
      const minKBVal = Math.floor(minSize / 1000);
      if (errors[0].errors[0].code === 'file-too-small')
        return `${baseMessage} File is smaller than ${minKBVal}KB`;
    }
    if (maxSize) {
      const maxMBVal = Math.floor(maxSize / 1000000);

      if (errors[0].errors[0].code === 'file-too-large')
        return `${baseMessage} File is larger than ${maxMBVal}MB`;
    }
    return `${baseMessage} ${errors[0].errors[0].message}`;
  }, [errors, maxSize, minSize]);

  return (
    <Heading color="red" variant="nav_body">
      {getMessage}
    </Heading>
  );
};
export const SinglePDFDropzone = ({
  description,
  onUploadConfirm,
}: {
  description?: string;
  onUploadConfirm: (document: any) => void;
}) => {
  //The 'droppedFiles' state value can now be used to update the redux state
  const [droppedFile, setDroppedFile] = useState<any | null>(null);
  const [uploadErrors, setUploadErrors] = useState<any[] | null>(null);
  const dropHandler = (
    acceptedFile: any,
    fileRejection: FileRejection[],
    event: DropEvent
  ) => {
    if (acceptedFile.length > 0) {
      onUploadConfirm(new Blob(acceptedFile));
      setUploadErrors(null);
      setDroppedFile(acceptedFile);
    } else {
      setDroppedFile(null);
      setUploadErrors(fileRejection);
    }
  };
  return (
    <Container cursor="default">
      <>
        {description && (
          <Heading cursor="default" variant="card_heading" level="sub">
            {description}
          </Heading>
        )}
        <Dropzone
          onDrop={dropHandler}
          onDropRejected={(fileRejection, event) =>
            setUploadErrors(fileRejection)
          }
          onDropAccepted={() => setUploadErrors(null)}
          accept=".pdf"
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              css={css`
                border: 2px dashed grey;
                border-radius: 3px;
                width: 100%;
                height: 200px;
                background-color: ${theme.colors.BGAdmin};
              `}
            >
              <input {...getInputProps()} />
              <Paragraph>
                Drop your file here, or click to open file dialogue
              </Paragraph>
            </div>
          )}
        </Dropzone>
      </>
      {droppedFile && (
        <>
          <Paragraph>{`File to upload ${droppedFile[0].name}`}</Paragraph>
        </>
      )}
      {uploadErrors && <RejectionText errors={uploadErrors} />}
    </Container>
  );
};
export const SingleImageDropzone = ({
  imageMinWidth,
  imageMaxWidth,
  imageMinHeight,
  imageMaxHeight,
  compressQuality = 100,
  maxFileSize = 2000000,
  minFileSize = 10000,
  outputType = 'base64',
  onUploadConfirm,
  name,
  description,
  previewRound,
  required,
}: {
  name: string;
  previewRound?: boolean;
  imageMinWidth: number;
  imageMaxWidth: number;
  imageMinHeight: number;
  imageMaxHeight: number;
  compressQuality?: number;
  maxFileSize?: number;
  minFileSize?: number;
  outputType?: string;
  required?: boolean;
  onUploadConfirm: (image: Blob, name: string, required?: boolean) => void;
  description: string;
}) => {
  const [droppedFile, setDroppedFile] = useState(null);
  const [image, setImage] = useState<any>(null);
  const [uploadErrors, setUploadErrors] = useState<any[] | null>(null);

  const dropHandler = (acceptedFiles: any) => {
    setUploadErrors(null);
    setDroppedFile(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
  };

  useEffect(() => {
    image && onUploadConfirm(image, name, required);
    // Ignored to avoid an infinite loop. We should probably find out why including onUploadConfirm causes an infinite loop...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    droppedFile &&
      Resizer.imageFileResizer(
        droppedFile,
        imageMaxWidth,
        imageMaxHeight,
        'PNG',
        100,
        0,
        (uri) => {
          setImage(uri);
        },
        outputType,
        imageMinWidth,
        imageMinHeight
      );
  }, [
    compressQuality,
    droppedFile,
    image,
    imageMaxHeight,
    imageMaxWidth,
    imageMinHeight,
    imageMinWidth,
    outputType,
  ]);

  const Previews = ({ image }: { image: any }) => {
    if (!image) {
      return null;
    }

    return (
      <>
        <Container
          width={`${imageMinWidth}px`}
          height={`${imageMinHeight}px`}
          m={0}
          mb="20px"
          p={0}
          cursor="default"
          addCss={css`
            aspect-ratio: ${imageMaxWidth} / ${imageMaxHeight};
          `}
        >
          <Image
            src={image}
            alt="preview"
            objectFit="cover"
            cursor="default"
            addCss={css`
              border-radius: ${previewRound ? '50%' : '8px'};
            `}
          />
        </Container>
      </>
    );
  };
  return (
    <Container cursor="default">
      <>
        <Heading variant="card_heading" level="sub">
          {description}
        </Heading>
        <Dropzone
          onDropRejected={(event) => setUploadErrors(event)}
          onDropAccepted={dropHandler}
          accept="image/*"
          maxFiles={1}
          maxSize={maxFileSize}
          minSize={minFileSize}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              css={css`
                border: 2px dashed grey;
                border-radius: 3px;
                width: 50%;
                height: 150px;
                background-color: ${theme.colors.BGAdmin};
              `}
            >
              <input {...getInputProps()} />
              <Paragraph>
                Drop your image here, or click to open file dialogue
              </Paragraph>
            </div>
          )}
        </Dropzone>
      </>
      {uploadErrors && (
        <RejectionText
          minSize={minFileSize}
          maxSize={maxFileSize ? maxFileSize : 2000000}
          errors={uploadErrors}
        />
      )}
      <Previews image={image} />
    </Container>
  );
};
/* Implementation of multiple image dropzone, not used now
export const MultipleImageDropzone = ({
  imageMinWidth,
  imageMaxWidth,
  imageMinHeight,
  imageMaxHeight,
  compressQuality = 100,
  outputType = 'file',
  numberOfFiles,
}: {
  imageMinWidth: number;
  imageMaxWidth: number;
  imageMinHeight: number;
  imageMaxHeight: number;
  compressQuality?: number;
  outputType?: string;
  numberOfFiles?: number;
}) => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [allDroppedFiles, setAllDroppedFiles] = useState<any[]>([]);
  const [uploadErrors, setUploadErrors] = useState<any[] | null>(null);
  const dropHandler = (acceptedFiles: any) => {
    setDroppedFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  useEffect(() => {
    if (droppedFiles.length > 0) {
      droppedFiles.forEach((file) => {
        console.log(allDroppedFiles);
        Resizer.imageFileResizer(
          file,
          imageMaxWidth,
          imageMaxHeight,
          'JPEG',
          compressQuality,
          0,
          (uri) => {
            // console.log(uri);
            setAllDroppedFiles((allDroppedFiles) => [...allDroppedFiles, uri]);
          },
          outputType,
          imageMinWidth,
          imageMinHeight
        );
      });
    }
  }, [droppedFiles]);
  const Previews = ({ allDroppedFiles }: { allDroppedFiles: any[] }) => {
    if (allDroppedFiles.length === 0) {
      return null;
    }
    return (
      <>
        <Heading px={0} mx={0}>
          How the image(s) will look after processing:
        </Heading>
        <Grid gridTemplateColumns="repeat(4, 1fr)">
          {allDroppedFiles.map((file, idx) => (
            <>
              <Image
                key={idx}
                src={URL.createObjectURL(file)}
                alt="preview"
                objectFit="cover"
                addCss={css`
                  aspect-ratio: ${imageMaxWidth} / ${imageMaxHeight};
                  max-height: ${imageMaxHeight};
                  max-width: ${imageMaxWidth};
                `}
              />
            </>
          ))}
        </Grid>
      </>
    );
  };
  return (
    <>
      <Dropzone
        onDrop={dropHandler}
        onDropRejected={(event) => setUploadErrors(event)}
        accept="image/*"
        maxFiles={numberOfFiles && numberOfFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            css={css`
              border: 1px solid grey;
              border-radius: ${theme.borderRadius.normal};
              width: 100%;
              height: 200px;
            `}
          >
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here</p>
          </div>
        )}
      </Dropzone>
      {uploadErrors && <RejectionText errors={uploadErrors} />}
      <Previews allDroppedFiles={allDroppedFiles} />
    </>
  );
};

*/
