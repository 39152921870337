export default function deepCompare(newObj: any, prevObj: any): boolean {
  try {
    if (newObj === prevObj) {
      return true;
    } else {
      if (typeof newObj !== 'object') {
        if (newObj !== '_id' && String(newObj) !== String(prevObj)) {
          return false;
        }
        return true;
      } else if (newObj instanceof Array) {
        if (newObj.length > 0) {
          return newObj.every(
            (element, index) =>
              prevObj[index] && deepCompare(element, prevObj[index])
          );
        } else {
          if (prevObj.length > 0) return false;
          return true;
        }
      } else {
        return Object.keys(newObj).every((key) =>
          deepCompare(newObj[key], prevObj[key])
        );
      }
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
