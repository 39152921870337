/* @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import React from 'react';
import StandardCSS from '../../../theme/standard-css';
import StandardProps from '../../../theme/standard-props';
import theme from '../../../theme/theme';

const Table = ({
  theader,
  children,
  addCss,
  headerStyles,
  ...styles
}: {
  children?: any;
  headerStyles?: StandardProps;
  addCss?: SerializedStyles;
  theader: {
    text: string;
    styles?: StandardProps;
  }[];
} & StandardProps) => {
  return (
    <table
      css={css`
        ${StandardCSS(styles)};
        ${addCss}
      `}
    >
      <thead
        css={css`
          background: ${theme.colors.veryLightGrey};
          border-bottom: 1px solid ${theme.colors.background};
          color: ${theme.colors.primaryCta};
          font-family: 'Poppins', sans-serif;
          font-size: 14px;

          ${headerStyles && StandardCSS(headerStyles)};
        `}
      >
        <tr>
          {theader.map((head, index) => (
            <th
              key={`th-${index}`}
              css={css`
                text-align: center;
                font-weight: 900;
                padding: ${theme.padding.s} ${theme.padding.s};
                ${head.styles && StandardCSS(head.styles)};
              `}
            >
              {head.text}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        css={css`
          text-align: center;
          font-weight: 400;
          padding: ${theme.padding.s} ${theme.padding.s};
        `}
      >
        {children}
      </tbody>
    </table>
  );
};

export default Table;
