import React from 'react';
import theme from '../../theme/theme';
import { Flex } from '../standard-components/layout/flex-box';
import Heading from '../standard-components/typography/heading';
import Paragraph from '../standard-components/typography/paragraph';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { css } from '@emotion/react';

const AddProductCard = ({ callback }: { callback: () => void }) => {
  return (
    <div onClick={callback} style={{ height: '100%' }}>
      <Flex
        height="100%"
        backgroundColor={theme.colors.darkGreyStandard}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        m={0}
        addCss={css`
          &:hover {
            cursor: pointer;
            background-color: ${theme.colors.halfDark};
          }
        `}
      >
        <Heading variant="card_heading" color={theme.colors.white}>
          Add Product
        </Heading>
        <Paragraph variant="hero_body">
          <IoMdAddCircleOutline size={50} color={theme.colors.white} />
        </Paragraph>
      </Flex>
    </div>
  );
};

export default AddProductCard;
