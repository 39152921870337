import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IToastItem } from '../../components/standard-components/elements/toast-notification/types';

const initialState: { toastList: IToastItem[] } = {
  toastList: [],
};
const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    removeNotification(
      state: { toastList: IToastItem[] },
      { payload }: PayloadAction<number>
    ) {
      state.toastList.splice(payload, 1);
    },
    pushNotification(
      state: { toastList: IToastItem[] },
      { payload }: PayloadAction<IToastItem>
    ) {
      state.toastList.push(payload);
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
