/** @jsxImportSource @emotion/react */
import React from 'react';
import Grid from '../../standard-components/layout/grid';
import Paragraph from '../../standard-components/typography/paragraph';
import { css } from '@emotion/react';
const ProductHeaderRowItem = ({ item }: { item: string }) => {
  return (
    <Paragraph borderRadius="0" m="0" px="0" variant="grid_header_row">
      <div
        css={css`
          height: 100%auto;
          display: flex;
          justify-content: center;
        `}
      >
        <span
          css={css`
            margin-right: 5px;
          `}
        >
          {item}
        </span>
      </div>
    </Paragraph>
  );
};
const ProductHeaderRow = () => {
  return (
    <Grid
      m="0"
      gridColumnGap="1.5rem"
      gridTemplateColumns="repeat(7, 1fr)"
      alignItems="center"
      pb="0"
      pt=".5rem"
      borderB="1px solid rgba(0,0,0,.1)"
      borderRadius="0"
    >
      <ProductHeaderRowItem item="Product Image" />
      <ProductHeaderRowItem item="Name" />
      <ProductHeaderRowItem item="Fabric" />
      <ProductHeaderRowItem item="Size" />
      <ProductHeaderRowItem item="Colour" />
      <ProductHeaderRowItem item="Product Price" />
      <ProductHeaderRowItem item="Quantity" />
    </Grid>
  );
};

export default ProductHeaderRow;
