/* @jsxImportSource @emotion/react */
import { ReactNode, useRef, useState } from 'react';
import theme from '../../theme/theme';
import Button from '../standard-components/elements/button';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import useOutsideClick from '../../hooks/use-outside-click';
import { css } from '@emotion/react';
import { RiArrowDownSLine } from 'react-icons/ri';
import StandardProps from '../../theme/standard-props';
import StandardCSS from '../../theme/standard-css';
import DefaultCss from '../../theme/standard-default-css';

/**I suggest using an enum in the file where the dropdown is implemented to control the active option.
 * In addition, I'm adding an "active" boolean to the DropdownMenuItem component, which will not be a required prop.
 * This can be used to change the appearance of the active menu item, if required.
 * Thanks for attending my Ted Talk. Get home safely. */

export const DropdownMenuItem = ({
  option,
  active,
  callback,
}: {
  option: string;
  active?: boolean;
  callback?: () => void;
}) => (
  <Button
    border="none"
    borderRadius={0}
    m={0}
    mb="1px"
    onClick={callback}
    addCss={css`
      background-color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.headerColor};
      }
    `}
  >
    <Paragraph
      m={0}
      p={0}
      color={active ? theme.colors.primaryCta : theme.colors.darkText}
    >
      {option}
    </Paragraph>
  </Button>
);

const DropdownMenu = ({
  title,
  options,
  currentItem,
  dropdownColour,
  noDefaultStyles,
  callback,
  children,
  ...styles
}: {
  title: string;
  options: string[];
  currentItem: string;
  dropdownColour?: string;
  noDefaultStyles?: boolean;
  callback?: (item: string) => void;
  children?: ReactNode[] | ReactNode;
} & StandardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));
  const handleItemClick = (item: string) => {
    setIsOpen(false);
    if (callback) {
      callback(item);
    }
  };
  return (
    <Flex p={0} m={0} position="relative" width="100%">
      <div
        ref={wrapperRef}
        css={css`
          ${!noDefaultStyles && DefaultCss()}
          ${StandardCSS(styles)}
          width: 100%;
        `}
      >
        <Button
          width="100%"
          variant="flat"
          p={0}
          m="0"
          mt="12px"
          borderRadius={0}
          backgroundColor={dropdownColour ? dropdownColour : theme.colors.white}
          onClick={() => setIsOpen(!isOpen)}
          borderB={
            isOpen
              ? `1.2px solid ${theme.colors.primaryCta}`
              : `1px solid ${theme.colors.lineGrey}`
          }
        >
          <Flex
            alignItems="flex-end"
            p={0}
            m={0}
            justifyContent="space-evenly"
            width="100%"
          >
            <Paragraph
              variant="card_body"
              flex="1"
              m="0"
              p="2px"
              color={theme.colors.dark}
            >
              {currentItem}
            </Paragraph>
            <RiArrowDownSLine
              color={theme.colors.supportingText}
              size="2.5em"
            />
          </Flex>
        </Button>
        {isOpen && (
          <Flex
            flexDirection="column"
            maxHeight="240px"
            overflowY="scroll"
            width="50%"
            m={0}
            p="5px"
            position="absolute"
            right="0"
            zIndex={100}
            mt="5px"
            ml="10px"
            border={`1px solid rgba(0,0,0,.14)`}
            borderRadius="0"
            boxShadow="medium"
            backgroundColor={theme.colors.white}
            // TODO: Always show scroll bar
          >
            {options.map((item, index) => {
              return (
                <DropdownMenuItem
                  key={item}
                  option={item}
                  callback={() => handleItemClick(item)}
                  active={currentItem === item}
                />
              );
            })}
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default DropdownMenu;
