/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Table from '../../standard-components/layout/table';
import { cartesian } from '../../../utils/combination-generator';
import Paragraph from '../../standard-components/typography/paragraph';
import { TiDelete } from 'react-icons/ti';
import theme from '../../../theme/theme';
import { css } from '@emotion/react';
import { IVariantCombine, IVariantTags } from '../types';

const getTheader = (headers: string[]) => {
  return [...headers.map((header) => ({ text: header })), { text: '' }];
};

const VariantSelectTable = ({
  readOnly,
  columns,
  tags,
  callback,
  type,
  defaultMeasure,
  defaultVariantData,
}: {
  readOnly: boolean;
  defaultMeasure?: number;
  columns: string[];
  tags: IVariantTags;
  type?: 'mto' | 'soh';
  defaultVariantData?: IVariantCombine[];
  callback?: (data: unknown) => void;
}) => {
  const [tableList, setTableList] = useState<Partial<IVariantCombine>[]>([]);

  const getIndex = useMemo(() => {
    return type === 'mto' ? 'leadTime' : 'quantity';
  }, [type]);

  useEffect(() => {
    if (defaultVariantData && defaultVariantData.length > 0) {
      setTableList(defaultVariantData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tags && !readOnly) {
      let cartesianProductList = cartesian(tags.fabric, tags.colour, tags.size);
      if (cartesianProductList.length > 0) {
        const productVariants = cartesianProductList.reduce(
          (prev, current) => [
            ...prev,
            {
              fabric: current[0] as unknown as string,
              colour: current[1] as unknown as string,
              size:
                type === 'mto'
                  ? (current[2] as unknown as string)
                  : {
                      UK: current[2] as unknown as string,
                    },
              [getIndex]: defaultMeasure ? defaultMeasure : 0,
            },
          ],
          [] as Partial<IVariantCombine>[]
        );
        setTableList(productVariants);
      } else {
        setTableList([]);
      }

      if (callback) {
        const tableListParam = defaultMeasure
          ? tableList.map((variant) => ({
              ...variant,
              [getIndex]: defaultMeasure,
            }))
          : tableList;
        callback(tableListParam);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, defaultMeasure, readOnly]);

  const onQuantityInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    const newTableList = [...tableList];
    newTableList[index][getIndex] = parseInt(value);
    setTableList(newTableList);

    callback && callback(newTableList);
  };

  const onRemoveFromList = (index: number) => {
    const _list = [...tableList];
    if (index < _list.length) {
      _list.splice(index, 1);

      setTableList(_list);
    }
    callback && callback(tableList);
  };

  return (
    <Table theader={getTheader(columns)}>
      {tableList.map((row, index) => (
        <tr
          key={`variant-${type}-table-row-${index}`}
          css={css`
            background-color: ${index % 2 === 0
              ? theme.colors.white
              : theme.colors.veryLightGrey};
          `}
        >
          <td>
            <Paragraph
              variant="hero_body_sm"
              mb="0!important"
              noDefaultStyle
              px={theme.padding.s}
              py={theme.padding.standard}
            >
              {row.fabric}
            </Paragraph>
          </td>
          <td>
            <Paragraph
              variant="hero_body_sm"
              mb="0!important"
              noDefaultStyle
              px={theme.padding.s}
              py={theme.padding.standard}
            >
              {row.colour}
            </Paragraph>
          </td>
          <td>
            <Paragraph
              variant="hero_body_sm"
              mb="0!important"
              noDefaultStyle
              px={theme.padding.s}
              py={theme.padding.standard}
            >
              {typeof row.size === 'string' ? row.size : row.size?.UK}
            </Paragraph>
          </td>
          <td>
            {/* <InputField
              name="table_quantity"
              defaultValue={row.quantity.toString()}
              placeholder=""
              label=""
              width="inherit !important"
            /> */}
            {readOnly ? (
              <Paragraph
                variant="hero_body_sm"
                mb="0!important"
                noDefaultStyle
                px={theme.padding.s}
                py={theme.padding.standard}
              >
                {row[getIndex] ? row[getIndex] : 0}
              </Paragraph>
            ) : (
              <input
                onChange={(event) => onQuantityInputChange(index, event)}
                defaultValue={row[getIndex] ? row[getIndex] : 0}
                value={row[getIndex] ? row[getIndex] : 0}
                css={css`
                  background-color: transparent;
                  width: 5rem;
                  text-align: center;
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 300;
                `}
              />
            )}
          </td>
          <td>
            {!readOnly && (
              <TiDelete
                onClick={() => onRemoveFromList(index)}
                color={theme.colors.error}
                size="1.7rem"
                css={css`
                  transition: all 0.2s ease-in-out;
                  &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                  }
                `}
              />
            )}
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default VariantSelectTable;
