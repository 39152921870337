// import { Card, Button } from 'react-bootstrap'
// import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
// import ProductPreviewList from "../../components/product-preview/product-preview-list";
import Card from 'react-bootstrap/Card'
import { useParams } from 'react-router-dom'
import useHttp from '../../hooks/use-http';
import { StoreType } from '../../types/store-type'
import { ProductType } from '../../types/product-type'
// import { propTypes } from "react-bootstrap/esm/Image";

const OneStorePage = ({
    productCallback
}:{
    productCallback: (product: ProductType) => void
}) => {

    const [store, setStore] = useState<StoreType>();

    const { storeId }: any = useParams();

    const {
        loading,
        error,
        completed,
        data: incomingData
    } = useHttp({ url: process.env.REACT_APP_MY_API_KEY + `/stores/?_id=${storeId}` });

    useEffect(() => {
        if (!loading && completed && incomingData) {
            setStore(incomingData)
        }
        if (error) {
            console.log(error)
        }
    }, [loading, error, completed, incomingData]);

    if (!store) return null;

    return <div>
        <Card className="text-center">
            <Card.Body>
                <Card.Title>Welcome to {store.name}!</Card.Title>
                <Card.Img variant="top" src={store.coverImage} />
                <Card.Text>
                    {store.bio}
                </Card.Text>
            </Card.Body>
        </Card>
        <div >
            {/* <ProductPreviewList store={store} productCallback={productCallback} /> */}
        </div>
    </div>
};

export default OneStorePage;