import { Order } from '../store/orders/orders-types';

export function dynamicSort(sortProperty: string) {
  return function (a: Order, b: Order) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    if (sortProperty === 'name' || sortProperty === 'email') {
      return a.buyer[sortProperty] < b.buyer[sortProperty]
        ? -1
        : a.buyer[sortProperty] > b.buyer[sortProperty]
        ? 1
        : 0;
    } else if (sortProperty === 'date') {
      console.log(sortProperty);
      const aDate = new Date(a.timeStamp);
      const bDate = new Date(b.timeStamp);

      return aDate.getDate() < bDate.getDate()
        ? -1
        : aDate.getDate() > bDate.getDate()
        ? 1
        : 0;
    } else if (sortProperty === 'time') {
      const aDate = new Date(a.timeStamp);
      const bDate = new Date(b.timeStamp);

      return aDate.getHours() < bDate.getHours()
        ? -1
        : aDate.getHours() > bDate.getHours()
        ? 1
        : 0;
    } else if (sortProperty === 'length') {
      return a.products.length < b.products.length
        ? -1
        : a.products.length > b.products.length
        ? 1
        : 0;
    } else {
      return a[sortProperty as keyof Order] < b[sortProperty as keyof Order]
        ? -1
        : a[sortProperty as keyof Order] > b[sortProperty as keyof Order]
        ? 1
        : 0;
    }
  };
}
