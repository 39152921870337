import Paragraph from '../../typography/paragraph';
import theme from '../../../../theme/theme';
import StandardProps from '../../../../theme/standard-props';
import { SerializedStyles } from '@emotion/react';

const ErrorMessage = ({
  error,
  addCss,
  ...styles
}: { error: string; addCss?: SerializedStyles } & StandardProps) => (
  <Paragraph
    m="0"
    p="0"
    pb={theme.padding.xs}
    px={theme.padding.xs}
    color="red"
    variant="input_error"
    position="absolute"
    bottom="0"
    {...styles}
    addCss={addCss}
  >
    {error}
  </Paragraph>
);

export default ErrorMessage;
