import React from 'react';
import Button from '../standard-components/elements/button';
import { Flex } from '../standard-components/layout/flex-box';
import Heading from '../standard-components/typography/heading';
import Paragraph from '../standard-components/typography/paragraph';
import theme from '../../theme/theme';
import LandingPage from '../landing-page-layout/landing-page';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  let navigate = useNavigate();
  return (
    <LandingPage heroImage="landing-hero.jpg">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          404 NOT FOUND
        </Heading>
        <Paragraph variant="hero_body" textAlign="center">
          The web page you have requested cannot be found.
          <br />
          Please check your URL or navigate back to Homepage
          <br />
        </Paragraph>
        <Button
          onClick={() => navigate('/')}
          mt={theme.margins.m}
          minWidth="240px"
          variant="outlined"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
        >
          GO TO HOME
        </Button>
      </Flex>
    </LandingPage>
  );
};

export default Error404;
