/** @jsxImportSource @emotion/react */
import Paragraph from '../../standard-components/typography/paragraph';
import Card from '../../standard-components/elements/card';
import { Dispatch, SetStateAction } from 'react';
import CheckBox from '../../standard-components/elements/check-box';
import Header from '../../standard-components/typography/heading';
import Link from 'react-router';

const QualityAssurance = ({
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Card heading="Quality Assurance">
        <Paragraph>
          These are our terms, conditions and guidelines. Please read them and
          indicate that you agree to them by checking the box.
        </Paragraph>
        <Card heading="Terms and Conditions">
          <a
            href="https://public-information.s3.af-south-1.amazonaws.com/VINCII+Terms+and+Conditions.pdf"
            target="_blank"
            download
          >
            VINCII Terms and Conditions
          </a>
        </Card>
        <Card heading="Customer Privacy Policy">
          <a
            href="https://public-information.s3.af-south-1.amazonaws.com/VINCII+Customer+Privacy+Policy.pdf"
            target="_blank"
            download
          >
            VINCII Customer Privacy Policy
          </a>
        </Card>
        <Card heading="Image Guidline">
          <a
            href="https://public-information.s3.af-south-1.amazonaws.com/VINCII+Image+Guideline.pdf"
            target="_blank"
            download
          >
            VINCII Image Guideline
          </a>
        </Card>
      </Card>
      <Card>
        <CheckBox
          label="I agree to the terms and conditions"
          onClick={() => setChecked((checked) => !checked)}
          isChecked={checked}
        />
      </Card>
    </>
  );
};

export default QualityAssurance;
