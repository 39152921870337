import { appApi } from '../app-api';
import { provideOrdersTagList } from '../util';
import { TGetAllOrdersResponse } from './orders-types';

export const ordersApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query<TGetAllOrdersResponse, string>({
      query: (requestId) => {
        return {
          url: `orders`,
          method: 'get',
          params: {
            storeFK: requestId,
          },
        };
      },
      providesTags: (response) => provideOrdersTagList(response, 'Orders'),
    }),
    updateOrderStatus: builder.mutation<
      TGetAllOrdersResponse,
      { orderID: string; status: string }
    >({
      query: ({ orderID, status }) => {
        return {
          url: 'orders/update-status',
          method: 'get',
          params: {
            id: orderID,
            status,
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Orders', id: arg.orderID },
      ],
    }),
  }),
});

export const { useGetAllOrdersQuery, useUpdateOrderStatusMutation } = ordersApi;
