// import {ProductModel} from '../models/storeModels'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { ProductType } from '../../types/product-type';

const OneProductPage = ({
    product
}: {
    product: ProductType
}) => {

    return <div>
        <Card className="text-center">
            <Card.Header></Card.Header>
            <Card.Img variant="top" src={product.img} />
            <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text>{product.description}</Card.Text>
                <Link to={`/`}><Button variant="primary">Check it out!</Button></Link>
            </Card.Body>
            <Card.Footer className="text-muted">{product.price}</Card.Footer>
        </Card>
    </div>
};

export default OneProductPage;