import { OrderStatus } from '../store/orders/orders-types';

export const OrderStatusMap: Record<
  OrderStatus,
  { label: string | null; color: string }
> = {
  [OrderStatus.placed]: { label: null, color: '#0275d8' },
  [OrderStatus.paid]: { label: null, color: '#5cb85c' },
  [OrderStatus.missed]: { label: null, color: '#292b2c' },
  [OrderStatus.declined]: { label: null, color: '#d9534f' },
  [OrderStatus.refunded]: { label: null, color: '#f7f7f7' },
  [OrderStatus.retailerMissedFlag]: { label: null, color: '#5cb85c' },
  [OrderStatus.paymentUnsuccesful]: { label: null, color: '#d9534f' },
  [OrderStatus.acceptedOrderStale]: {
    label: null,
    color: '#292b2c',
  },
  [OrderStatus.confirmed]: { label: null, color: '#5cb85c' },
  [OrderStatus.waybillCreated]: { label: null, color: '#5cb85c' },
  [OrderStatus.collectionTriggered]: {
    label: 'Courier En Route',
    color: '#5cb85c',
  },
  [OrderStatus.itemCollected]: {
    label: 'En Route to Customer',
    color: '#5cb85c',
  },
  [OrderStatus.itemDelivered]: {
    label: 'En Route to Customer',
    color: '#5cb85c',
  },
  [OrderStatus.retailerDue]: { label: 'Order Delivered', color: '#5cb85c' },
  [OrderStatus.retailerPaid]: {
    label: 'Order Complete',
    color: '#5cb85c',
  },
};
