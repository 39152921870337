import React from 'react';
import { Flex } from '../../layout/flex-box';
import { FaChevronRight } from 'react-icons/fa';
import theme from '../../../../theme/theme';

const BreadCrumb = ({ levels }: { levels: string[] }) => {
  return (
    <Flex>
      {levels.map((level, ind) => {
        return (
          <span
            style={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: '600',
            }}
            key={`breadcrumb-level-${ind}`}
          >
            {level}
            {levels.length - 1 === ind ? (
              ''
            ) : (
              <FaChevronRight
                fontSize="14px"
                style={{ margin: theme.margins.xs }}
              />
            )}
          </span>
        );
      })}
    </Flex>
  );
};

export default BreadCrumb;
