import React from 'react';
import theme from '../../../theme/theme';
import Card from '../../standard-components/elements/card';
import OrderHeaderRow from '../order-header-row';
// import OrderSearch from '../order-search';

const OrderHeader = ({
  setSortParameter,
  setFilterParameter,
  setSearchParameter,
  detailsFK,
}: {
  setSortParameter: (searchParam: string) => void;
  setFilterParameter: (filterParam: string) => void;
  setSearchParameter: (searchValue: string) => void;
  detailsFK?: string;
}) => {
  return (
    <Card backgroundColor={theme.colors.headerColor} boxShadow="medium">
      {/* <OrderSearch
        setFilterParameter={setFilterParameter}
        setSearchParameter={setSearchParameter}
      /> */}
      <OrderHeaderRow
        handleSortParameter={setSortParameter}
        detailsFK={detailsFK}
      />
    </Card>
  );
};

export default OrderHeader;
