/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import StandardCSS from '../../../../theme/standard-css';
import DefaultCss from '../../../../theme/standard-default-css';
const InputLabel = ({
  label,
  htmlFor,
  addCss,
  noDefaultStyle,
  ...styles
}: {
  label: string;
  htmlFor: string;
  noDefaultStyle?: boolean;
  addCss?: SerializedStyles;
} & StandardProps) => (
  <label
    className="card-input-label"
    css={css`
      margin: 0;
      ${!noDefaultStyle && DefaultCss()}
      ${StandardCSS(styles)}
      font-size: ${theme.fontSizes.normal};
      color: ${theme.colors.supportingText};
      font-weight: ${theme.fontWeights.semiBold};
      &:focus {
      }
      ${addCss}
    `}
    htmlFor={htmlFor}
  >
    {label}
  </label>
);

export default InputLabel;
