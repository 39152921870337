import LeftNavigation from '../../components/standard-components/elements/left-navigation';
import MyShopContent from '../../components/my-shop';
import { useNavigate, useParams } from 'react-router-dom';
import MyShopPageLayout from '../../components/my-shop-page-layout';
import { useSignoutMutation } from '../../store/auth/auth-api';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import ReactLoading from 'react-loading';

import {
  RiLogoutBoxLine,
  RiStore3Line,
  RiFeedbackLine,
  RiShoppingCartLine,
  RiNewspaperLine,
} from 'react-icons/ri';
import { FaWarehouse } from 'react-icons/fa';
import TabItem, {
  MultiTabItem,
  TabItemSecondary,
} from '../../components/standard-components/elements/tab-item';
import { useState } from 'react';
import { useGetStoreOverviewQuery } from '../../store/my-shop';
import { Flex } from '../../components/standard-components/layout/flex-box';
import theme from '../../theme/theme';

export type Tabs =
  | 'products'
  | 'feedback'
  | 'contact-us'
  | 'account-details'
  | 'manage-store'
  | 'signout'
  | 'manufacturing'
  | 'orders'
  | 'marketing';

const MyShop = () => {
  const { tabName, storeID } = useParams();
  const [storeDetailsExpanded, setStoreDetailsExpanded] = useState(false);
  const [signout] = useSignoutMutation();
  const { data, isLoading } = useGetStoreOverviewQuery(storeID ?? '');
  const navigate = useNavigate();
  const handleSignOut = () => {
    signout('Log Out');
    navigate('/login', { replace: true });
  };
  const activeTab = tabName as Tabs;
  const handleSetStoreTab = () => {
    if (storeDetailsExpanded) {
      setStoreDetailsExpanded(false);
    } else {
      setStoreDetailsExpanded(true);
      navigate(`/my-shop/account-details/${storeID}`, {
        replace: true,
      });
    }
  };
  return (
    <MyShopPageLayout>
      <LeftNavigation heading="My Shop">
        <TabItem
          icon={<HiOutlineShoppingBag size="1.2rem" />}
          setTab={() =>
            navigate(`/my-shop/products/${storeID}`, {
              replace: true,
            })
          }
          active={activeTab === 'products'}
          title="Products"
        />
        <TabItem
          icon={<RiShoppingCartLine size="1.2rem" />}
          setTab={() =>
            navigate(`/my-shop/orders/${storeID}`, {
              replace: true,
            })
          }
          active={activeTab === 'orders'}
          title="Orders"
        />
        {(data?.status === 'active' ||
          data?.status === 'inactive' ||
          data?.status === 'pending') && (
          <MultiTabItem
            expanded={
              storeDetailsExpanded &&
              (activeTab === 'account-details' || activeTab === 'manage-store')
            }
            icon={<RiStore3Line size="1.2rem" />}
            setTab={handleSetStoreTab}
            active={
              activeTab === 'account-details' || activeTab === 'manage-store'
            }
            title="Shop Details"
          >
            <TabItemSecondary
              setTab={() =>
                navigate(`/my-shop/account-details/${storeID}`, {
                  replace: true,
                })
              }
              active={activeTab === 'account-details'}
              title="Account"
            />
            <TabItemSecondary
              setTab={() =>
                navigate(`/my-shop/manage-store/${storeID}`, {
                  replace: true,
                })
              }
              active={activeTab === 'manage-store'}
              title="Manage Store"
            />
          </MultiTabItem>
        )}
        <TabItem
          icon={<FaWarehouse size="1.2rem" />}
          setTab={() =>
            navigate(`/my-shop/manufacturing/${storeID}`, {
              replace: true,
            })
          }
          active={activeTab === 'manufacturing'}
          title="Manufacturing"
        />
        <TabItem
          icon={<RiNewspaperLine size="1.2rem" />}
          setTab={() =>
            navigate(`/my-shop/marketing/${storeID}`, {
              replace: true,
            })
          }
          active={activeTab === 'marketing'}
          title="Marketing"
        />
        <TabItem
          icon={<RiFeedbackLine size="1.2rem" />}
          setTab={() =>
            navigate(`/my-shop/feedback/${storeID}`, {
              replace: true,
            })
          }
          active={activeTab === 'feedback'}
          title="Feedback"
        />
        <TabItem
          icon={<RiLogoutBoxLine size="1.2rem" />}
          setTab={handleSignOut}
          active={activeTab === 'signout'}
          title="Sign Out"
        />
      </LeftNavigation>
      {isLoading || !data ? (
        <Flex
          noDefaultStyle
          justifyContent="center"
          alignItems="center"
          width="100%"
          boxShadow="small"
        >
          <ReactLoading
            type="spinningBubbles"
            height="15rem"
            width="15rem"
            color={theme.colors.primaryCta}
          />
        </Flex>
      ) : (
        <MyShopContent activeTab={activeTab} />
      )}
    </MyShopPageLayout>
  );
};

export default MyShop;
