import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { appApi } from './app-api';
import authReducer from './auth/auth-slice';
import onboardingReducer from './onboarding/onboarding-slice';
import productsReducer from './products/product-slice';
import notificationReducer from './notifications/notification-slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    onboarding: onboardingReducer,
    products: productsReducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
