import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentUser,
  selectPendingVerificationUser,
} from '../store/auth/auth-slice';

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);
  const {
    user: pendingUser,
    verifiedStatus,
    retailer,
    storeName,
    timeToTokenExpiry,
    retailerDetailsID,
  } = useSelector(selectPendingVerificationUser);

  return useMemo(
    () => ({
      user,
      pendingUser,
      verifiedStatus,
      retailer,
      storeName,
      timeToTokenExpiry,
      retailerDetailsID,
    }),
    [
      user,
      pendingUser,
      verifiedStatus,
      retailer,
      storeName,
      timeToTokenExpiry,
      retailerDetailsID,
    ]
  );
};
