import React, { MouseEventHandler } from 'react';
import theme from '../../../theme/theme';
import Button from '../../standard-components/elements/button';
import { Flex } from '../../standard-components/layout/flex-box';
import Paragraph from '../../standard-components/typography/paragraph';

const RetailerStoreFooter = ({
  error,
  isLoading,
  isModified,
  onClickBack,
  onClickUpload,
  onClickDiscard,
  buttonText,
}: {
  error: string[];
  isLoading: boolean;
  isModified: boolean;
  onClickBack: MouseEventHandler<HTMLElement>;
  onClickUpload: MouseEventHandler<HTMLElement>;
  onClickDiscard: MouseEventHandler<HTMLElement>;
  buttonText?: string;
}) => {
  return (
    <Flex
      borderRadius="0"
      m="0"
      py={theme.padding.standard}
      backgroundColor={theme.colors.background}
    >
      {error.length > 0 && (
        <Paragraph ml="auto" variant="input_error" color="red">
          {error[error.length - 1]}
        </Paragraph>
      )}
      {isModified && (
        <Button
          ml="auto"
          backgroundColor={theme.colors.halfDark}
          variant="flat"
          onClick={onClickDiscard}
        >
          Discard Changes
        </Button>
      )}

      {buttonText && (
        <Button
          isLoading={isLoading}
          backgroundColor={
            isModified ? theme.colors.primaryCta : theme.colors.darkGreyStandard
          }
          variant="flat"
          onClick={onClickUpload}
          disabled={!isModified}
          color={isModified ? theme.colors.white : theme.colors.dark}
        >
          {buttonText}
        </Button>
      )}

      {!buttonText && (
        <Button
          isLoading={isLoading}
          backgroundColor={
            isModified ? theme.colors.primaryCta : theme.colors.darkGreyStandard
          }
          variant="flat"
          onClick={onClickUpload}
          disabled={!isModified}
          color={isModified ? theme.colors.white : theme.colors.dark}
        >
          Save Product
        </Button>
      )}
    </Flex>
  );
};

export default RetailerStoreFooter;
