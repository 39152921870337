/** @jsxImportSource @emotion/react */
import Paragraph from '../../standard-components/typography/paragraph';
import Card from '../../standard-components/elements/card';
import FormWrapper from '../../form-wrapper';
import { SinglePDFDropzone } from '../../dropzone';
import { useContext } from 'react';
import { OnboardingContext } from '../../../context/onboarding/OnboardingContext';

const Verification = () => {
  const { onboardingDispatch } = useContext(OnboardingContext)!;
  const handleUploadConfirm = (document: any) => {
    onboardingDispatch({
      type: 'document_upload',
      payload: {
        key: 'retailerVerification',
        data: document,
      },
    });
  };
  return (
    <Card heading="Verification (MUST BE IN PDF FORMAT)">
      <Paragraph>
        Please upload a copy of the ID document of the store owner below. If
        there are multiple store owners we only require the ID document of one
        store owner.
      </Paragraph>
      <Paragraph>We require this for legal purposes.</Paragraph>
      <FormWrapper columns={1}>
        <SinglePDFDropzone onUploadConfirm={handleUploadConfirm} />
      </FormWrapper>
    </Card>
  );
};

export default Verification;
