/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useUpdateOrderStatusMutation } from '../../../store/orders/orders-api';
import { Order, OrderStatus } from '../../../store/orders/orders-types';
import theme from '../../../theme/theme';
import Button from '../../standard-components/elements/button';
import { Flex } from '../../standard-components/layout/flex-box';
import OrderRowItem from './OrderRowItem';
import { MdAdsClick } from 'react-icons/md';
import Paragraph from '../../standard-components/typography/paragraph';
import { OrderStatusMap } from '../../../constants/orders';
import { RiContactsBookLine } from 'react-icons/ri';

const OrderStatusItem = ({
  status,
  orderID,
}: {
  status: Order['status'];
  orderID: string;
}) => {
  const orderStatus = status[status.length - 1].status as OrderStatus;
  const [updateOrderStatus, { isLoading }] = useUpdateOrderStatusMutation();

  const displayStatus = () => {
    switch (orderStatus) {
      case 'PLACED':
        return (
          <Paragraph
            m="0"
            p="0"
            variant="grid_header_row"
            color={OrderStatusMap[orderStatus].color}
            backgroundColor={theme.colors.white}
          >
            -----
          </Paragraph>
        );

      case 'PAID':
        return (
          <Flex noDefaultStyle justifyContent="center">
            <Button
              mx={theme.margins.xs}
              ml="0"
              px="5px"
              variant="icon"
              border="none"
              color={theme.colors.white}
              backgroundColor={OrderStatusMap.PAID.color}
              my="0"
              fontSize="10px"
              onClick={() =>
                updateOrderStatus({ orderID, status: 'RETAILER_CONFIRMED' })
              }
              boxShadow="large"
            >
              <span
                css={css`
                  margin-right: 2px;
                `}
              >
                Accept
              </span>
              <MdAdsClick />
            </Button>
            <Button
              mx={theme.margins.xs}
              px="5px"
              mr="0"
              color={theme.colors.white}
              border="none"
              variant="icon"
              backgroundColor={OrderStatusMap.RETAILER_DECLINED.color}
              my="0"
              fontSize="10px"
              onClick={() =>
                updateOrderStatus({ orderID, status: 'RETAILER_DECLINED' })
              }
              boxShadow="large"
            >
              <span
                css={css`
                  margin-right: 4px;
                `}
              >
                Reject
              </span>
              <MdAdsClick />
            </Button>
          </Flex>
        );

      case 'RETAILER_MISSED_FLAG':
        return (
          <Flex noDefaultStyle justifyContent="center">
            <Button
              mx={theme.margins.xs}
              ml="0"
              px="5px"
              variant="icon"
              border="none"
              color={theme.colors.white}
              backgroundColor={OrderStatusMap.PAID.color}
              my="0"
              fontSize="10px"
              onClick={() =>
                updateOrderStatus({ orderID, status: 'RETAILER_CONFIRMED' })
              }
              boxShadow="large"
            >
              <span
                css={css`
                  margin-right: 2px;
                `}
              >
                Accept
              </span>
              <MdAdsClick />
            </Button>
            <Button
              mx={theme.margins.xs}
              px="5px"
              mr="0"
              color={theme.colors.white}
              border="none"
              variant="icon"
              backgroundColor={OrderStatusMap.RETAILER_DECLINED.color}
              my="0"
              fontSize="10px"
              onClick={() =>
                updateOrderStatus({ orderID, status: 'RETAILER_DECLINED' })
              }
              boxShadow="large"
            >
              <span
                css={css`
                  margin-right: 4px;
                `}
              >
                Reject
              </span>
              <MdAdsClick />
            </Button>
          </Flex>
        );

      case 'RETAILER_CONFIRMED':
        return (
          <Button
            variant="icon"
            border="none"
            color={theme.colors.white}
            backgroundColor={OrderStatusMap.PAID.color}
            my="0"
            fontSize="11px"
            onClick={() =>
              updateOrderStatus({ orderID, status: 'COLLECTION_TRIGGERED' })
            }
            boxShadow="large"
          >
            <span
              css={css`
                margin-right: 4px;
              `}
            >
              Collect My Order
            </span>
            <MdAdsClick />
          </Button>
        );

      case 'ACCEPTED_ORDER_STALE':
        return (
          <Button
            variant="icon"
            border="none"
            color={theme.colors.white}
            backgroundColor={OrderStatusMap.PAID.color}
            my="0"
            fontSize="11px"
            onClick={() =>
              updateOrderStatus({ orderID, status: 'COLLECTION_TRIGGERED' })
            }
            boxShadow="large"
          >
            <span
              css={css`
                margin-right: 4px;
              `}
            >
              Collect My Order
            </span>
            <MdAdsClick />
          </Button>
        );

      default:
        return (
          <Paragraph
            m="0"
            p="0"
            variant="grid_header_row"
            color={OrderStatusMap[orderStatus].color}
            backgroundColor={theme.colors.white}
          >
            {OrderStatusMap[orderStatus].label}
          </Paragraph>
        );
    }
  };
  return (
    <OrderRowItem borderRadius={theme.borderRadius.small}>
      {isLoading ? (
        <Button
          backgroundColor="transparent"
          color="#000"
          cursor="default"
          border="none"
          fontSize="13px"
          m="0"
        >
          Loading
        </Button>
      ) : (
        displayStatus()
      )}
    </OrderRowItem>
  );
};

export default OrderStatusItem;
