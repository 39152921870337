import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../auth/auth-api';
import { ILoginResponse } from '../auth/auth-types';
import { productApi } from './product-api';
import { Product, TGetAllProductsResponse } from './product-types';
const initialState: {
  products: Product[];
  storeFK: null | string;
} = {
  products: [],
  storeFK: null,
};
const Products = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.signinUser.matchFulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          if (action.payload.msg.retailerStore)
            state.storeFK = action.payload.msg.retailerStore;
        }
      )
      .addMatcher(
        productApi.endpoints.getAllProducts.matchFulfilled,
        (state, { payload }: PayloadAction<TGetAllProductsResponse>) => {
          state.products = payload.result;
        }
      )
      .addMatcher(
        productApi.endpoints.searchProucts.matchFulfilled,
        (state, { payload }: PayloadAction<TGetAllProductsResponse>) => {
          state.products = payload.result;
        }
      )
      .addMatcher(
        productApi.endpoints.uploadProducts.matchFulfilled,
        (state, { payload }: any) => {
          if (payload) {
            state.products.push(payload.product);
          }
        }
      )
      .addMatcher(
        productApi.endpoints.deleteProduct.matchFulfilled,
        (
          state,
          { payload }: PayloadAction<{ _id: string; storeID: string }>
        ) => {
          if (payload) {
            state.products = state.products.filter(
              (product) => product._id !== payload._id
            );
          }
        }
      )
      .addMatcher(
        productApi.endpoints.deleteProduct.matchRejected,
        (state, action) => {
          console.log(action.payload);
        }
      );
  },
});

export const productsActions = Products.actions;
export default Products.reducer;
