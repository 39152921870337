/** @jsxImportSource @emotion/react */
import Paragraph from '../../components/standard-components/typography/paragraph';
import Heading from '../../components/standard-components/typography/heading';
import Button from '../../components/standard-components/elements/button';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Grid from '../../components/standard-components/layout/grid';
import Image from '../../components/standard-components/elements/image';
import { css } from '@emotion/react';
import theme from '../../theme/theme';
import LeftNavigation from '../../components/standard-components/elements/left-navigation';
import ContentArea from '../../components/standard-components/elements/content-area';
import OnboardingPageLayout from '../../components/onboarding-page-layout';
import FormWrapper from '../../components/form-wrapper';
import InputField from '../../components/standard-components/elements/input-field';
import Card from '../../components/standard-components/elements/card';
import OnboardingSuccess from '../../components/onboarding-success';
import ConfirmVerification from '../confirm-verification';
import Login from '../login';
import RequestVerification from '../request-verification';
import Signup from '../signup';
import { useAppDispatch } from '../../store/hooks';
import { notificationActions } from '../../store/notifications/notification-slice';
import { useEffect, useState } from 'react';
import { SingleImageDropzone } from '../../components/dropzone';
import MultipleSelectionDropdownMenu from '../../components/multiple-selection-dropdown';
import TagInput from '../../components/standard-components/elements/tag-input';

const ComponentTestPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      notificationActions.pushNotification({
        message: 'No data provided',
        type: 'error',
        autoClose: true,
        closeTime: 2000,
        positionY: 'top',
        positionX: 'center',
      })
    );
    dispatch(
      notificationActions.pushNotification({
        message: 'Data submitted successfully',
        type: 'success',
        autoClose: false,
        positionY: 'top',
        positionX: 'left',
      })
    );
    dispatch(
      notificationActions.pushNotification({
        message: 'Image is not provided, card will not render',
        type: 'warning',
        autoClose: false,
        positionY: 'bottom',
        positionX: 'right',
      })
    );
  }, [dispatch]);

  const dropDownTestData = [
    'item1',
    'item2',
    'item3',
    'item4',
    'item5',
    'item6',
  ];
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [tags, setTags] = useState<string[]>([]);

  const handleUpdateTagInput = (event: any) => {
    if (event.key !== 'Enter') return;
    const { value } = event.target;
    if (!value.trim()) return;

    setTags((tags) => [...tags, value]);
    event.target.value = '';
  };

  const handleClose = (index: number) => {
    setTags(tags.filter((el, i) => i !== index));
  };

  const handleMultipleSelection = (
    currentValues: string[],
    clickedItem: string
  ) => {
    const isCurrentValueSelected = currentValues.includes(clickedItem);
    if (isCurrentValueSelected) {
      setSelectedValues(currentValues.filter((value) => value !== clickedItem));
      return;
    }
    setSelectedValues([...currentValues, clickedItem]);
  };

  return (
    <Flex flexDirection="column" mx="20px">
      <Heading
        variant="card_heading"
        color={theme.colors.primaryCta}
        justifyText="center"
        textAlign="center"
      >
        Welcome to the Vincii components showcase page
      </Heading>
      <Heading
        variant="card_sub_heading"
        justifyText="center"
        textAlign="center"
        alignContent="center"
        color="black"
        borderRadius={0}
        pb="10px"
        addCss={css`
          border-bottom: 1px solid grey;
        `}
      >
        The idea is to provide a space for developer to test and showcase their
        components during the development process, especially if the pages that
        these components should go to are still under development. Feel free to
        create new sections on this page as needed. It will never go into
        production.
      </Heading>
      <Heading
        variant="card_heading"
        justifyText="center"
        textAlign="center"
        pb="10px"
        borderRadius={0}
        addCss={css`
          border-bottom: 1px solid grey;
        `}
      >
        TYPOGRAPHY
      </Heading>
      <Heading
        variant="card_heading"
        color="#000000"
        justifyText="center"
        textAlign="center"
      >
        Main Heading
      </Heading>
      <Heading variant="card_item_heading">Sub Heading</Heading>
      <Heading variant="card_sub_heading">Sub sub heading</Heading>
      <Paragraph variant="card_body">Here we have a test paragraph</Paragraph>
      <Heading
        variant="card_heading"
        justifyText="center"
        textAlign="center"
        py="10px"
        borderRadius={0}
        addCss={css`
          border-top: 1px solid grey;
          border-bottom: 1px solid grey;
        `}
      >
        ELEMENTS
      </Heading>
      <Heading variant="card_item_heading">Dropdowns</Heading>
      <Flex width="40%" flexDirection="column">
        <MultipleSelectionDropdownMenu
          currentValues={selectedValues}
          options={dropDownTestData}
          title="Multiple selection dropdown"
          callback={handleMultipleSelection}
        />
        <Paragraph>Selected values:</Paragraph>
        {selectedValues.length > 0 &&
          selectedValues.map((value) => <Paragraph>{value}</Paragraph>)}
      </Flex>
      <Heading variant="card_item_heading">Buttons</Heading>
      <Button variant="flat">FLAT BUTTON</Button>
      <Button variant="outlined">OUTLINED BUTTON </Button>
      <SingleImageDropzone
        imageMaxHeight={1440}
        imageMinHeight={576}
        imageMaxWidth={1000}
        imageMinWidth={400}
        name="test"
        description="test"
        onUploadConfirm={() => null}
      />
      <OnboardingPageLayout backgroundColor={theme.colors.headerColor}>
        <LeftNavigation heading="Retailer OnBoarding">
          Child components go here
        </LeftNavigation>
        <ContentArea>
          <Card heading="Card Details">
            <Paragraph variant="card_body" pl="0" ml="0" pb="0" mb="0">
              Please note You willl not be charged until after your free trial
              has expired on the 10 november 2022. You can cancel anytime.
            </Paragraph>
            <FormWrapper columns={4}>
              <InputField label="Card Number" placeholder="" />
              <InputField label="Expiration" placeholder="" />
              <InputField label="cvv" placeholder="" />
              <InputField label="ZIP Code" placeholder="" />
            </FormWrapper>
            <FormWrapper columns={2}>
              <InputField label="Card Region" placeholder="South Africa" />
              <InputField
                label="Address"
                placeholder="4 Street Name, Suburb Name"
              />
              <TagInput
                name=""
                tags={tags}
                onKeyDown={handleUpdateTagInput}
                onClose={handleClose}
              />
            </FormWrapper>
          </Card>
        </ContentArea>
      </OnboardingPageLayout>
      {/** The maxHeight prop and mb are included just to
       * constrain the landing page to a size suitable for showcase purposes */}

      <Heading
        variant="card_heading"
        justifyText="center"
        textAlign="center"
        pb="10px"
        borderRadius={0}
        addCss={css`
          border-bottom: 1px solid grey;
        `}
      >
        TYPOGRAPHY
      </Heading>
      <Heading
        variant="card_heading"
        color="#000000"
        justifyText="center"
        textAlign="center"
      >
        Main Heading
      </Heading>
      <Heading variant="card_item_heading">Sub Heading</Heading>
      <Heading variant="card_sub_heading">Sub sub heading</Heading>
      <Paragraph variant="card_body">Here we have a test paragraph</Paragraph>
      <Heading
        variant="card_heading"
        justifyText="center"
        textAlign="center"
        py="10px"
        borderRadius={0}
        addCss={css`
          border-top: 1px solid grey;
          border-bottom: 1px solid grey;
        `}
      >
        ELEMENTS
      </Heading>
      <Heading variant="card_item_heading">Buttons</Heading>
      <Button variant="flat">FLAT BUTTON</Button>
      <Button variant="outlined">OUTLINED BUTTON </Button>
      <Flex width="100%" justifyContent="center" flexDirection="column">
        <Heading variant="card_item_heading" textAlign="center" color="grey">
          An image grid
        </Heading>
        <Grid
          gridTemplateColumns="repeat(2, 1fr)"
          gridRowGap="10px"
          gridColumnGap="20px"
          px="20px"
          addCss={css`
            max-width: 100vw;
          `}
        >
          <Image
            src="https://picsum.photos/200/300"
            alt="random image"
            borderRadius="100px"
            height="300px"
            width="200px"
          />
          <Image
            src="https://picsum.photos/200/300"
            alt="random image"
            height="100px"
            width="200px"
          />
          <Image
            src="https://picsum.photos/200/300"
            alt="random image"
            isSquare
            imageCaption="Square image, maintains original aspect ratio"
          />
          <Image
            src="https://picsum.photos/200/300"
            alt="random image"
            isSquare
            objectFit="fill"
            imageCaption="Square image that forces the image to fill the space"
          />
          <Image
            src="https://picsum.photos/200/300"
            alt="random image"
            borderRadius="1px"
            imageCaption="Changed the border radius using props"
          />
          <Image src="https://picsum.photos/200/300" alt="random image" />
        </Grid>
      </Flex>
      <OnboardingPageLayout backgroundColor={theme.colors.headerColor}>
        <LeftNavigation heading="Retailer OnBoarding">
          Child components go here
        </LeftNavigation>
        <ContentArea>
          <Card heading="Card Details">
            <Paragraph variant="card_body" pl="0" ml="0" pb="0" mb="0">
              Please note You willl not be charged until after your free trial
              has expired on the 10 november 2022. You can cancel anytime.
            </Paragraph>
            <FormWrapper columns={4}>
              <InputField label="Card Number" placeholder="" />
              <InputField label="Expiration" placeholder="" />
              <InputField label="cvv" placeholder="" />
              <InputField label="ZIP Code" placeholder="" />
            </FormWrapper>
            <FormWrapper columns={2}>
              <InputField label="Card Region" placeholder="South Africa" />
              <InputField
                label="Address"
                placeholder="4 Street Name, Suburb Name"
              />
            </FormWrapper>
          </Card>
        </ContentArea>
      </OnboardingPageLayout>
      {/** The maxHeight prop and mb are included just to
       * constrain the landing page to a size suitable for showcase purposes */}

      <RequestVerification />

      <ConfirmVerification />

      <OnboardingSuccess />
      <Signup />
      <Login />
    </Flex>
  );
};
export default ComponentTestPage;
