import { ChangeEventHandler, MouseEventHandler } from 'react';
import theme from '../../theme/theme';
import Card from '../standard-components/elements/card';
import Form from '../standard-components/elements/form';
import InputField from '../standard-components/elements/input-field';

const ProductSearch = ({
  storeID,
  searchInput,
  setSearchInput,
  handleOnSearchSubmit,
}: {
  storeID: string;
  searchInput: string;
  setSearchInput: ChangeEventHandler<HTMLInputElement>;
  handleOnSearchSubmit: MouseEventHandler<HTMLFormElement>;
}) => {
  return (
    <Card backgroundColor={theme.colors.BGAdmin}>
      {/* <Grid gridTemplateColumns="10fr 1fr"> */}
      <Form hideSubmitButton onSubmit={handleOnSearchSubmit} p="0" m="0">
        <InputField
          defaultValue={searchInput}
          onChange={setSearchInput}
          placeholder="Search..."
          width="100%"
          fontSize={theme.fontSizes.medium}
        />
      </Form>
      {/* </Grid> */}
    </Card>
  );
};

export default ProductSearch;
