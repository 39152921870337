import OneStorePage from './pages/retailers/store-page';
import OneProductPage from './pages/second-hand/product-page';
import OnboardingPage from './pages/onboarding';
import { ProductType } from './types/product-type';

import { Navigate, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import ComponentTestPage from './pages/showcase';
import OnboardingContextProvider from './context/onboarding/OnboardingContext';
import Login from './pages/login/index';
import Error404 from './components/404';
import OnboardingSuccess from './components/onboarding-success';
import PrivateRoute from './components/private-route';
import ConfirmVerification from './pages/confirm-verification';
import RequestVerification from './pages/request-verification';
import Signup from './pages/signup';
import MyShop from './pages/my-shop';
import MyShopContextProvider from './context/my-shop/MyShopContext';
import ToastNotification from './components/standard-components/elements/toast-notification';
import { notificationActions } from './store/notifications/notification-slice';
import { useAppDispatch, useAppSelector } from './store/hooks';

const App = () => {
  const [product, setProduct] = useState<ProductType>();

  const toastList = useAppSelector((state) => state.notification.toastList);
  const dispatch = useAppDispatch();

  return (
    <div className="App">
      <ToastNotification
        toastList={toastList}
        removeNotificationCallback={(index: number) =>
          dispatch(notificationActions.removeNotification(index))
        }
      />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute role="user" fallbackTo="/login">
              {/* {<AllStoresPage />} */}
              <Navigate to="/onboarding-success" />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route
          path="/send-verify-mail"
          element={
            <PrivateRoute role="client" fallbackTo="/">
              <RequestVerification />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/email-verify/:confirmation_code"
          element={<ConfirmVerification />}
        />

        <Route path="/showcase" element={<ComponentTestPage />} />

        <Route
          path="/store/:storeId"
          element={<OneStorePage productCallback={setProduct} />}
        />

        {/*The '!' tells JavaScript that even though it looks like product might be null, it can trust us that it won't be null.
         */}
        <Route
          path="/product/:storeId/:productId"
          element={<OneProductPage product={product!} />}
        />

        <Route
          path="/onboarding"
          element={
            <PrivateRoute role="user" fallbackTo="/login">
              <OnboardingContextProvider>
                <OnboardingPage />
              </OnboardingContextProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/onboarding_test"
          element={
            <OnboardingContextProvider>
              <OnboardingPage />
            </OnboardingContextProvider>
          }
        />
        <Route
          path="/onboarding-success/:storeID"
          element={
            <OnboardingContextProvider>
              <OnboardingSuccess />
            </OnboardingContextProvider>
          }
        />
        <Route
          path="/my-shop/:tabName/:storeID"
          element={
            <PrivateRoute role="retailer" fallbackTo="/login">
              <MyShopContextProvider>
                <MyShop />
              </MyShopContextProvider>
            </PrivateRoute>
          }
        />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
};

export default App;
