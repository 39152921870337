import { SerializedStyles } from '@emotion/serialize';
import StandardProps from '../../../../theme/standard-props';
import Container from '../../layout/container';

const ContentArea = ({
  addCss,
  children,
  ...styles
}: {
  children?: any;
  addCss?: SerializedStyles;
} & StandardProps) => (
  <Container
    borderRadius="0"
    flex="1"
    m="0"
    alignItems="flex-start"
    addCss={addCss}
    {...styles}
  >
    {children}
  </Container>
);

export default ContentArea;
