import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { SingleImageDropzone } from '../../dropzone';
import FormWrapper from '../../form-wrapper';
import Image from '../../standard-components/elements/image';
import Container from '../../standard-components/layout/container';
import Heading from '../../standard-components/typography/heading';

const StoreImageEdit = ({
  logo,
  banner,
  handleUploadConfirm,
  imageEditCallBack,
}: {
  logo?: string;
  banner?: string;
  imageEditCallBack: (name: string, required: boolean) => void;
  handleUploadConfirm: (image: Blob, name: string) => void;
}) => {
  const handleImageEdit = (name: string, required: boolean) => {
    setPreview((preview) => ({ ...preview, [name]: null }));
    imageEditCallBack && imageEditCallBack(name, required);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<{ name: string; image: Blob }[]>([]);
  const [preview, setPreview] = useState<{ [key: string]: string | null }>({
    logo: null,
    banner: null,
  });
  const getBlobFromUrl = (imageUrl: string, name: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('GET', `${imageUrl}?${Date.now()}`, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve({
          name,
          image: request.response,
        });
      };
      request.onerror = reject;
      request.send();
    });
  };

  useEffect(() => {
    setPreview({
      logo: logo ?? null,
      banner: banner ?? null,
    });
    setLoading(true);
    (async () => {
      try {
        console.log('LOGO:', logo, 'BANNER:', banner);
        const results = await Promise.all([
          logo && getBlobFromUrl(logo, 'logo'),
          banner && getBlobFromUrl(banner, 'banner'),
        ]);
        console.log('RESULTS:', results);
        const imagesResult = results
          .filter((image) => {
            return image && (image !== '' || image !== undefined);
          })
          .filter((image: { name: string; image: Blob }) => {
            return image.image && image.image.type.includes('image');
          });
        imagesResult && setImages(imagesResult);
        console.log('IMAGE RESULTS:', imagesResult);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [logo, banner]);
  return (
    <FormWrapper my="0" py="0" columns={2}>
      {preview.logo ? (
        <Preview
          loading={loading}
          name="logo"
          description="Shop Logo *"
          images={images}
          handleImageEdit={handleImageEdit}
          required
        />
      ) : (
        <SingleImageDropzone
          description="Shop Logo *"
          previewRound
          name="logo"
          imageMinHeight={200}
          imageMinWidth={200}
          imageMaxHeight={800}
          imageMaxWidth={800}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
        />
      )}

      {preview.banner ? (
        <Preview
          loading={loading}
          name="banner"
          images={images}
          description="Shop Banner *"
          handleImageEdit={handleImageEdit}
        />
      ) : (
        <SingleImageDropzone
          description="Shop Banner *"
          name="banner"
          imageMinHeight={200}
          imageMinWidth={400}
          imageMaxHeight={800}
          imageMaxWidth={1600}
          onUploadConfirm={handleUploadConfirm}
          minFileSize={10000}
        />
      )}
    </FormWrapper>
  );
};

const Preview = ({
  name,
  loading,
  required = false,
  images,
  description,
  handleImageEdit,
}: {
  name: string;
  required?: boolean;
  loading: boolean;
  description: string;
  images: { name: string; image: Blob }[];
  handleImageEdit: (name: string, required: boolean) => void;
}) => {
  return (
    <Container
      width={`${480}px`}
      height={`${322}px`}
      m={0}
      mb="60px"
      p={0}
      addCss={css`
        aspect-ratio: ${480} / ${322};
      `}
      position="relative"
    >
      <Heading variant="nav_body">{description}</Heading>
      <Container
        right="2rem"
        top="1rem"
        position="absolute"
        m="0"
        p="0"
        addCss={css`
          &:hover {
            cursor: pointer;
          }
        `}
      >
        <FaEdit size={25} onClick={() => handleImageEdit(name, required)} />
      </Container>
      {images.find((image) => image?.name === name) && (
        <Image
          m={0}
          src={URL.createObjectURL(
            images.find((image) => image?.name === name)?.image!
          )}
          alt="preview"
          objectFit="contain"
          addCss={css`
            border-radius: '8px';
          `}
          //height: calc(${imageMinHeight} / 4) px;
          // width: calc(${imageMinWidth} / 4) px;
        />
      )}
    </Container>
  );
};
export default StoreImageEdit;
