import React, { useEffect } from 'react';
import LandingPage from '../../components/landing-page-layout/landing-page';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import Paragraph from '../../components/standard-components/typography/paragraph';
import { useAuth } from '../../hooks/useAuth';
import theme from '../../theme/theme';

const RequestVerification = () => {
  const { verifiedStatus, pendingUser } = useAuth();

  useEffect(() => {
    //TODO: to add re send verification request to backend.
    console.log('Send RequestVerification');
  }, [verifiedStatus, pendingUser]);

  return (
    <LandingPage heroImage="2FA-hero.jpg">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          We sent you an email. (It might be in your spam)
        </Heading>
        <Paragraph variant="hero_body" textAlign="center" mb="0">
          Please follow the link in the email to complete
          <br />
          your registration.
          <br />
        </Paragraph>
        {/* <Paragraph variant="hero_body_sm" textAlign="center">
          Didn’t receive email? <a href="/landing"> Click here to resend</a>.
        </Paragraph> */}
      </Flex>
    </LandingPage>
  );
};

export default RequestVerification;
