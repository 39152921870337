/** @jsxImportSource @emotion/react */
import Paragraph from '../../standard-components/typography/paragraph';
import FormWrapper from '../../form-wrapper';
import Card from '../../standard-components/elements/card';
import patterns from '../../../constants/validation-regex';
import OnboardingInputField from '../../onboarding-input';

const ShopDetails = ({ register, errors }: any) => (
  <>
    <Card heading="Shop Details">
      <FormWrapper columns={1}>
        <OnboardingInputField
          label="Shop Description *"
          placeholder="Description"
          error={errors.shopDescription}
          {...register('shopDescription', { required: false })}
        />
      </FormWrapper>
      <Paragraph>This is the address we'll use for collections.</Paragraph>
      <FormWrapper columns={2}>
        <OnboardingInputField
          label="Street Address *"
          placeholder="99 Vincii Boulevard"
          error={errors.shopStreetNumber}
          {...register('shopStreetNumber', { required: false })}
        />
        <OnboardingInputField
          label="Suburb *"
          placeholder="Enter Surburb"
          error={errors.shopSuburb}
          {...register('shopSuburb', { required: false })}
        />
      </FormWrapper>
      <Paragraph>
        We'll give this information to our delivery partner.
      </Paragraph>
      <FormWrapper columns={2}>
        <OnboardingInputField
          label="City *"
          placeholder="Enter City"
          error={errors.shopCity}
          {...register('shopCity', { required: false })}
        />
        <OnboardingInputField
          label="ZIP Code *"
          placeholder="0000"
          error={errors.shopZipCode}
          {...register('shopZipCode', { required: false })}
        />
      </FormWrapper>
    </Card>
    <Card heading="Shop Contact">
      <Paragraph>
        We will give this contact information to our delivery partner.
      </Paragraph>
      <FormWrapper columns={2}>
        <OnboardingInputField
          label="Phone Number *"
          placeholder="Enter shop contact number"
          error={errors.shopContactNumber}
          {...register('shopContactNumber', { required: false })}
        />
        <OnboardingInputField
          label="Email *"
          placeholder="name@example.com"
          error={errors.shopEmail}
          {...register('shopEmail', { required: false })}
        />
      </FormWrapper>
    </Card>
  </>
);

export default ShopDetails;
