import { appApi } from '../app-api';
import { IRegister, ISignIn, IVerification } from './auth-types';

export const authApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    signinUser: builder.mutation({
      query: (body: ISignIn) => ({
        url: '/auth/login',
        method: 'post',
        body,
      }),
    }),
    signupUser: builder.mutation({
      query: (body: IRegister) => ({
        url: '/auth/register?portal=yes',
        method: 'post',
        body,
      }),
    }),
    signout: builder.mutation({
      queryFn: (args?: any) => ({ data: 'success' }),
    }),
    sendVerification: builder.mutation({
      query: (body: IVerification) => ({
        url: '/send-verification',
        method: 'post',
        body,
      }),
    }),
    verifyUser: builder.mutation({
      query: (params: IVerification) => ({
        url: `auth/confirm/${params.confirmationCode}`,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useSigninUserMutation,
  useSignupUserMutation,
  useSendVerificationMutation,
  useVerifyUserMutation,
  useSignoutMutation,
} = authApi;
