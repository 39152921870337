import { css } from '@emotion/react';
import { ChangeEventHandler, ReactNode, useRef, useState } from 'react';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import { Flex } from '../../layout/flex-box';
import Heading from '../../typography/heading';
import ToggleSwitch from '../toggle-switch';

const Accordion = ({
  name,
  heading,
  children,
  headerStyles,
  show = false,
  setIsShow,
  onClick,
  ...styles
}: {
  onClick?: () => void;
  name: string;
  show: boolean;
  heading: string;
  headerStyles?: StandardProps;
  children: ReactNode | ReactNode[];
  setIsShow: ChangeEventHandler<HTMLInputElement>;
} & StandardProps) => {
  const accordionItems = useRef<any>();
  return (
    <Container {...styles}>
      <div
        onClick={() => onClick && onClick()}
        style={{ cursor: `${onClick ? 'pointer' : 'initial'}` }}
      >
        <Flex {...headerStyles}>
          <Heading variant="card_heading" level="sub" m={0} p={0} flex="1">
            {heading}
          </Heading>
          <ToggleSwitch name={name} isOn={show} handleToggle={setIsShow} />
        </Flex>
      </div>
      <Container
        m="0"
        mb={theme.margins.standard}
        addCss={css`
          transition: height ease-in 0.3s;
        `}
        p="0"
        pb={theme.padding.standard}
        height={!show ? '0' : 'auto'}
        overflowY={!show ? 'hidden' : 'initial'}
        boxShadow="medium"
      >
        <div style={{ background: theme.colors.white }} ref={accordionItems}>
          {children}
        </div>
      </Container>
    </Container>
  );
};

export default Accordion;
