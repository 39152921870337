import React, { ReactNode, ReactNodeArray } from 'react';
import theme from '../../theme/theme';
import Card from '../standard-components/elements/card';
import Heading from '../standard-components/typography/heading';

const UploadProductCard = ({
  heading,
  children,
}: {
  heading: string;
  children: ReactNode | ReactNodeArray;
}) => (
  <Card p="0" pb={theme.padding.standard} boxShadow="small">
    <Heading
      variant="card_heading"
      backgroundColor={theme.colors.headerColor}
      m="0"
      borderRadius={`${theme.borderRadius.normal} ${theme.borderRadius.normal} 0 0`}
      p={theme.padding.m}
    >
      {heading}
    </Heading>
    {children}
  </Card>
);

export default UploadProductCard;
