/* @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import theme from '../../../../theme/theme';
import { Flex } from '../../layout/flex-box';
import Paragraph from '../../typography/paragraph';
import Button from '../button';
import { IToastItem, Toast } from './types';
import {
  IoCloseSharp,
  IoWarningSharp,
  IoCheckmarkDoneSharp,
  IoAlertCircleSharp,
} from 'react-icons/io5';
import { VscError } from 'react-icons/vsc';
const notification = {
  error: {
    background: '#ff000050',
    color: '#ff0000',
  },
  warning: {
    background: '#f4c40545',
    color: '#a18000',
  },
  info: {
    background: '#3498db50',
    color: '#3498db',
  },
  success: {
    background: '#07bc0c50',
    color: '#07bc0c',
  },
};

const ToastItem = ({
  type,
  message,
  autoClose,
  closeTime,
  toastIndex,
  positionY = 'top',
  positionX = 'center',
  callback,
  deleteNotification,
}: IToastItem & {
  toastIndex: number;
  deleteNotification: (index: number) => void;
}) => {
  const handleOnNotificationClick = () => {
    deleteNotification(toastIndex);
  };

  useEffect(() => {
    let closeInterval: NodeJS.Timer;
    if (autoClose && closeTime) {
      closeInterval = setInterval(() => {
        deleteNotification(toastIndex);
        clearInterval(closeInterval);
      }, closeTime);
    }
    return () => clearInterval(closeInterval);
  }, [autoClose, callback, closeTime, deleteNotification, toastIndex]);
  return (
    <Flex
      zIndex={50000}
      minWidth="10rem"
      maxWidth="max-content"
      border={`1px solid ${notification[type].color}`}
      backgroundColor={theme.colors.white}
      borderRadius="3px"
      p="0"
      position="fixed"
      top={positionY === 'top' ? '2rem' : 'calc(100vh - 2rem)'}
      left={
        positionX === 'left'
          ? '2rem'
          : positionX === 'center'
          ? '50vw'
          : undefined
      }
      right={positionX === 'right' ? '2rem' : undefined}
      addCss={css`
        box-shadow: ${notification[type].background} 0px 0px 4px 2px,
          ${notification[type].background} 0px 0px 3px 1px inset;
        transform: ${positionX === 'center' && 'translateX(-50%)'}${positionY === 'bottom' && 'translateY(-150%)'};
      `}
    >
      <Flex
        borderRadius="3px"
        width="100%"
        m="0"
        p="0"
        backgroundColor={notification[type].background}
      >
        <Flex
          m={0}
          borderRadius={`2px 0 0 2px`}
          backgroundColor={notification[type].background}
          alignItems="center"
          justifyContent="center"
        >
          {(() => {
            switch (type) {
              case 'error':
                return (
                  <VscError
                    color={notification[type].color}
                    size="25px"
                    opacity="100%"
                    fontWeight={700}
                  />
                );
              case 'info':
                return (
                  <IoAlertCircleSharp
                    color={notification[type].color}
                    size="25px"
                    opacity="100%"
                    fontWeight={700}
                  />
                );
              case 'success':
                return (
                  <IoCheckmarkDoneSharp
                    color={notification[type].color}
                    size="25px"
                    opacity="100%"
                    fontWeight={700}
                  />
                );
              case 'warning':
                return (
                  <IoWarningSharp
                    color={notification[type].color}
                    size="25px"
                    opacity="100%"
                    fontWeight={700}
                  />
                );
            }
          })()}
        </Flex>
        <Paragraph
          px={theme.padding.standard}
          pr={`calc(20px + 2 * ${theme.padding.standard})`}
          py={theme.padding.standard}
          variant="card_body"
          textAlign="left"
          m="0"
          flex="1"
          color={notification[type].color}
          fontSize="16px !important"
          fontWeight="700 !important"
        >
          {message}
        </Paragraph>
        <Button
          variant="icon"
          m="0"
          position="absolute"
          right="0"
          top="-4px"
          onClick={handleOnNotificationClick}
        >
          <IoCloseSharp color={notification[type].color} size="22px" />
        </Button>
      </Flex>
    </Flex>
  );
};

const ToastNotification = ({
  toastList,
  removeNotificationCallback,
}: Toast & { removeNotificationCallback: (index: number) => void }) => {
  return (
    <>
      {toastList.map((notification, index) => (
        <ToastItem
          toastIndex={index}
          deleteNotification={removeNotificationCallback}
          key={`notification-${notification.type}-${index}`}
          {...notification}
        />
      ))}
    </>
  );
};

export default ToastNotification;
