/** @jsxImportSource @emotion/react */
import theme from '../../theme/theme';
import LeftNavigation from '../../components/standard-components/elements/left-navigation';
import ContentArea from '../../components/standard-components/elements/content-area';
import OnboardingPageLayout from '../../components/onboarding-page-layout';
import ShopOverview from '../../components/onboarding-journey/shop-overview';
import ShopDetails from '../../components/onboarding-journey/shop-details';
import Verification from '../../components/onboarding-journey/verification';
import FinancialDetails from '../../components/onboarding-journey/financial-details';
import QualityAssurance from '../../components/onboarding-journey/quality-assurance';
import { useContext, useState, useEffect } from 'react';
import { OnboardingContext } from '../../context/onboarding/OnboardingContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { onboardingActions } from '../../store/onboarding/onboarding-slice';
import useS3Upload, { IS3FileOptions } from '../../hooks/useS3Upload';
import useInput from '../../hooks/useInput';
import useUpdateEffect from '../../hooks/useUpdateEffect';
// import fs from 'fs';
// import path from 'path';
import {
  useCreateRetailerStoreMutation,
  useUpdateRetailerDetailsMutation,
} from '../../store/onboarding/onboarding-api';
import { useNavigate } from 'react-router-dom';
import { OnboardingDocuments } from '../../constants/uploadOptionsProvider';
type OnboardingDocKeys =
  | 'retailerVerification'
  | 'sizingGuide'
  | 'proofOfBankDetails';

/**
 * Onboard Retailer
 *  - Create Retailer  -> Submit required fields
 *  - Update Retailer Details -> Submit remaining fields
 *  - Upload images and documents to S3
 */

const OnboardingPage = () => {
  const imagesBucket = process.env.REACT_APP_AWS_S3_BUCKET ?? '';
  const {
    pageNumber,
    onboardingImages,
    onboardingDocuments,
    onboardingDispatch,
  } = useContext(OnboardingContext)!;

  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [checked, setChecked] = useState(false);
  const { upload } = useS3Upload();
  const { register, errors, values, hasBeenTouched, submit, reset } =
    useInput();

  const [useVinciiColor, setUseVinciiColor] = useState(false);
  const dispatch = useAppDispatch();
  const onboardingState = useAppSelector((state) => state.onboarding);
  const onboardingStatus = useAppSelector((state) => state.onboarding.status);
  const navigate = useNavigate();

  const [createRetailerStore, { isLoading: isCreateLoading }] =
    useCreateRetailerStoreMutation();
  const [updateRetailerDetails, { isLoading: isUpdateLoading }] =
    useUpdateRetailerDetailsMutation();

  const mapOptions = (key: OnboardingDocKeys): IS3FileOptions => {
    return {
      uploadType: 'pdf' as 'image' | 'pdf',
      filename: `${OnboardingDocuments[key].name}.pdf`,
      folderPrefix: `${OnboardingDocuments[key].prefix}/${onboardingState.retailer.retailerStore}`,
      bucket: OnboardingDocuments[key].bucket,
    } as IS3FileOptions;
  };

  //1. Account Details
  //2. Card details
  //3. Shop Overview
  //4. Shop Details
  //5. Verification
  //6. Financial details
  //7. Quality Assurance
  useUpdateEffect(() => {}, [
    errors,
    values,
    hasBeenTouched,
    onboardingDispatch,
  ]);

  useEffect(() => {
    if (pageNumber === 1) {
      setIsFirstPage(true);
    } else {
      setIsFirstPage(false);
    }
    if (pageNumber === 5) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
  }, [pageNumber]);

  //When Uploading, each stage has a different Onboarding Status
  useEffect(() => {
    try {
      if (onboardingStatus === 'store_created') {
        (async () => {
          await updateRetailerDetails(onboardingState.retailer).unwrap();
        })();
      }

      if (onboardingStatus === 'store_created') {
        //Upload Images to s3
        (async () => {
          await upload(onboardingImages['logo'], {
            uploadType: 'image',
            filename: `${onboardingState.retailer.retailerStore}/logo.jpg`,
            folderPrefix: 'retailer-stores',
            bucket: imagesBucket,
          });
          await upload(onboardingImages['banner'], {
            uploadType: 'image',
            filename: `${onboardingState.retailer.retailerStore}/banner.jpg`,
            folderPrefix: 'retailer-stores',
            bucket: imagesBucket,
          });
          //Upload Documents to s3
          Object.entries(onboardingDocuments).forEach(
            async (document, index) => {
              const uploadOptions = mapOptions(
                document[0] as OnboardingDocKeys
              );
              await upload(document[1], uploadOptions);
            }
          );
        })();
        navigate(
          `/onboarding-success/${onboardingState.retailer.retailerStore}`
        );
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  const submitHandler = async () => {
    //Add the data captured on the page to the Redux Store

    dispatch(
      onboardingActions.addFormData({
        ...values,
        useBrandColour: useVinciiColor,
      })
    );

    if (!isLastPage) {
      //Navigate to next page using context api dispatch actions
      onboardingDispatch({ type: 'next' });
    } else {
      try {
        //If Is last Page Submit onboarding information to backend
        if (checked) {
          /**
           * Upload Onboarding Information to Backend
           * Start with creating a store API Call Initiated from Onboarding REDUX API Query
           */
          if (onboardingStatus === 'not_started') {
            await createRetailerStore(onboardingState.formData).unwrap();
          }
        }
        // await use
      } catch (err) {
        //TODO: Error Handling
        console.log(err);
        alert('Error');
      }
    }
  };
  const previousPageHandler = () => {
    reset();
    onboardingDispatch({ type: 'back' });
  };

  const content = () => {
    if (!pageNumber) {
      return null;
    }
    // switch (pageNumber) {
    //   case 1:
    //     return <AccountDetails errors={errors} register={register} />;
    // }
    switch (pageNumber) {
      case 1:
        return (
          <ShopOverview
            errors={errors}
            register={register}
            useVinciiColor={useVinciiColor}
            setUseVinciiColor={() =>
              setUseVinciiColor((currentState) => !currentState)
            }
          />
        );
    }
    switch (pageNumber) {
      case 2:
        return <ShopDetails errors={errors} register={register} />;
    }
    switch (pageNumber) {
      case 3:
        return <Verification />;
    }
    switch (pageNumber) {
      case 4:
        return <FinancialDetails errors={errors} register={register} />;
    }
    // switch (pageNumber) {
    //   case 6:
    //     return <CardDetails errors={errors} register={register} />;
    // }
    switch (pageNumber) {
      case 5:
        return <QualityAssurance checked={checked} setChecked={setChecked} />;
    }
  };
  return (
    <OnboardingPageLayout
      backgroundColor={theme.colors.headerColor}
      onClick={
        submitHandler
        // submit(submitHandler, useVinciiColor ? ['storeBackground'] : [])
      }
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      isLoading={isCreateLoading || isUpdateLoading}
    >
      <LeftNavigation
        heading="Onboarding"
        onBackClick={previousPageHandler}
      ></LeftNavigation>
      <ContentArea overflowY="scroll">{content()}</ContentArea>
    </OnboardingPageLayout>
  );
};

export default OnboardingPage;
