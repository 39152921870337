import { useMemo, useState } from 'react';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
export interface IS3FileOptions {
  filename: string;
  folderPrefix?: string;
  fileType?: 'image/jpeg' | 'application/pdf';
  contentEncoding?: 'base64' | 'gzip';
  uploadType: 'image' | 'pdf';
  bucket: string;
}
const useS3Upload = () => {
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const identityPool = process.env.REACT_APP_AWS_IDPOOL_ID!;
  const regionS3 = process.env.REACT_APP_AWS_S3_REGION;
  const identityPoolRegion = process.env.REACT_APP_AWS_IDPOOL_REGION;

  const client = useMemo(() => {
    return new S3Client({
      region: regionS3,
      credentials: fromCognitoIdentityPool({
        identityPoolId: identityPool,
        clientConfig: { region: identityPoolRegion },
      }),
    });
  }, [identityPool, identityPoolRegion, regionS3]);

  const uploadToS3 = async (
    uploadFile: any,
    fileOptions: IS3FileOptions,
    expires?: number | string
  ) => {
    // IF FILE IS AN IMAGE, WE NEED TO CONVERT IT TO BASE64

    if (fileOptions.uploadType === 'image') {
      fileOptions.contentEncoding = 'base64';
      fileOptions.fileType = 'image/jpeg';
      const base64String = uploadFile.replace(/^data:image\/\w+;base64,/, '');
      uploadFile = Buffer.from(base64String, 'base64');
    }

    setIsUploading(true);
    setStatus('pending');
    const folderStructure = fileOptions.folderPrefix
      ? `${fileOptions.folderPrefix}/${fileOptions.filename}`
      : `${fileOptions.filename}`;
    console.log('S3 Location', folderStructure);
    const command = new PutObjectCommand({
      Key: folderStructure,
      Bucket: fileOptions.bucket,
      ContentType: fileOptions.fileType,
      ContentEncoding: fileOptions.contentEncoding,
      CacheControl: `max-age=${expires ? expires : 604800}`,
      Body: uploadFile,
    });

    try {
      const respone = await client.send(command);
      console.log(respone);
      setStatus('upload complete');
      setIsUploading(false);
    } catch (err) {
      setIsError(true);
      setIsUploading(false);
      console.log(err);
    }
  };

  return {
    upload: (
      uploadFile: any,
      fileOptions: IS3FileOptions,
      expires?: number | string
    ) => uploadToS3(uploadFile, fileOptions, expires),
    status,
    isError,
    isUploading,
  };
};

export default useS3Upload;
