import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from '../../utils/omit-keys';
import { onboardingApi } from './onboarding-api';
import {
  IOnboardingState,
  IRetailerStoreResponse,
  IRetailerUpdateResponse,
} from './onboarding-types';

const initialState: IOnboardingState = {
  formData: {},
  retailer: {},
  status: 'not_started',
};
const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    addFormData(state: IOnboardingState, { payload }: any) {
      state.formData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        onboardingApi.endpoints.createRetailerStore.matchFulfilled,
        (state, { payload }: PayloadAction<IRetailerStoreResponse>) => {
          const formData = omit(state.formData, Object.keys(payload));
          const {
            vatNumber,
            shopEmail,
            shopContactNumber,
            customerRequestEmail,
            customerRequestNumber,
          } = formData!;
          state.retailer = {
            retailerStore: payload._id,
            // cardDetails: {
            //   cardNumber: cardNumber!,
            //   expiration: expiration!,
            //   zipCode: zipCode!,
            //   address: address!,
            //   country: country!,
            // },
            financialDetails: {
              bankName: formData.bankName,
              accountNumber: formData.accountNumber,
              branchCode: formData.branchCode,
              accountHolder: formData.accountHolder,
            },
            address: {
              streetNumber: formData.shopStreetNumber,
              // streetName: formData.shopStreetName,
              housingType: 'house',
              suburb: formData.shopSuburb,
              city: formData.shopCity,
              zipCode: formData.shopZipCode,
            },
            monthlyInvoices: {
              settlementsFile: 'Example Invoice',
            },
            user: payload._id,
            package: {
              basic: false,
              booster: true,
            },
            shopContactNumber,
            shopEmail,
            customerRequestEmail,
            customerRequestNumber,
            vatNumber,
          };
          state.status = 'store_created';
        }
      )
      .addMatcher(
        onboardingApi.endpoints.updateRetailerDetails.matchFulfilled,
        (state, { payload }: PayloadAction<IRetailerUpdateResponse>) => {
          state.retailer = payload.msg;
          state.status = 'store_details_updated';
        }
      );
  },
});

export const onboardingActions = onboardingSlice.actions;
export default onboardingSlice.reducer;
