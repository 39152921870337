/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { forwardRef } from 'react';
import StandardCSS from '../../../../theme/standard-css';
import DefaultCss from '../../../../theme/standard-default-css';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';

const InputField = forwardRef<
  HTMLInputElement,
  {
    name?: string;
    value?: string;
    label?: string;
    children?: any;
    htmlId?: string;
    placeholder?: string;
    defaultValue?: string;
    noDefaultStyle?: boolean;
    addCss?: SerializedStyles;
    placeholderColour?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  } & StandardProps
>(
  (
    {
      name,
      value,
      label,
      addCss,
      htmlId,
      children,
      placeholder,
      defaultValue,
      noDefaultStyle,
      placeholderColour,
      onBlur,
      onFocus,
      onChange,
      ...styles
    },
    ref
  ) => {
    return (
      <div
        className="form-group"
        css={css`
          display: flex;
          position: relative;
          margin-bottom: 12px;
        `}
      >
        {label && (
          <label
            className="card-input-label"
            css={css`
              margin: 0;
              font-size: ${theme.fontSizes.normal};
              color: ${theme.colors.supportingText};
              font-weight: ${theme.fontWeights.semiBold};
              &:focus {
              }
            `}
            htmlFor={label}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          id={htmlId}
          name={name}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          className={'form-control'}
          defaultValue={defaultValue}
          css={css`
            margin: 0;
            border: none;
            outline: none;
            cursor: pointer;

            border-bottom: 1px solid ${theme.colors.lineGrey};
            font-size: ${theme.fontSizes.medium};
            &:focus {
              outline: none;
              box-shadow: none;
              border-width: 2px;
              border: none;
              border-bottom: 1px solid ${theme.colors.primaryCta};
            }
            &::placeholder {
              color: ${placeholderColour
                ? placeholderColour
                : theme.colors.lightGrey};
            }
            ${!noDefaultStyle && DefaultCss()}
            ${StandardCSS(styles)}
            ${addCss}
             border-radius: 0px;
          `}
        />
        {children}
      </div>
    );
  }
);

export default InputField;
