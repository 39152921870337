import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import patterns from '../../../constants/validation-regex';
import { useAuth } from '../../../hooks/useAuth';
import useHttp from '../../../hooks/useHttp';
import useInput from '../../../hooks/useInput';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import useIsWindowFocused from '../../../hooks/useWindowFocus';
import { useAppDispatch } from '../../../store/hooks';
import { notificationActions } from '../../../store/notifications/notification-slice';
import { useUpdateRetailerAccountInformationMutation as useUpdateRetailerAccount } from '../../../store/retailer-store/retailer-store-api';
import {
  IRetailerInformation,
  IUpdateAccountDetails,
} from '../../../store/retailer-store/types';
import theme from '../../../theme/theme';
import deepCompare from '../../../utils/deepCompare';
import FormWrapper from '../../form-wrapper';
import ProductInputField from '../../product-input';
import Container from '../../standard-components/layout/container';
import { Flex } from '../../standard-components/layout/flex-box';
import UploadProductCard from '../../upload-product-card';
import RetailerStoreFooter from './retailer-footer';

const mapFields = (data?: IRetailerInformation) => {
  return {
    shopStreetNumber: data?.address.streetNumber ?? '',
    shopSuburb: data?.address.suburb ?? '',
    shopCity: data?.address.city ?? '',
    shopZipCode: data?.address.zipCode ?? '',
    shopContactNumber: data?.shopContactNumber ?? '',
    shopEmail: data?.shopEmail ?? '',
    bankName: data?.financialDetails.bankName ?? '',
    accountNumber: data?.financialDetails.accountNumber ?? '',
    accountHolder: data?.financialDetails.accountHolder ?? '',
    branchCode: data?.financialDetails.branchCode ?? '',
    vatNumber: data?.vatNumber ?? '',
  };
};

const AccountDetails = ({
  refetch,
  storeID,
  defaultData,
  retailerDetailsID,
}: {
  storeID: string;
  refetch: () => void;
  retailerDetailsID: string | null;
  defaultData: IRetailerInformation | undefined;
}) => {
  const navigate = useNavigate();
  const windowIsFocused = useIsWindowFocused();
  const reduxDispatch = useAppDispatch();
  const [updateRetailer, { data, isLoading }] = useUpdateRetailerAccount();
  const { timeToTokenExpiry } = useAuth();
  const [accoutDetailsIsModified, setAccoutDetailsIsModified] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const initialAccountData = useRef<Partial<IRetailerInformation> | null>(
    mapFields(defaultData)
  );
  const { register, errors, values, submit, resetDefaults, updateDefaults } =
    useInput(mapFields(defaultData));
  const handleDiscard = () => {
    if (window.confirm('All unsaved data will be lost. Continue?')) {
      //TODO: on discard
      resetDefaults();
      setAccoutDetailsIsModified(false);
    }
  };

  useEffect(() => {
    const expiryTime = timeToTokenExpiry ? parseInt(timeToTokenExpiry) : 0;
    if (Date.now() > expiryTime) {
      //Token has expired
      if (
        window.confirm(
          'Current session has expired, Please Login again to continue.'
        )
      ) {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    }
  }, [navigate, timeToTokenExpiry, windowIsFocused]);

  useUpdateEffect(() => {
    if (!deepCompare(values, initialAccountData.current)) {
      setAccoutDetailsIsModified(true);
    } else {
      setAccoutDetailsIsModified(false);
    }
  }, [values]);

  const handleUpdateAcccountDetails = async () => {
    setIsFetching(true);
    try {
      let response = null;
      if (defaultData && retailerDetailsID) {
        response = await updateRetailer({
          retailerDetailsID,
          storeID,
          data: values as unknown as IUpdateAccountDetails,
        }).unwrap();
      } else {
        throw Error('Access Denied.Please log in again to perform this action');
      }
      if (!response) {
        if (
          window.confirm(
            'Access Denied.Please log in again to perform this action'
          )
        ) {
          navigate('/login');
        } else {
          throw Error(
            'Access Denied.Please log in again to perform this action'
          );
        }
        resetDefaults();
      } else {
        reduxDispatch(
          notificationActions.pushNotification({
            message: 'Product Updated successfully',
            type: 'success',
            autoClose: true,
            closeTime: 2000,
            positionY: 'top',
            positionX: 'center',
          })
        );
        refetch();
        setIsFetching(false);
        updateDefaults();
        setAccoutDetailsIsModified(false);
      }
    } catch (error) {
      reduxDispatch(
        notificationActions.pushNotification({
          message: 'Product Updated Failed, Try Again or Contact Support',
          type: 'error',
          autoClose: true,
          closeTime: 3000,
          positionY: 'top',
          positionX: 'center',
        })
      );
      setIsFetching(false);
    }
  };
  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor={theme.colors.BGAdmin}
      overflowY="hidden"
      noDefaultStyle
    >
      <Container
        overflowY="scroll"
        p={theme.padding.standard}
        noDefaultStyle
        flex="1"
      >
        <UploadProductCard heading="Shop Details">
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Street Address *"
              placeholder="Enter Street Address"
              error={errors.shopStreetNumber}
              defaultValue={defaultData?.address.streetNumber ?? ''}
              value={values.shopStreetNumber}
              {...register('shopStreetNumber', { required: false })}
            />
            <ProductInputField
              label="Suburb *"
              placeholder="Enter Surburb"
              error={errors.shopSuburb}
              value={values.shopSuburb}
              defaultValue={defaultData?.address.suburb ?? ''}
              {...register('shopSuburb', { required: false })}
            />
            <ProductInputField
              label="City *"
              placeholder="Enter City"
              error={errors.shopCity}
              value={values.shopCity}
              defaultValue={defaultData?.address.city ?? ''}
              {...register('shopCity', { required: false })}
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="ZIP Code *"
              placeholder="0000"
              error={errors.shopZipCode}
              value={values.shopZipCode}
              defaultValue={defaultData?.address.zipCode ?? ''}
              {...register('shopZipCode', { required: false })}
            />
          </FormWrapper>
        </UploadProductCard>

        <UploadProductCard heading="Account Details">
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Bank Name *"
              placeholder="Enter Bank Name"
              error={errors.bankName}
              value={values.bankName}
              {...register('bankName', { required: false })}
              defaultValue={defaultData?.financialDetails.bankName ?? ''}
            />
            <ProductInputField
              label="Account Number *"
              placeholder="Enter Account Number"
              error={errors.accountNumber}
              value={values.accountNumber}
              {...register('accountNumber', { required: false })}
              defaultValue={defaultData?.financialDetails.accountNumber ?? ''}
            />
            <ProductInputField
              label="Branch Code *"
              placeholder="000000"
              error={errors.branchCode}
              value={values.branchCode}
              {...register('branchCode', { required: false })}
              defaultValue={defaultData?.financialDetails.branchCode ?? ''}
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Account Holder *"
              placeholder="Enter Account Holder Name"
              error={errors.accountHolder}
              value={values.accountHolder}
              {...register('accountHolder', { required: false })}
              defaultValue={defaultData?.financialDetails.accountHolder ?? ''}
            />
            <ProductInputField
              label="VAT Number"
              placeholder=""
              error={errors.vatNumber}
              value={values.vatNumber}
              {...register('vatNumber')}
              defaultValue={defaultData?.vatNumber ?? ''}
            />
          </FormWrapper>
        </UploadProductCard>
      </Container>
      <RetailerStoreFooter
        error={['']}
        isLoading={isLoading || isFetching}
        isModified={accoutDetailsIsModified}
        onClickBack={handleDiscard}
        onClickDiscard={handleDiscard}
        onClickUpload={() => submit(handleUpdateAcccountDetails)}
      />
    </Flex>
  );
};

export default AccountDetails;
