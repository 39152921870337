/* @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import theme from '../../../theme/theme';
import { capitalizeFirstLetter } from '../../../utils/string-manipulation';
import FormWrapper from '../../form-wrapper';
import Accordion from '../../standard-components/elements/accordion';
import Button from '../../standard-components/elements/button';
import { Flex } from '../../standard-components/layout/flex-box';
import Paragraph from '../../standard-components/typography/paragraph';
import Heading from '../../standard-components/typography/heading';
import Variant from '../new-product-variants/Variant';
import { IVariantCombine, IVariantTags } from '../types';
import VariantSelectTable from '../variant-select-table';

const MtoVariants = ({
  parent,
  isEditabe,
  showVariants,
  setShowVariants,
  defaultVariantData,
  accordionCallback,
  handleUpdateVariants,
}: {
  handleUpdateVariants: ({
    name,
    variants,
  }: {
    name: 'mto' | 'soh';
    variants: IVariantCombine[];
  }) => void;
  isEditabe?: boolean;
  parent: 'edit' | 'upload';
  showVariants?: boolean;
  setShowVariants: (show: boolean) => void;
  defaultVariantData?: IVariantCombine[];
  accordionCallback?: () => void;
}) => {
  const [variantTagList, setVariantTagList] = useState<IVariantTags>({
    fabric: [],
    colour: [],
    size: [],
  });

  const [defaultLeadTime, setDefaultLeadTime] = useState<number>(0);
  const [editVariants, setEditVariants] = useState<boolean>(false);
  useEffect(() => {
    if (defaultVariantData && defaultVariantData.length > 0) {
      const defaultTagList = defaultVariantData?.reduce(
        (prev, current) => {
          console.log('CURRENT');
          console.log(current);
          return {
            fabric: [...prev.fabric, current.fabric].filter(
              (value, index, self) => self.indexOf(value) === index
            ),
            colour: [
              ...prev.colour,
              typeof current.colour === 'object'
                ? current.colour[0]
                : current.colour,
            ].filter((value, index, self) => self.indexOf(value) === index),
          } as IVariantTags;
        },

        {
          fabric: [],
          colour: [],
          size: [],
        } as IVariantTags
      );
      setVariantTagList(defaultTagList);
    }
  }, [defaultVariantData]);

  const handleShowAccordion = () => {
    setShowVariants(!showVariants);
  };

  const handleChangeDefaultMeasure: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value ? event.target.value : '0';
    setDefaultLeadTime(parseInt(value));
  };
  const updateVariantTagList = ({
    name,
    tags,
  }: {
    name: keyof IVariantTags;
    tags: string[];
  }) => {
    const uniqueTags = [...variantTagList[name], ...tags]
      .map((value) => {
        return typeof value === 'object'
          ? capitalizeFirstLetter(value['UK'])
          : capitalizeFirstLetter(value);
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    setVariantTagList((tagList) => ({
      ...tagList,
      [name]: uniqueTags,
    }));
  };

  const removeVariantTagFromList = ({
    name,
    index,
  }: {
    name: keyof IVariantTags;
    index: number;
  }) => {
    setVariantTagList((tagList) => ({
      ...tagList,
      [name]:
        name === 'size'
          ? [...tagList.size.filter((el, i) => i !== index)]
          : [...tagList[name].filter((el, i) => i !== index)],
    }));
  };

  return (
    <Accordion
      show={showVariants ?? false}
      setIsShow={() => handleShowAccordion()}
      heading={'Make to Order Variants'}
      name="mto-vairant"
      headerStyles={{
        backgroundColor: theme.colors.headerColor,
        m: '0',
        borderRadius: `${theme.borderRadius.normal} ${theme.borderRadius.normal} 0 0`,
        p: theme.padding.m,
      }}
    >
      <Paragraph>
        When typing variants for fabric, colour and size: be sure to{' '}
        <strong>press enter after each variant.</strong>
      </Paragraph>
      <FormWrapper
        backgroundColor={theme.colors.white}
        px={theme.padding.m}
        columns={2}
      >
        {Object.entries(variantTagList).map((variant, index) => (
          <Variant
            tags={variant[1] as string[]}
            name={variant[0]}
            key={`variant-mto-${variant[0]}-${index}`}
            addVariantTagToList={updateVariantTagList}
            removeVariantTagFromList={removeVariantTagFromList}
          />
        ))}
      </FormWrapper>
      <FormWrapper
        backgroundColor={theme.colors.white}
        px={theme.padding.m}
        columns={parent === 'upload' || editVariants ? 2 : 1}
      >
        {parent === 'edit' ? (
          <>
            {!editVariants ? (
              <Button
                justifySelf="flex-end"
                variant="flat"
                backgroundColor={theme.colors.background}
                onClick={() => setEditVariants(true)}
              >
                Edit Table
              </Button>
            ) : (
              <>
                <Heading variant="card_sub_heading" level="subsub">
                  Modify the variants to be created :
                </Heading>
                <Flex noDefaultStyle justifyContent="center">
                  <Heading variant="card_sub_heading" level="subsub">
                    Enter default lead time (days) :
                  </Heading>
                  <input
                    defaultValue={defaultLeadTime}
                    type="number"
                    onChange={handleChangeDefaultMeasure}
                    css={css`
                      background-color: transparent;
                      width: 10rem;
                      text-align: center;
                      font-size: 14px;
                      line-height: 16px;
                      font-weight: 300;
                    `}
                  />
                </Flex>
              </>
            )}
          </>
        ) : (
          <>
            <Heading variant="card_sub_heading" level="subsub">
              Modify the variants to be created :
            </Heading>
            <Flex noDefaultStyle justifyContent="center">
              <Heading variant="card_sub_heading" level="subsub">
                Enter default lead time (days) :
              </Heading>
              <input
                onChange={(event) =>
                  setDefaultLeadTime(parseInt(event.target.value))
                }
                defaultValue={defaultLeadTime}
                css={css`
                  background-color: transparent;
                  width: 10rem;
                  text-align: center;
                  font-size: 14px;
                  line-height: 16px;
                  font-weight: 300;
                `}
              />
            </Flex>
          </>
        )}
      </FormWrapper>
      <FormWrapper
        backgroundColor={theme.colors.white}
        px={theme.padding.l}
        columns={1}
      >
        <VariantSelectTable
          readOnly={parent === 'upload' ? false : !editVariants}
          type="mto"
          defaultMeasure={defaultLeadTime}
          callback={(data) =>
            handleUpdateVariants({
              name: 'mto',
              variants: data as IVariantCombine[],
            })
          }
          defaultVariantData={defaultVariantData}
          tags={variantTagList}
          columns={['Fabric', 'Colour', 'Lead Time']}
        />
      </FormWrapper>
    </Accordion>
  );
};

export default MtoVariants;
