import { css } from '@emotion/react';
import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
} from 'react';
import { MyShopContext } from '../../context/my-shop/MyShopContext';
import { useAppSelector } from '../../store/hooks';
import theme from '../../theme/theme';
import AddProductCard from '../add-product-card';
import ProductPreviewCard from '../product-preview-card';
import ProductSearch from '../product-search';
import Card from '../standard-components/elements/card';
import { Flex } from '../standard-components/layout/flex-box';
import Grid from '../standard-components/layout/grid';
import Heading from '../standard-components/typography/heading';

const ListProducts = ({
  storeID,
  isLoading,
  searchInput,
  handleSearchInput,
  handleOnSearchSubmit,
}: {
  storeID: string;
  isLoading: boolean;
  searchInput: string;
  handleSearchInput: ChangeEventHandler<HTMLInputElement>;
  handleOnSearchSubmit: MouseEventHandler<HTMLFormElement>;
}) => {
  const { products } = useAppSelector((state) => state.products);
  const { dispatch } = useContext(MyShopContext)!;
  return (
    <Flex
      flexDirection="column"
      height="100%"
      m="0"
      width="100%"
      p={theme.padding.standard}
    >
      {isLoading ? (
        <Card heading="My Shop" height="100%">
          <ProductSearch
            handleOnSearchSubmit={handleOnSearchSubmit}
            storeID={storeID!}
            searchInput={searchInput}
            setSearchInput={handleSearchInput}
          />
          <Heading variant="nav_heading">Loading Products....</Heading>
        </Card>
      ) : (
        <Flex
          backgroundColor="white"
          borderRadius={theme.borderRadius.normal}
          boxShadow="medium"
          flexDirection="column"
          width="100%"
          height="100%"
          m="0"
          p={theme.padding.standard}
        >
          <Heading variant="card_heading" level="sub" m={0} p={0}>
            Manage Products
          </Heading>
          <ProductSearch
            handleOnSearchSubmit={handleOnSearchSubmit}
            storeID={storeID!}
            searchInput={searchInput}
            setSearchInput={handleSearchInput}
          />
          <Grid
            flex="1"
            overflowY="scroll"
            px={theme.padding.standard}
            height="inherit"
            gridColumnGap={theme.margins.m}
            gridRowGap={theme.margins.l}
            gridTemplateColumns="repeat(auto-fill, minmax(14.2rem, 1fr))"
            gridTemplateRows="min-content"
            mx="0"
          >
            <AddProductCard callback={() => dispatch({ type: 'ADD' })} />
            {products &&
              products.map((product, indx) => (
                <ProductPreviewCard product={product} key={`product-${indx}`} />
              ))}
          </Grid>
        </Flex>
      )}
    </Flex>
  );
};

export default ListProducts;
