import { useContext, useEffect, useState } from 'react';
import { MyShopContext } from '../../context/my-shop/MyShopContext';
import useHttp from '../../hooks/useHttp';
import useInput from '../../hooks/useInput';
import useS3Upload from '../../hooks/useS3Upload';
import { useAppDispatch } from '../../store/hooks';
import { notificationActions } from '../../store/notifications/notification-slice';
import { CommodityGroups } from '../../store/products/product-types';
import theme from '../../theme/theme';
import DropdownMenu from '../dropdown';
import { SingleImageDropzone } from '../dropzone';
import FormWrapper from '../form-wrapper';
import ProductInputField from '../product-input';
import BreadCrumb from '../standard-components/elements/breadcrumb';
import Button from '../standard-components/elements/button';
import ContentArea from '../standard-components/elements/content-area';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import UploadProductCard from '../upload-product-card';
import {
  EApparelType,
  EPurpose,
  ETargetGroup,
  IMTOVariant,
  IProduct,
  IVariant,
} from './upload-product.types';
import { useUploadProductsMutation } from '../../store/products/product-api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useIsWindowFocused from '../../hooks/useWindowFocus';
import MultipleSelectionDropdownMenu from '../multiple-selection-dropdown';
import StoVariants from '../product-variants/StoVariants';
import { IVariantCombine } from '../product-variants/types';
import MtoVariants from '../product-variants/MtoVariants';

const UploadProduct = ({ storeID }: { storeID: string }) => {
  const reduxDispatch = useAppDispatch();
  const { dispatch: changeProductPageDispatch } = useContext(MyShopContext)!;
  const { register, errors, values, submit } = useInput();
  const { storeName, retailer, timeToTokenExpiry } = useAuth();
  const [commodityGroups, setCommodityGroups] = useState<any>({
    targetGroup: null,
    purpose: null,
    apparelType: null,
  });
  const [variants, setVariants] = useState<{
    makeToOrderVariants: IVariantCombine[];
    variants: IVariantCombine[];
  }>({
    makeToOrderVariants: [] as unknown as IVariantCombine[],
    variants: [] as unknown as IVariantCombine[],
  });
  const [error, setError] = useState('');
  const [productImages, setProductImages] = useState<
    Array<{ name: string; image: Blob | null; required?: boolean }>
  >([]);
  const { isLoading, post } = useHttp(`${process.env.REACT_APP_API_HOST}`);
  const { upload, isUploading } = useS3Upload();
  const [isFetching, setIsFetching] = useState(false);
  const [uploadProducts] = useUploadProductsMutation();
  const navigate = useNavigate();
  const windowIsFocused = useIsWindowFocused();

  const [showVariants, setShowVariants] = useState(false);
  const [showMakeToOrder, setShowMakeToOrder] = useState(false);
  const bucket = process.env.REACT_APP_AWS_S3_BUCKET;

  useEffect(() => {
    if (!storeName || !retailer) {
      navigate('/login');
    }
  }, [navigate, retailer, storeName]);

  useEffect(() => {
    const expiryTime = timeToTokenExpiry ? parseInt(timeToTokenExpiry) : 0;
    if (Date.now() > expiryTime) {
      //Token has expired
      if (
        window.confirm(
          'Current session has expired, Please Login again to continue.'
        )
      ) {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    }
  }, [navigate, timeToTokenExpiry, windowIsFocused]);

  const handleUploadConfirm = (
    image: Blob,
    name: string,
    required?: boolean
  ) => {
    setProductImages((productImages) => [
      ...productImages,
      { name, image, required },
    ]);
  };

  const handleUpdateOptions = (item: string, key: string) => {
    setCommodityGroups((commodityGroups: Partial<CommodityGroups>) => ({
      ...commodityGroups,
      [key]: item,
    }));
  };
  const handleUpdateVariants = ({ name, variants }: any) => {
    const variantIndex = name === 'mto' ? 'makeToOrderVariants' : 'variants';

    setVariants((_variants) => ({ ..._variants, [variantIndex]: variants }));
  };

  const handleUploadProduct = async () => {
    // const optionErrors = Object.values(options).every(
    //   (field: string) => !field.includes('Select') && field.length > 0
    // );

    let requiredImages = ['catalogueImage', 'detailView'];
    const imagesLoaded = productImages
      .map((image) => {
        if (image.required) return image.name;
        return null;
      })
      .filter((name) => name !== null);

    const imageErrors = requiredImages.every((image) => {
      return imagesLoaded.includes(image);
    });

    const commodityGroupErrors = Object.values(commodityGroups).every(
      (commodity) => commodity !== null
    );

    const noVariantError = showVariants || showMakeToOrder;

    const variantsErrors =
      showVariants &&
      !(
        variants.variants.length > 0 &&
        Object.values(variants.variants[0]).every((variant) => {
          return typeof variant === 'number'
            ? variant > 0
            : typeof variant === 'object'
            ? true
            : variant.length > 0;
        })
      );
    console.log(variants.makeToOrderVariants);
    console.log(showMakeToOrder);
    // const mtoVariantsErrors =
    //   showMakeToOrder &&
    //   !(
    //     variants.makeToOrderVariants.length > 0 &&
    //     Object.values(variants.variants[0]).every((variant) => {
    //       return typeof variant === 'number'
    //         ? variant >= 0
    //         : typeof variant === 'object'
    //         ? true
    //         : variant.length > 0;
    //     })
    //   );

    if (!commodityGroupErrors) {
      setError('Required Field in Product Detail Is Missing');
    } else if (!imageErrors) {
      setError('Required Image Is Missing');
    } else if (variantsErrors) {
      setError('At least one set of Variants is required.');
      // } else if (mtoVariantsErrors) {
      //   setError('At least one set of Make To Order Variants is required.');
    } else if (!noVariantError) {
      setError('No variant option selected, at least one is required.');
    } else {
      setIsFetching(true);
      //Submit API

      setError('');
      const productUploadData: IProduct = {
        productName: values.productName,
        productDescription: values.productDescription,
        productPrice: values.productPrice,
        variants: showVariants
          ? (variants.variants as unknown as IVariant[])
          : [],
        commodityGroups,
        storeFK: storeID,
        storeName: storeName!,
        makeToOrderVariants: showMakeToOrder
          ? (variants.makeToOrderVariants.map((variant) => ({
              ...variant,
              colour: [variant.colour],
            })) as unknown as IMTOVariant[])
          : [],
      };

      try {
        const response = await post({
          url: '/products',
          data: productUploadData,
        });
        await uploadProducts(response).unwrap();
        await Promise.all(
          productImages.map(async ({ name, image }) => {
            await upload(image, {
              uploadType: 'image',
              filename: `${storeID}/${response.product['_id']}/${name}.jpg`,
              folderPrefix: 'retailer-products',
              bucket: bucket!,
            });
          })
        );

        reduxDispatch(
          notificationActions.pushNotification({
            message: 'Product created successfully',
            type: 'success',
            autoClose: true,
            closeTime: 2000,
            positionY: 'top',
            positionX: 'center',
            callback: 'ADD PRODUCT',
          })
        );
        setIsFetching(false);
        changeProductPageDispatch({ type: 'LIST' });
      } catch (error) {
        setIsFetching(false);
        console.log(error);
        reduxDispatch(
          notificationActions.pushNotification({
            message:
              'Product Update Failed, Try Again or Contact Your Vincii Representative.',
            type: 'error',
            autoClose: true,
            closeTime: 3000,
            positionY: 'top',
            positionX: 'center',
          })
        );
      }
    }
  };

  const handleMultipleSelection = (
    currentValues: string[],
    clickedItem: string,
    key: string
  ) => {
    const isCurrentValueSelected = currentValues.includes(clickedItem);
    if (isCurrentValueSelected) {
      setCommodityGroups((commodityGroups: any) => ({
        [key]: currentValues.filter((value) => value !== clickedItem),
      }));
      return;
    }
    setCommodityGroups({
      ...commodityGroups,
      [key]: [...currentValues, clickedItem],
    });
  };

  return (
    <Flex
      backgroundColor={theme.colors.veryLightGrey}
      flexDirection="column"
      borderRadius={0}
      minHeight="100vh"
      width="100%"
      p={0}
      m={0}
    >
      <ContentArea overflowY="scroll" flex="1">
        <BreadCrumb levels={['Products', 'Add Product']} />
        <UploadProductCard heading="Product Details">
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Name *"
              placeholder="Rose Gold Sequins Mini Dress"
              {...register('productName', { required: true })}
              error={errors.productName}
            />
            <ProductInputField
              label="Description *"
              placeholder="Proudly Designed and Made with Love in Africa."
              {...register('productDescription')}
              error={errors.productDescription}
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt="0"
          >
            <ProductInputField
              label="Price * (Remember to include a 10% buffer for commision)"
              placeholder="750"
              {...register('productPrice', { required: true })}
              error={errors.productPrice}
            />
            <MultipleSelectionDropdownMenu
              currentValues={
                commodityGroups.targetGroup ? commodityGroups.targetGroup : []
              }
              options={Object.values(ETargetGroup)}
              title="Select Target Group..."
              callback={(currentValues: string[], clickedItem: string) =>
                handleMultipleSelection(
                  currentValues,
                  clickedItem,
                  'targetGroup'
                )
              }
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt="0"
            mb={theme.margins.standard}
          >
            <MultipleSelectionDropdownMenu
              currentValues={
                commodityGroups.purpose ? commodityGroups.purpose : []
              }
              options={Object.values(EPurpose)}
              title="Select Occasion..."
              callback={(currentValues: string[], clickedItem: string) =>
                handleMultipleSelection(currentValues, clickedItem, 'purpose')
              }
            />
            <DropdownMenu
              noDefaultStyles
              title="Apparel Type"
              options={Object.values(EApparelType)}
              callback={(item) => handleUpdateOptions(item, 'apparelType')}
              currentItem={
                commodityGroups.apparelType ?? 'Select Apparel Type...'
              }
            />
          </FormWrapper>
        </UploadProductCard>
        <UploadProductCard heading="Image Guideline">
          <Paragraph>
            Please refer to the image guideline below if you have any questions.
          </Paragraph>
          <a
            href="https://public-information.s3.af-south-1.amazonaws.com/VINCII+Image+Guideline.pdf"
            target="_blank"
            download
            rel="noreferrer"
          >
            <Paragraph>Download VINCII Image Guideline</Paragraph>
          </a>
        </UploadProductCard>
        <UploadProductCard heading="Add Product Images">
          <FormWrapper my="0" py="0" columns={2}>
            <SingleImageDropzone
              description="Catalogue Image *"
              name="catalogueImage"
              imageMinHeight={432}
              imageMinWidth={300}
              imageMaxHeight={1152}
              imageMaxWidth={800}
              onUploadConfirm={handleUploadConfirm}
              minFileSize={10000}
              required
            />

            <SingleImageDropzone
              description="Image 2 *"
              name="detailView"
              imageMinHeight={432}
              imageMinWidth={300}
              imageMaxHeight={1152}
              imageMaxWidth={800}
              onUploadConfirm={handleUploadConfirm}
              minFileSize={10000}
              required
            />
            <SingleImageDropzone
              description="Image 3 *"
              name="cropFrontView"
              imageMinHeight={432}
              imageMinWidth={300}
              imageMaxHeight={1152}
              imageMaxWidth={800}
              onUploadConfirm={handleUploadConfirm}
              minFileSize={10000}
            />
            <SingleImageDropzone
              description="Image 4 *"
              name="cropBackView"
              imageMinHeight={432}
              imageMinWidth={300}
              imageMaxHeight={1152}
              imageMaxWidth={800}
              onUploadConfirm={handleUploadConfirm}
              minFileSize={10000}
            />
            <SingleImageDropzone
              description="Image 5 "
              name="packshotView"
              imageMinHeight={432}
              imageMinWidth={300}
              imageMaxHeight={1152}
              imageMaxWidth={800}
              onUploadConfirm={handleUploadConfirm}
              minFileSize={10000}
            />
          </FormWrapper>
        </UploadProductCard>
        <UploadProductCard heading="Stock on Hand vs Make To Order">
          <Paragraph>
            We understand that estimating demand for clothing items is
            difficult. That's why we offer make-to-order functionality.
          </Paragraph>
          <Paragraph>
            <strong>Stock on hand:</strong> Some variations of the described
            product have already been manufactured and can be delivered
            immediately.
          </Paragraph>
          <Paragraph>
            <strong>Make to order:</strong> Some variations of the described
            product have not yet been manufactured and will take a bit longer to
            deliver.
          </Paragraph>
          <Paragraph>
            <strong>Lead time:</strong> An estimate of the number of days to
            manufacture the item. We will communicate this to the customer.
          </Paragraph>
        </UploadProductCard>

        <StoVariants
          parent="upload"
          setShowVariants={(variant) => setShowVariants(variant)}
          showVariants={showVariants}
          handleUpdateVariants={handleUpdateVariants}
        />
        <MtoVariants
          parent="upload"
          setShowVariants={(variant) => setShowMakeToOrder(variant)}
          showVariants={showMakeToOrder}
          handleUpdateVariants={handleUpdateVariants}
        />
      </ContentArea>
      <Flex
        backgroundColor={theme.colors.background}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        p={theme.padding.standard}
        borderRadius="0"
        m="0"
      >
        <Button
          mr="auto"
          backgroundColor={theme.colors.halfDark}
          variant="flat"
          onClick={() => changeProductPageDispatch({ type: 'LIST' })}
        >
          Back
        </Button>

        {error.length > 0 && (
          <Paragraph variant="input_error" color="red">
            {error}
          </Paragraph>
        )}
        {/* <Button backgroundColor={theme.colors.halfDark} variant="flat">
            Discard
          </Button> */}
        <Button
          isLoading={isFetching || isLoading || isUploading}
          backgroundColor={theme.colors.primaryCta}
          variant="flat"
          onClick={() => submit(handleUploadProduct)}
        >
          Save Product
        </Button>
      </Flex>
    </Flex>
  );
};

export default UploadProduct;
