import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import useInput from '../../../hooks/useInput';
import useS3Upload from '../../../hooks/useS3Upload';
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import useIsWindowFocused from '../../../hooks/useWindowFocus';
import { useAppDispatch } from '../../../store/hooks';
import { notificationActions } from '../../../store/notifications/notification-slice';
import { useUpdateRetailerManageStoreMutation } from '../../../store/retailer-store/retailer-store-api';
import {
  IRetailerInformation,
  IUpdateManageStoreDetails,
} from '../../../store/retailer-store/types';
import theme from '../../../theme/theme';
import deepCompare from '../../../utils/deepCompare';
import FormWrapper from '../../form-wrapper';
import ProductInputField from '../../product-input';
import ToggleSwitch from '../../standard-components/elements/toggle-switch';
import Container from '../../standard-components/layout/container';
import { Flex } from '../../standard-components/layout/flex-box';
import Heading from '../../standard-components/typography/heading';
import Paragraph from '../../standard-components/typography/paragraph';
import UploadProductCard from '../../upload-product-card';
import StoreImageEdit from './image-edit';
import RetailerStoreFooter from './retailer-footer';

const mapFields = (data?: IRetailerInformation) => {
  return {
    shopName: data?.storeName ?? '',
    shopContactNumber: data?.shopContactNumber ?? '',
    shopEmail: data?.shopEmail ?? '',
    website: data?.website ?? '',
    instagram: data?.instagram ?? '',
    facebook: data?.facebook ?? '',
    storeBackground: data?.storeBackground ?? '',
    storeBio: data?.storeBio ?? '',
    banner: data?.logo ?? '',
    logo: data?.logo ?? '',
  };
};

const ManageStore = ({
  storeID,
  refetch,
  defaultData,
  retailerDetailsID,
}: {
  storeID: string;
  retailerDetailsID: string | null;
  refetch: () => void;
  defaultData: IRetailerInformation | undefined;
}) => {
  const { upload } = useS3Upload();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const reduxDispatch = useAppDispatch();
  const { timeToTokenExpiry } = useAuth();
  const windowIsFocused = useIsWindowFocused();
  const [isFetching, setIsFetching] = useState(false);
  const bucket = process.env.REACT_APP_AWS_S3_BUCKET;
  const [storeStatus, setStoreStatus] = useState(false);
  const [useBrandColour, setUseBrandColour] = useState(false);
  const [updateRetailer, { isLoading }] =
    useUpdateRetailerManageStoreMutation();
  const [manageStoreIsModified, setManageStoreIsModified] = useState(false);
  const [storeStatusModified, setStoreStatusModified] = useState(false);
  const { register, errors, values, submit, resetDefaults, updateDefaults } =
    useInput(mapFields(defaultData));
  const initialStoreData = useRef<Partial<IRetailerInformation> | null>({
    ...mapFields(defaultData),
    useBrandColour: defaultData?.useBrandColour ?? false,
  });
  const [imageIsModified, setImageIsModified] = useState(false);
  const [requiredImages, setRequiredImages] = useState<string[]>([]);
  const [storeImages, setStoreImages] = useState<
    Array<{ name: string; image: Blob | null; required?: boolean }>
  >([]);

  useEffect(() => {
    setStoreStatus(defaultData?.status === 'active');
    setUseBrandColour(defaultData?.useBrandColour ? true : false);
  }, [defaultData?.status, defaultData?.useBrandColour]);

  useEffect(() => {
    const _req = [];
    !initialStoreData.current?.logo && _req.push('logo');
    !initialStoreData.current?.banner && _req.push('banner');

    setRequiredImages(_req);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const expiryTime = timeToTokenExpiry ? parseInt(timeToTokenExpiry) : 0;
    if (Date.now() > expiryTime) {
      //Token has expired
      if (
        window.confirm(
          'Current session has expired, Please Login again to continue.'
        )
      ) {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    }
  }, [navigate, timeToTokenExpiry, windowIsFocused]);

  useUpdateEffect(() => {
    const brandColourCompare =
      initialStoreData.current &&
      initialStoreData.current.useBrandColour !== useBrandColour;

    const initialStatus = initialStoreData.current?.status === 'active';
    const storeStatusCompare =
      initialStoreData.current && initialStatus !== storeStatus;
    if (storeImages.length > 0) {
      setImageIsModified(true);
    } else {
      setImageIsModified(false);
    }

    if (
      !deepCompare(values, initialStoreData.current) ||
      brandColourCompare ||
      storeStatusCompare ||
      imageIsModified ||
      storeStatusModified
    ) {
      setManageStoreIsModified(true);
    } else {
      setManageStoreIsModified(false);
    }
  }, [values, useBrandColour, storeStatus, storeImages.length]);

  const handleUploadConfirm = (
    image: Blob,
    name: string,
    required?: boolean
  ) => {
    let _images = [...storeImages];
    const imageIndex = _images.findIndex((value) => value.name === name);
    if (imageIndex >= 0) {
      _images[imageIndex] = {
        image,
        name,
        required,
      };
    } else {
      _images.push({
        image,
        name,
        required,
      });
    }

    setStoreImages([]);
    setStoreImages(_images);
    setImageIsModified(true);
  };

  const handleDiscard = () => {
    if (window.confirm('All unsaved data will be lost. Continue?')) {
      //TODO: on discard

      setUseBrandColour(defaultData?.useBrandColour ?? false);
      setStoreStatus(defaultData?.status === 'active' ? true : false);
      resetDefaults();
      setStoreStatusModified(false);
      setManageStoreIsModified(false);
    }
  };

  const handleUpdateManageStore = async () => {
    setIsFetching(true);
    const imagesLoaded = storeImages
      .map((image) => {
        if (image.required) return image.name;
        return null;
      })
      .filter((name) => name !== null);

    const imageErrors = requiredImages.every((image) => {
      return imagesLoaded.includes(image);
    });

    if (imageIsModified && !imageErrors) {
      setError('Required Image Is Missing');
    } else {
      try {
        let response = null;
        if (defaultData && retailerDetailsID) {
          response = await updateRetailer({
            retailerDetailsID,
            storeID,
            data: {
              ...values,
              useBrandColour,
              status: storeStatus ? 'active' : 'inactive',
            } as unknown as IUpdateManageStoreDetails,
          }).unwrap();
        } else {
          throw Error(
            'Access Denied.Please log in again to perform this action'
          );
        }
        if (!response) {
          if (
            window.confirm(
              'Access Denied.Please log in again to perform this action'
            )
          ) {
            navigate('/login');
          } else {
            throw Error(
              'Access Denied.Please log in again to perform this action'
            );
          }
          resetDefaults();
        } else {
          const _images: { [key: string]: string } = {};
          storeImages.forEach((image) => {
            _images[
              image.name
            ] = `${process.env.REACT_APP_AWS_S3_URL}/retailer-stores/${storeID}/${image.name}.jpg`;
          });
          if (imageIsModified) {
            setIsFetching(true);
            console.log('IMAGE CHANGED. Updating S3');
            console.log('Store Images:', storeImages);
            await Promise.all(
              storeImages.map(async ({ name, image }) => {
                await upload(
                  image,
                  {
                    uploadType: 'image',
                    filename: `${storeID}/${name}.jpg`,
                    folderPrefix: 'retailer-stores',
                    bucket: bucket!,
                  },
                  0
                );
              })
            );
          }

          reduxDispatch(
            notificationActions.pushNotification({
              message: 'Store Updated successfully',
              type: 'success',
              autoClose: true,
              closeTime: 2000,
              positionY: 'top',
              positionX: 'center',
            })
          );
          refetch();
          setIsFetching(false);
          updateDefaults();
          setManageStoreIsModified(false);
        }
      } catch (error) {
        reduxDispatch(
          notificationActions.pushNotification({
            message:
              'Store Updated Failed, Try Again or Contact Your Vincii Representative',
            type: 'error',
            autoClose: true,
            closeTime: 3000,
            positionY: 'top',
            positionX: 'center',
          })
        );
        setIsFetching(false);
      }
    }
  };
  return (
    <Flex
      flexDirection="column"
      width="100%"
      backgroundColor={theme.colors.BGAdmin}
      overflowY="hidden"
      noDefaultStyle
    >
      <Container
        p={theme.padding.standard}
        overflowY="scroll"
        noDefaultStyle
        flex="1"
      >
        <UploadProductCard heading="Shop Overview">
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Shop Name *"
              placeholder="Enter shop name"
              defaultValue={defaultData?.storeName ?? ''}
              value={values.shopName}
              {...register('shopName', { required: true })}
              error={errors.shopName}
            />

            <ProductInputField
              label="Shop Email"
              placeholder="Enter shop email"
              defaultValue={defaultData?.shopEmail ?? ''}
              value={values.shopEmail}
              {...register('shopEmail')}
              error={errors.shopEmail}
            />
            <ProductInputField
              label="Shop Contact Number"
              placeholder="Enter company contact number"
              defaultValue={defaultData?.shopContactNumber ?? ''}
              value={values.shopContactNumber}
              {...register('shopContactNumber')}
              error={errors.shopContactNumber}
            />
          </FormWrapper>

          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={3}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              defaultValue={defaultData?.website ?? ''}
              value={values.website}
              error={errors.website}
              label="Website"
              placeholder="Enter website url"
              {...register('website')}
            />
            <ProductInputField
              defaultValue={defaultData?.instagram ?? ''}
              value={values.instagram}
              error={errors.instagram}
              label="Instagram"
              placeholder="https://www.instagram.com/username"
              {...register('instagram')}
            />
            <ProductInputField
              defaultValue={defaultData?.facebook ?? ''}
              value={values.facebook}
              error={errors.facebook}
              label="Facebook"
              placeholder="https://www.facebook.com/username"
              {...register('facebook')}
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={1}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              defaultValue={defaultData?.storeBio ?? ''}
              value={values.storeBio}
              error={errors.storeBio}
              label="Shop Description"
              placeholder="Add a short description for your shop."
              {...register('storeBio')}
            />
          </FormWrapper>
        </UploadProductCard>
        <UploadProductCard heading="Logo Image, Banner Image and Brand colour">
          <Paragraph>
            We need a hex code for your brand colour. We'll' use this as the
            background colour of your store. For example #402AB2 is the hex code
            for Vincii Purple.
          </Paragraph>
          <FormWrapper columns={2} mb="0" pb="0">
            <ProductInputField
              label="Brand colour *"
              placeholder="#402AB2"
              defaultValue={defaultData?.storeBackground ?? ''}
              value={values.storeBackground}
              error={errors.storeBackground}
              {...register('storeBackground', { required: true })}
            />
          </FormWrapper>
          <FormWrapper columns={1} mb="0" pb="0">
            <Flex
              pb={theme.padding.standard}
              flexDirection="column"
              noDefaultStyle
            >
              <Heading py="0" m="0" level="sub" variant="card_sub_heading">
                Would you prefer to use Vincii color scheme for your store?
              </Heading>
              <Flex noDefaultStyle flex={1} alignItems="flex-end">
                <Paragraph
                  color={theme.colors.primaryCta}
                  fontWeight="500 !important"
                  pb="2px"
                  my="0"
                >
                  Use Vincii Colours?
                </Paragraph>
                <ToggleSwitch
                  name="variantSwitch"
                  isOn={useBrandColour}
                  handleToggle={() =>
                    setUseBrandColour((currentState) => !currentState)
                  }
                />
              </Flex>
            </Flex>
          </FormWrapper>
          <Paragraph>
            Upload your logo and banner images. A demo of the Logo and Banner is
            shown on the right of each image Drop Zone. Please ensure that your
            banner image has an aspect ratio of 2:1 and your logo has an aspect
            ratio of 1:1. Upload the image and confirm once you are happy with
            the preview.
          </Paragraph>
          <FormWrapper my="0" py="0" columns={1}>
            <StoreImageEdit
              handleUploadConfirm={handleUploadConfirm}
              logo={`${process.env.REACT_APP_AWS_S3_URL}/retailer-stores/${storeID}/logo.jpg`}
              banner={`${process.env.REACT_APP_AWS_S3_URL}/retailer-stores/${storeID}/banner.jpg`}
              imageEditCallBack={(name, required) =>
                required &&
                ((requiredImages: string[]) => [...requiredImages, name])
              }
            />
          </FormWrapper>
        </UploadProductCard>
        {(defaultData?.status === 'active' ||
          defaultData?.status === 'inactive') && (
          <UploadProductCard heading="Store Status">
            <FormWrapper
              backgroundColor={theme.colors.white}
              px={theme.padding.m}
              columns={2}
              mt={theme.margins.standard}
              mb={0}
            >
              <Paragraph variant="hero_body">
                Going on holiday? Use this toggle to take your store on/offline.
                Your store won't appear in the marketplace when offline.
              </Paragraph>
              <ToggleSwitch
                name="manageStoreVisibility"
                isOn={storeStatus}
                handleToggle={() => {
                  setStoreStatus((state) => !state);
                  setStoreStatusModified(true);
                }}
              />
            </FormWrapper>
          </UploadProductCard>
        )}
      </Container>
      <RetailerStoreFooter
        error={[error]}
        isLoading={isFetching}
        isModified={manageStoreIsModified}
        onClickBack={handleDiscard}
        onClickDiscard={handleDiscard}
        onClickUpload={handleUpdateManageStore}
        buttonText="Save Changes"
      />
    </Flex>
  );
};

export default ManageStore;
