/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import theme from '../../../theme/theme';
import Grid from '../../standard-components/layout/grid';
import Container from '../../standard-components/layout/container';
import { Order, OrderStatus } from '../../../store/orders/orders-types';
import OrderStatusItem from './OrderStatusItem';
import OrderRowItem from './OrderRowItem';
import ProductRowItem from './ProductRowItem';
import ProductHeaderRow from './ProductHeaderRow';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { OrderStatusMap } from '../../../constants/orders';
import Image from '../../standard-components/elements/image';
import ParagraphLink from '../../standard-components/typography/paragraph-link';
import { Tooltip } from '@material-ui/core';
import { css } from '@emotion/react';

const OrderItem = ({
  showProducts,
  setShowProducts,
  ...order
}: {
  showProducts: boolean;
  setShowProducts: (refNumber: string) => void;
} & Order) => {
  const orderDate = useMemo(() => {
    const date = new Date(order.timeStamp);
    return {
      time: `${
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      }:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }`,
      date: `${date.getFullYear()}/${date.getMonth()}/${date.getDay()}`,
    };
  }, [order.timeStamp]);
  const orderTotal = useMemo(() => {
    return order.products.reduce(
      (prev, current) => prev + current.productPrice * current.variant.quantity,
      0
    );
  }, [order.products]);

  const handleOrderAction = () => {
    if (!showProducts) {
      setShowProducts(order.referenceNumber);
    } else {
      setShowProducts('');
    }
  };

  if (
    order.status[order.status.length - 1].status == 'PLACED' ||
    order.status[order.status.length - 1].status == 'PAYMENT_UNSUCCESFUL' ||
    order.status[order.status.length - 1].status == 'REFUNDED' ||
    order.status[order.status.length - 1].status == 'RETAILER_MISSED' ||
    order.status[order.status.length - 1].status == 'RETAILER_DECLINED'
    // !OrderStatusMap[order.status[order.status.length - 1].status as OrderStatus]
    //   .label
  ) {
    return null;
  }

  // console.log(order);

  return (
    <>
      <Grid
        mx={theme.margins.standard}
        gridColumnGap="1.1rem"
        gridTemplateColumns="repeat(6, 1fr) 8rem"
        alignItems="center"
        py={theme.padding.standard}
        my={showProducts ? '0' : theme.margins.s}
        backgroundColor={theme.colors.white}
        boxShadow="small"
      >
        <Tooltip title="Vincii Order Number" arrow>
          <div>
            <OrderRowItem item={order.referenceNumber} />
          </div>
        </Tooltip>
        <Tooltip title="This is when the customer paid." arrow>
          <div>
            <OrderRowItem item={orderDate.date} />
          </div>
        </Tooltip>
        <Tooltip
          title="Track your order. Collections are triggered for the day after you request. Our delivery partners do not operate on weekends and public holidays"
          arrow
        >
          <div>
            <OrderStatusItem status={order.status} orderID={order._id} />
          </div>
        </Tooltip>
        <OrderRowItem item={`${order.products.length}`} />
        <OrderRowItem item={`R${orderTotal}`} />

        {(order.aramexWaybill && (
          <Tooltip arrow title="Download and attach to parcel for delivery">
            <div>
              <a
                href={order.aramexWaybill.label_print}
                color="black"
                target="__blank"
              >
                {order.aramexWaybill.waybill_number}
              </a>
            </div>
          </Tooltip>
        )) || (
          <Tooltip arrow title="No waybill required">
            <div></div>
          </Tooltip>
        )}

        <Container
          noDefaultStyle
          justifySelf="center"
          pr={theme.padding.standard}
          cursor="pointer"
          onClick={handleOrderAction}
        >
          {showProducts ? (
            <RiArrowUpSLine size="1.8rem" color="#000" />
          ) : (
            <RiArrowDownSLine size="1.8rem" color="#000" />
          )}
        </Container>
      </Grid>
      {showProducts && (
        <Container
          overflowY="scroll"
          overflowX="hidden"
          noDefaultStyle
          borderRadius={`0px 0px ${theme.borderRadius.normal} ${theme.borderRadius.normal} !important`}
          border="1px solid rgba(0,0,0,.1)"
          borderT="0 !important"
          mx={theme.margins.m}
          px={theme.padding.m}
        >
          <ProductHeaderRow />

          {order.products.map((product, index) => (
            <ProductRowItem
              key={`Product-${order.referenceNumber}-${index}`}
              {...product}
            />
          ))}
        </Container>
      )}
    </>
  );
};

export default OrderItem;
