import { SerializedStyles } from '@emotion/serialize';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import { Flex } from '../../layout/flex-box';
import Button from '../button';

const Footer = ({
  type,
  addCss,
  children,
  disabled,
  isLoading,
  buttonText,
  onClick,
  ...styles
}: {
  children?: any;
  type?: 'submit';
  buttonText: string;
  disabled?: boolean;
  isLoading?: boolean;
  addCss?: SerializedStyles;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & StandardProps) => (
  <Flex
    backgroundColor={theme.colors.background}
    flexDirection="row"
    justifyContent="flex-end"
    alignItems="center"
    p={theme.padding.s}
    borderRadius="0"
    m="0"
  >
    <Button
      variant="outlined"
      color={theme.colors.white}
      fontSize={theme.fontSizes.medium}
      backgroundColor={
        disabled ? theme.colors.lightGrey : theme.colors.primaryCta
      }
      disabled={disabled}
      onClick={onClick}
      type={type}
      isLoading={isLoading}
      px={theme.padding.standard}
    >
      {`${buttonText}`}
    </Button>
  </Flex>
);

export default Footer;
