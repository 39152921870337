import { createContext, FunctionComponent, useMemo, useReducer } from 'react';
import { ActionType, IOnboardingContext, IOnboardingState } from './types';

//TODO: Create custom type for Context
export const OnboardingContext = createContext<IOnboardingContext | null>(null);

const initialState: IOnboardingState = {
  pageNumber: 1,
  isFormDisabled: true, //NEEDS TO BE TRUE
  onboardingDocuments: {
    retailerVerification: null,
    proofOfBankDetails: null,
    sizingGuide: null,
  },
  onboardingImages: {
    logo: '',
    banner: '',
  },
};

const reducer = (
  state: IOnboardingState,
  action: ActionType
): IOnboardingState => {
  switch (action.type) {
    case 'next':
      const nextPage = state.pageNumber + 1;
      return {
        ...state,
        isFormDisabled: true,
        pageNumber: nextPage,
      };
    case 'back':
      const prevPage = state.pageNumber > 1 ? state.pageNumber - 1 : 1;
      return {
        ...state,
        isFormDisabled: false,
        pageNumber: prevPage,
      };
    case 'disable_form':
      return {
        ...state,
        isFormDisabled: action.payload,
      };
    case 'image_upload':
      return {
        ...state,
        onboardingImages: {
          ...state.onboardingImages,
          [action.payload.key]: action.payload.data,
        },
      };
    case 'document_upload':
      return {
        ...state,
        onboardingDocuments: {
          ...state.onboardingDocuments,
          [action.payload.key]: action.payload.data,
        },
      };

    default:
      throw new Error(`Invalid Onboarding Action Type: ${action}`);
  }
};

const OnboardingContextProvider: FunctionComponent = ({ children }) => {
  const [onboardingState, onboardingDispatch] = useReducer(
    reducer,
    initialState
  );
  const store = useMemo(
    () => ({ ...onboardingState, onboardingDispatch }),
    [onboardingState]
  );
  return (
    <OnboardingContext.Provider value={store}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContextProvider;
