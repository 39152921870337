import React from 'react';
import TagInput from '../../standard-components/elements/tag-input';
import { Flex } from '../../standard-components/layout/flex-box';
import Heading from '../../standard-components/typography/heading';
import { IVariantTags } from '../types';

const Variant = ({
  name,
  tags,
  addVariantTagToList,
  removeVariantTagFromList,
}: {
  name: string;
  tags: string[];
  addVariantTagToList: ({
    name,
    tags,
  }: {
    name: keyof IVariantTags;
    tags: string[];
  }) => void;
  removeVariantTagFromList: ({
    name,
    index,
  }: {
    name: keyof IVariantTags;
    index: number;
  }) => void;
}) => {
  const handleUpdateTagInput = (event: any) => {
    if (event.key !== 'Enter') return;
    const { value } = event.target;
    if (!value.trim()) return;

    addVariantTagToList({
      name: name as keyof IVariantTags,
      tags: [value],
    });

    event.target.value = '';
  };

  return (
    <Flex mx="0">
      <Heading
        my={0}
        px="0"
        ml="0"
        flex={'0 0 20%'}
        variant="card_item_heading"
        level="subsub"
      >{`${name.charAt(0).toUpperCase() + name.slice(1)} :`}</Heading>
      <TagInput
        name={name}
        tags={tags}
        onClose={(index) =>
          removeVariantTagFromList({
            name: name as keyof IVariantTags,
            index,
          })
        }
        onKeyDown={handleUpdateTagInput}
      />
    </Flex>
  );
};

export default Variant;
