/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import theme from '../../../../theme/theme';

const TagItem = ({
  text,
  color,
  background,
  onClose,
}: {
  text: string;
  color?: string;
  background?: string;
  onClose: MouseEventHandler<HTMLSpanElement>;
}) => {
  return (
    <div
      css={css`
        display: inline-block;
        padding: 0.2rem 0.35rem;
        border-radius: ${theme.borderRadius.normal};
        background-color: ${background ?? 'rgb(218,216,216)'};
      `}
    >
      <span
        css={css`
          font-size: '12px';
          font-weight: 700;
          color: ${color ?? theme.colors.halfDark};
        `}
      >
        {text}
      </span>
      <span
        css={css`
          height: 20px;
          width: 20px;
          background-color: ${theme.colors.primaryCta};
          display: inline-flex;
          color: #fff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          margin-left: 0.5em;
          font-size: 16px;
          transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
            box-shadow: ${theme.shadows.small};
          }
          &:active {
            box-shadow: none;
            transform: scale(1);
          }
        `}
        onClick={onClose}
      >
        &times;
      </span>
    </div>
  );
};
const TagInput = ({
  onClose,
  onKeyDown,
  tags,
  name,
}: {
  name: string;
  tags: string[];
  onClose: (index: number) => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
}) => {
  return (
    <>
      <div
        css={css`
          flex-grow: 1;
          border: ${theme.borders.lighter};
          padding: 0.3rem 0.5rem;
          border-radius: ${theme.borderRadius.normal};
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.2rem;
        `}
      >
        {tags.map((tag, index) => (
          <TagItem
            text={tag}
            onClose={() => onClose(index)}
            key={`tag-item-${index}`}
          />
        ))}
        <input
          onKeyDown={onKeyDown}
          css={css`
            margin: 0;
            flex-grow: 1;
            padding: 0.2rem 0;
            border: none;
            outline: none;
            background-color: #fff;
            color: ${theme.colors.dark};
            font-family: "'Roboto', sans-serif";
            font-size: 14px;
            letter-spacing: -0.02em;
          `}
          type="text"
          placeholder={`Type to add ${name}(s)..`}
        ></input>
      </div>
    </>
  );
};

export default TagInput;
