import { css } from '@emotion/react';
import React, { useState } from 'react';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import Paragraph from '../../typography/paragraph';

const Tooltip = ({
  show,
  text,
  xPos,
  yPos,
  orientation,
  ...styles
}: {
  xPos: string | number;
  yPos: string | number;
  show: boolean;
  text: string;
  orientation: 'left' | 'right';
} & StandardProps) => {
  return (
    <Container
      m="0"
      zIndex="2000"
      width="240px"
      position="fixed"
      transition="opacity 0.3s"
      opacity={show ? '1' : '0'}
      pb={theme.padding.standard}
      visibility={show ? 'visible' : 'hidden'}
      backgroundColor={theme.colors.halfDark}
      {...styles}
      addCss={css`
        top: ${`calc(${yPos}px - 7%)`};
        left: ${xPos}px;
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: ${orientation === 'right' ? 0 : 60}%;
          margin-left: ${orientation === 'right' ? 10 : 0}px;
          margin-right: ${orientation === 'right' ? 0 : -10}px;
          border-width: 10px;
          border-style: solid;
          border-color: ${theme.colors.halfDark} transparent transparent
            transparent;
        }
      `}
    >
      {show && (
        <Paragraph
          noDefaultStyle
          mb="0"
          textAlign="center"
          variant="tool_tip"
          opacity={show ? '1' : '0'}
          color={theme.colors.white}
          transition="opacity 0.3s"
        >
          {text}
        </Paragraph>
      )}
    </Container>
  );
};

export default Tooltip;
