import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyShopContext } from '../../context/my-shop/MyShopContext';
import { useAuth } from '../../hooks/useAuth';
import useHttp from '../../hooks/useHttp';
import useInput from '../../hooks/useInput';
import useS3Upload from '../../hooks/useS3Upload';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import useIsWindowFocused from '../../hooks/useWindowFocus';
import { appApi } from '../../store/app-api';
import { useAppDispatch } from '../../store/hooks';
import { notificationActions } from '../../store/notifications/notification-slice';
import { Product } from '../../store/products/product-types';
import theme from '../../theme/theme';
import deepCompare from '../../utils/deepCompare';
import DropdownMenu from '../dropdown';
import FormWrapper from '../form-wrapper';
import MultipleSelectionDropdownMenu from '../multiple-selection-dropdown';
import ProductInputField from '../product-input';
import MtoVariants from '../product-variants/MtoVariants';
import StoVariants from '../product-variants/StoVariants';
import {
  IVariantBaseWithLeadTime,
  IVariantCombine,
} from '../product-variants/types';
import BreadCrumb from '../standard-components/elements/breadcrumb';
import Button from '../standard-components/elements/button';
import ContentArea from '../standard-components/elements/content-area';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import UploadProductCard from '../upload-product-card';
import {
  EApparelType,
  EPurpose,
  ETargetGroup,
  IMTOVariant,
  IVariant,
} from '../upload-product/upload-product.types';
import ProductEditImagePreview from './product-image-edit';

const EditProduct = ({
  storeID,
  productEditDefault,
}: {
  storeID: string;
  productEditDefault: Product | null;
}) => {
  const bucket = process.env.REACT_APP_AWS_S3_BUCKET;
  const { dispatch: productPageToDisplay } = useContext(MyShopContext)!;
  const reduxDispatch = useAppDispatch();
  const [productIsModified, setProductIsModified] = useState(false);
  const [imageIsModified, setImageIsModified] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [productData, setProductData] = useState<Partial<Product>>();
  const { register, errors, values, submit } = useInput({
    productName: productEditDefault?.productName ?? '',
    productDescription: productEditDefault?.productDescription ?? '',
    productPrice: productEditDefault?.productPrice ?? '',
  });
  const initialProductData = useRef<Product | null>(productEditDefault);
  const { storeName, timeToTokenExpiry } = useAuth();

  const [error, setError] = useState('');
  const [productImages, setProductImages] = useState<
    Array<{ name: string; image: Blob | null; required?: boolean }>
  >([]);
  const { isLoading, put } = useHttp(`${process.env.REACT_APP_API_HOST}`);
  const { upload } = useS3Upload();
  const handleDiscard = () => {
    if (window.confirm('All unsaved data will be lost. Continue?')) {
      productPageToDisplay({ type: 'LIST' });
    }
  };
  const [requiredImages, setRequiredImages] = useState<string[]>([]);
  const navigate = useNavigate();
  const windowIsFocused = useIsWindowFocused();
  const [showVariants, setShowVariants] = useState(false);
  const [showMakeToOrder, setShowMakeToOrder] = useState(false);

  useEffect(() => {
    const expiryTime = timeToTokenExpiry ? parseInt(timeToTokenExpiry) : 0;
    if (Date.now() > expiryTime) {
      //Token has expired
      if (
        window.confirm(
          'Current session has expired, Please Login again to continue.'
        )
      ) {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    }
  }, [navigate, timeToTokenExpiry, windowIsFocused]);

  const handleUploadConfirm = (
    image: Blob,
    name: string,
    required?: boolean
  ) => {
    setProductImages((productImages) => [
      ...productImages,
      { name, image, required },
    ]);
  };

  const handleUpdateOptions = (item: string, key: string) => {
    setProductData((productData) => ({
      ...productData,
      commodityGroups: {
        ...productData?.commodityGroups!,
        [key]: item,
      },
    }));
  };
  const handleUpdateVariants = ({
    name,
    variants,
  }: {
    name: 'soh' | 'mto';
    variants: IVariantCombine[];
  }) => {
    const variantIndex = (
      name === 'mto' ? 'leadTime' : 'quantity'
    ) as keyof IVariantBaseWithLeadTime;
    const variantType = name === 'mto' ? 'makeToOrderVariants' : 'variants';
    setProductData((product) => ({
      ...product,
      [variantType]: variants.filter(
        (_variant) => _variant && (_variant[variantIndex] as number) > 0
      ),
    }));
  };

  const handleMultipleSelection = (
    currentValues: string[],
    clickedItem: string,
    key: string
  ) => {
    const isCurrentValueSelected = currentValues.includes(clickedItem);
    if (isCurrentValueSelected) {
      setProductData((productData) => ({
        ...productData,
        commodityGroups: {
          ...productData?.commodityGroups!,
          [key]: currentValues.filter((value) => value !== clickedItem),
        },
      }));

      return;
    }
    setProductData({
      ...productData,
      commodityGroups: {
        ...productData?.commodityGroups!,
        [key]: [...currentValues, clickedItem],
      },
    });
  };

  const handleUploadProduct = async () => {
    const imagesLoaded = productImages
      .map((image) => {
        if (image.required) return image.name;
        return null;
      })
      .filter((name) => name !== null);

    const imageErrors = requiredImages.every((image) => {
      return imagesLoaded.includes(image);
    });

    if (imageIsModified && !imageErrors) {
      setError('Required Image Is Missing');
    } else {
      setError('');
      const _images: { [key: string]: string } = {};
      productImages.forEach((image) => {
        _images[
          image.name
        ] = `${process.env.REACT_APP_AWS_S3_URL}/retailer-products/${storeID}/${productEditDefault?._id}/${image.name}.jpg`;
      });

      const productUploadData: Partial<Product> = {
        ...productData,
        ..._images,
        productName: values.productName,
        productDescription: values.productDescription,
        productPrice: values.productPrice,
      };

      try {
        const response = await put({
          url: `/products?id=${productUploadData._id}`,
          data: productUploadData,
        });
        if (!response) {
          if (
            window.confirm(
              'Access Denied.Please log in again to perform this action'
            )
          ) {
            navigate('/login');
          } else {
            throw Error(
              'Access Denied.Please log in again to perform this action'
            );
          }
        }
        if (imageIsModified) {
          setIsFetching(true);
          await Promise.all(
            productImages.map(async ({ name, image }) => {
              await upload(
                image,
                {
                  uploadType: 'image',
                  filename: `${storeID}/${productEditDefault?._id}/${name}.jpg`,
                  folderPrefix: 'retailer-products',
                  bucket: bucket!,
                },
                0
              );
            })
          );
        }

        reduxDispatch(
          notificationActions.pushNotification({
            message: 'Product Updated successfully',
            type: 'success',
            autoClose: true,
            closeTime: 2000,
            positionY: 'top',
            positionX: 'center',
          })
        );
        reduxDispatch(appApi.util.invalidateTags(['Products']));
        setIsFetching(false);
        productPageToDisplay({ type: 'LIST' });
      } catch (error) {
        reduxDispatch(
          notificationActions.pushNotification({
            message: 'Product Updated Failed, Try Again or Contact Support',
            type: 'error',
            autoClose: true,
            closeTime: 3000,
            positionY: 'top',
            positionX: 'center',
          })
        );
        setIsFetching(false);
      }
    }
  };
  useEffect(() => {
    if (!storeName) {
      navigate('/login');
    }
  }, [navigate, storeName]);
  useEffect(() => {
    const _req = [];
    !initialProductData.current?.catalogueImage && _req.push('catalogueImage');
    !initialProductData.current?.detailView && _req.push('detailView');

    setRequiredImages(_req);
    if (initialProductData.current) setProductData(initialProductData.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    //Check if Logged in

    const productUploadData = {
      ...productData,
      productName: values.productName,
      productDescription: values.productDescription,
      productPrice: values.productPrice,
      variants: productData?.variants!,
      commodityGroups: productData?.commodityGroups!,
      storeFK: storeID,
    };

    if (productImages.length > 0) {
      setImageIsModified(true);
    } else {
      setImageIsModified(false);
    }

    if (!deepCompare(productUploadData, initialProductData.current)) {
      setProductIsModified(true);
    } else {
      setProductIsModified(false);
    }
  }, [
    productImages.length,
    productData,
    storeID,
    values.productDescription,
    values.productName,
    values.productPrice,
  ]);

  return (
    <Flex
      backgroundColor={theme.colors.veryLightGrey}
      flexDirection="column"
      borderRadius={0}
      minHeight="100vh"
      width="100%"
      p={0}
      m={0}
    >
      <ContentArea overflowY="scroll" flex="1">
        <BreadCrumb levels={['Products', 'Edit Product']} />
        <UploadProductCard heading="Product Details">
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt={theme.margins.standard}
            mb={0}
          >
            <ProductInputField
              label="Name *"
              placeholder="Enter product name"
              defaultValue={initialProductData.current?.productName}
              {...register('productName', { required: true })}
              error={errors.productName}
            />
            <ProductInputField
              label="Description"
              placeholder="Enter product name"
              defaultValue={initialProductData.current?.productDescription}
              {...register('productDescription')}
              error={errors.productDescription}
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt="0"
          >
            <ProductInputField
              defaultValue={initialProductData.current?.productPrice}
              label="Price (Rands) *"
              placeholder="Enter product price"
              {...register('productPrice', { required: true })}
              error={errors.productPrice}
            />
            <MultipleSelectionDropdownMenu
              currentValues={
                productData?.commodityGroups?.targetGroup
                  ? (productData?.commodityGroups
                      ?.targetGroup! as unknown as string[])
                  : []
              }
              options={Object.values(ETargetGroup)}
              title="Select Target Group..."
              callback={(currentValues: string[], clickedItem: string) =>
                handleMultipleSelection(
                  currentValues,
                  clickedItem,
                  'targetGroup'
                )
              }
            />
          </FormWrapper>
          <FormWrapper
            backgroundColor={theme.colors.white}
            px={theme.padding.m}
            columns={2}
            mt="0"
            mb={theme.margins.standard}
          >
            <MultipleSelectionDropdownMenu
              currentValues={
                productData?.commodityGroups?.purpose
                  ? (productData?.commodityGroups
                      ?.purpose! as unknown as string[])
                  : []
              }
              options={Object.values(EPurpose)}
              title="Select Occasion..."
              callback={(currentValues: string[], clickedItem: string) =>
                handleMultipleSelection(currentValues, clickedItem, 'purpose')
              }
            />
            <DropdownMenu
              noDefaultStyles
              title="Apparel Type"
              options={Object.values(EApparelType)}
              callback={(item) => handleUpdateOptions(item, 'apparelType')}
              currentItem={
                productData?.commodityGroups?.apparelType ??
                'Select Apparel Type...'
              }
            />
          </FormWrapper>
        </UploadProductCard>
        <UploadProductCard heading="Image Guideline">
          <Paragraph>
            Please refer to the image guideline below if you have any questions.
          </Paragraph>
          <a
            href="https://public-information.s3.af-south-1.amazonaws.com/VINCII+Image+Guideline.pdf"
            target="_blank"
            download
            rel="noreferrer"
          >
            <Paragraph>Download VINCII Image Guideline</Paragraph>
          </a>
        </UploadProductCard>
        <UploadProductCard heading="Edit Product Images">
          <ProductEditImagePreview
            handleUploadConfirm={handleUploadConfirm}
            catalogueImage={initialProductData.current?.catalogueImage}
            cropFrontView={initialProductData.current?.cropFrontView}
            cropBackView={initialProductData.current?.cropBackView}
            detailView={initialProductData.current?.detailView}
            packshotView={initialProductData.current?.packshotView}
            imageEditCallBack={(name, required) =>
              required &&
              setRequiredImages((requiredImages) => [...requiredImages, name])
            }
          />
        </UploadProductCard>
        <UploadProductCard heading="Stock on Hand vs Make To Order">
          <Paragraph>
            We understand that estimating demand for clothing items is
            difficult. That's why we offer make-to-order functionality.
          </Paragraph>
          <Paragraph>
            <strong>Stock on hand:</strong> Some variations of the described
            product have already been manufactured and can be delivered
            immediately.
          </Paragraph>
          <Paragraph>
            <strong>Make to order:</strong> Some variations of the described
            product have not yet been manufactured and will take a bit longer to
            deliver.
          </Paragraph>
          <Paragraph>
            <strong>Lead time:</strong> An estimate of the number of days to
            manufacture the item. We will communicate this to the customer.
          </Paragraph>
        </UploadProductCard>

        <StoVariants
          defaultVariantData={
            (initialProductData.current?.variants as IVariantCombine[]) || []
          }
          parent="edit"
          setShowVariants={(variant) => setShowVariants(variant)}
          showVariants={
            initialProductData.current?.variants ? true : showVariants
          }
          handleUpdateVariants={handleUpdateVariants}
        />
        <MtoVariants
          defaultVariantData={
            (initialProductData.current
              ?.makeToOrderVariants as IVariantCombine[]) || []
          }
          parent="edit"
          setShowVariants={(variant) => setShowMakeToOrder(variant)}
          showVariants={
            initialProductData.current?.makeToOrderVariants
              ? true
              : showMakeToOrder
          }
          handleUpdateVariants={handleUpdateVariants}
        />
      </ContentArea>
      <Flex
        backgroundColor={theme.colors.background}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        p={theme.padding.standard}
        borderRadius="0"
        m="0"
      >
        <Button
          mr="auto"
          backgroundColor={theme.colors.halfDark}
          variant="flat"
          onClick={() => productPageToDisplay({ type: 'LIST' })}
        >
          Back
        </Button>
        {error.length > 0 && (
          <Paragraph variant="input_error" color="red">
            {error}
          </Paragraph>
        )}
        <Button
          backgroundColor={theme.colors.halfDark}
          variant="flat"
          onClick={handleDiscard}
        >
          Discard Changes
        </Button>

        <Button
          isLoading={isLoading || isFetching}
          backgroundColor={
            productIsModified || imageIsModified
              ? theme.colors.primaryCta
              : theme.colors.darkGreyStandard
          }
          variant="flat"
          onClick={() => submit(handleUploadProduct)}
          disabled={!productIsModified && !imageIsModified}
          color={
            productIsModified || imageIsModified
              ? theme.colors.white
              : theme.colors.dark
          }
        >
          Save Product
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditProduct;
