export function provideTagList<
  R extends { _id: string | number }[],
  T extends string
>(resultsWithIds: R | undefined, tagType: T) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ _id }) => ({ type: tagType, id: _id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

export function provideOrdersTagList<R, T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  if (resultsWithIds instanceof Array) {
    return resultsWithIds
      ? [
          { type: tagType, id: 'LIST' },
          ...resultsWithIds.map(({ _id }) => ({ type: tagType, id: _id })),
        ]
      : [{ type: tagType, id: 'LIST' }];
  }
  return [{ type: tagType, id: 'LIST' }];
}
