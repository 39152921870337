/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import Paragraph from '../../standard-components/typography/paragraph';
import FormWrapper from '../../form-wrapper';
import Card from '../../standard-components/elements/card';
import patterns from '../../../constants/validation-regex';
import OnboardingInputField from '../../onboarding-input';
import { SinglePDFDropzone } from '../../dropzone';
import { OnboardingContext } from '../../../context/onboarding/OnboardingContext';
import Heading from '../../standard-components/typography/heading';

const FinancialDetails = ({ register, errors }: any) => {
  const { onboardingDispatch } = useContext(OnboardingContext)!;
  const handleUploadConfirm = (document: any) => {
    onboardingDispatch({
      type: 'document_upload',
      payload: {
        key: 'proofOfBankDetails',
        data: document,
      },
    });
  };
  return (
    <Card heading="Financial Details">
      <Paragraph>
        We will deposit the money from your sales into this account.
      </Paragraph>
      <FormWrapper columns={4}>
        <OnboardingInputField
          label="Bank Name *"
          placeholder="Enter Bank Name"
          error={errors.bankname}
          {...register('bankName', { required: false })}
        />
        <OnboardingInputField
          label="Account Number *"
          placeholder="Enter Account Number"
          error={errors.accountNumber}
          {...register('accountNumber', { required: false })}
        />
        <OnboardingInputField
          label="Branch Code *"
          placeholder="000000"
          error={errors.branchCode}
          {...register('branchCode', { required: false })}
        />
        <OnboardingInputField
          label="Account Holder *"
          placeholder="Enter Account Holder Name"
          error={errors.accountHolder}
          {...register('accountHolder', { required: false })}
        />
      </FormWrapper>
      <Paragraph>If you have a VAT number, please enter it here.</Paragraph>
      <FormWrapper columns={1}>
        <OnboardingInputField
          label="VAT Number"
          placeholder=""
          {...register('vatNumber')}
        />
      </FormWrapper>
      <Heading variant="card_heading" level="sub" m={0} p={0}>
        Upload Proof of Banking Details
      </Heading>
      <FormWrapper columns={1}>
        <SinglePDFDropzone onUploadConfirm={handleUploadConfirm} />
      </FormWrapper>
    </Card>
  );
};

export default FinancialDetails;
