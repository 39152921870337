const omit = (
  object: { [key: string]: any },
  keys: Array<string>
): { [key: string]: any } => {
  const ret: { [key: string]: any } = {};

  for (const key in object) {
    if (!keys.includes(key)) {
      ret[key] = object[key];
    }
  }

  return ret;
};

export default omit;
