import { appApi } from '../app-api';
import { provideTagList } from '../util';
import { TGetAllProductsResponse, IProductSearchParams } from './product-types';

export const productApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query<TGetAllProductsResponse, string | null>({
      query: (storeID: string) => `/search/products?storeId=${storeID}`,
      providesTags: (response) => provideTagList(response?.result, 'Products'),
    }),
    searchProucts: builder.mutation<
      TGetAllProductsResponse,
      Partial<IProductSearchParams>
    >({
      query: (payload: IProductSearchParams) => {
        return {
          url: `search/products?storeId=${payload.storeID}&search=${payload.search}`,
          method: 'get',
        };
      },
    }),
    uploadProducts: builder.mutation({
      queryFn: (payload) => {
        return {
          data: payload,
        };
      },
    }),

    deleteProduct: builder.mutation({
      queryFn: async (
        payload: { _id: string },
        _api,
        _extraOptions,
        fetchWithBQ
      ) => {
        try {
          const response = await fetchWithBQ({
            method: 'delete',
            url: `products?id=${payload._id}`,
          });
          if (response.error) throw Error('Delete Failed');
          return {
            data: payload,
          };
        } catch (error) {
          return {
            error: { status: 500, error: 'Delete Failed', data: undefined },
          };
        }
      },
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useSearchProuctsMutation,
  useUploadProductsMutation,
  useDeleteProductMutation,
} = productApi;
