import {
  ChangeEvent,
  EventHandler,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { KeyValue } from '../types/input-document-type';

const useInput = (_defaultValue?: { [key: string]: string }) => {
  const [values, setValues] = useState<{
    [key: string]: string;
  }>({});
  const [defaultValue, setDefaultValues] = useState(_defaultValue);
  const [isTouched, setIsTouched] = useState<KeyValue<boolean>>({});
  const [hasBeenTouched, setHasBeenTouched] = useState<KeyValue<boolean>>({});
  const [validationErrors, setValidationErrors] = useState<KeyValue<string>>(
    {}
  );
  let _errors = useRef<KeyValue<KeyValue<any>>>({});

  useEffect(() => {
    setDefaultValues(_defaultValue);
  }, []);

  useEffect(() => {
    Object.keys(_errors.current).forEach((inputName) => {
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        [inputName]: '',
      }));
      if (Object.keys(_errors.current[inputName]).length > 0) {
        setIsTouched({ [inputName]: false });
        setHasBeenTouched((hasBeenTouched) => ({
          ...hasBeenTouched,
          [inputName]: false,
        }));
      }
    });
    defaultValue && setValues(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateOnClick = () => {
    const unValidatedFields = Object.keys(_errors.current).filter(
      (el) => !Object.keys(values).includes(el)
    );
    let errorMessage = '';
    const onclickValidationErrors: KeyValue<string> = {};

    unValidatedFields.forEach((inputFieldName) => {
      Object.keys(_errors.current[inputFieldName]).forEach((rule) => {
        switch (rule) {
          case 'min':
            if (values[inputFieldName]) {
              errorMessage =
                values[inputFieldName].length <
                _errors.current[inputFieldName][rule]
                  ? `Too few characters ${_errors.current[inputFieldName][rule]}`
                  : '';
            } else {
              errorMessage = _errors.current[inputFieldName][rule]
                ? `Too few characters ${_errors.current[inputFieldName][rule]}`
                : '';
            }
            break;
          case 'max':
            if (values[inputFieldName]) {
              errorMessage =
                values[inputFieldName].length >
                _errors.current[inputFieldName][rule]
                  ? `Too many characters ${_errors.current[inputFieldName][rule]}`
                  : '';
            } else {
              errorMessage = _errors.current[inputFieldName][rule]
                ? `Too many characters ${_errors.current[inputFieldName][rule]}`
                : '';
            }
            break;
          case 'required':
            if (values[inputFieldName]) {
              errorMessage =
                _errors.current[inputFieldName][rule] &&
                values[inputFieldName].length < 1
                  ? `Field can't be empty`
                  : '';
            } else {
              errorMessage = _errors.current[inputFieldName][rule]
                ? `Field can't be empty`
                : '';
            }

            break;
          case 'pattern':
            if (values[inputFieldName]) {
              errorMessage = !_errors.current[inputFieldName][rule].test(
                values[inputFieldName]
              )
                ? `Something's wrong here`
                : '';
            } else {
              errorMessage = _errors.current[inputFieldName][rule]
                ? `Something's wrong here`
                : '';
            }

            break;
          default:
            throw new Error(`Input Validation case not known for:${rule} `);
        }

        onclickValidationErrors[inputFieldName] = errorMessage;
      });
    });

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      ...onclickValidationErrors,
    }));

    return onclickValidationErrors;
  };
  const submit = async (
    callback: () => Promise<void>,
    fieldsToExclude?: string[]
  ) => {
    const onclickErrors = validateOnClick();
    const errorsTovalidate = excludeFields(
      { ...validationErrors, ...onclickErrors },
      fieldsToExclude || []
    );
    const valid = Object.values(errorsTovalidate as KeyValue<string>).every(
      (error) => error.length < 1
    );
    if (valid) {
      try {
        await callback();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const excludeFields = (
    originalObject: KeyValue<unknown>,
    fieldsToExclude: string[]
  ): KeyValue<unknown> => {
    return Object.keys(originalObject)
      .filter((key: string) => fieldsToExclude.every((val) => val !== key))
      .reduce((obj, key) => {
        obj[key] = originalObject[key];
        return obj;
      }, {} as KeyValue<unknown>);
  };
  const handleChange: EventHandler<ChangeEvent<HTMLInputElement>> = (event) => {
    const { name, value } = event.target;
    let errorMessage = '';

    Object.keys(_errors.current[name]).forEach((rule) => {
      switch (rule) {
        case 'min':
          errorMessage =
            value.length < _errors.current[name][rule]
              ? `Too few characters ${_errors.current[name][rule]}`
              : '';
          break;
        case 'max':
          errorMessage =
            value.length > _errors.current[name][rule]
              ? `Too many characters ${_errors.current[name][rule]}`
              : '';
          break;
        case 'required':
          errorMessage =
            _errors.current[name][rule] && value.length < 1
              ? `Field can't be empty`
              : '';
          break;
        case 'pattern':
          errorMessage = !_errors.current[name][rule].test(value)
            ? `Something's wrong here`
            : '';
          break;
        default:
          throw new Error(`Input Validation case not known for:${rule} `);
      }

      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        [name]: errorMessage,
      }));
      setHasBeenTouched((hasBeenTouched) => ({
        ...hasBeenTouched,
        ...isTouched,
      }));
    });

    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  };
  const handleFocus: EventHandler<FocusEvent<HTMLInputElement>> = (event) => {
    setIsTouched({ [event.target.name]: true });
  };
  const handleBlur: EventHandler<FocusEvent<HTMLInputElement>> = (event) => {
    const { name, value } = event.target;
    const required =
      _errors.current[name]['required'] && isTouched[name] && value.length < 1
        ? `Field can't be empty`
        : validationErrors[name] ?? '';

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      [name]: required,
    }));

    setHasBeenTouched((hasBeenTouched) => ({
      ...hasBeenTouched,
      ...isTouched,
    }));
  };
  const checkHasBeenBlurred = () => {
    const _hasBeenTouched =
      Object.values(isTouched).length > 0 &&
      Object.values(hasBeenTouched).every((value) => value);

    return _hasBeenTouched;
  };
  const register = (
    inputName: string,
    options?: {
      min?: number;
      max?: number;
      pattern?: RegExp;
      required?: boolean;
    }
  ) => {
    if (options) {
      _errors.current[inputName] = { ...options };
    } else {
      _errors.current[inputName] = { required: false };
    }

    return {
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
      name: inputName,
    };
  };
  const handleResetDefaults = () => {
    if (defaultValue) {
      setValues(defaultValue);
    } else {
      const _defaultValue = Object.keys(values).reduce(
        (prev, current: string) => ({ ...prev, [current]: '' }),
        {}
      );
      setValues(_defaultValue);
    }

    _errors.current = {};
    setValidationErrors({});
  };

  const handleUpdateDefaults = () => {
    if (values) {
      setDefaultValues(values);
    }

    _errors.current = {};
    setValidationErrors({});
  };

  return {
    reset: () => {
      _errors.current = {};
      setValidationErrors({});
    },
    resetDefaults: handleResetDefaults,
    updateDefaults: handleUpdateDefaults,
    register,
    errors: validationErrors,
    values,
    submit,
    hasBeenTouched: checkHasBeenBlurred(),
  };
};

export default useInput;
