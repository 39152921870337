import StandardProps from '../../theme/standard-props';
import { Flex } from '../standard-components/layout/flex-box';
import theme from '../../theme/theme';
import Footer from '../standard-components/elements/footer';
import { css } from '@emotion/react';

const OnboardingPageLayout = ({
  heading,
  disabled,
  children,
  isLoading,
  isLastPage,
  isFirstPage,
  onClick,
}: {
  children?: any;
  heading?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isLastPage?: boolean;
  isFirstPage?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & StandardProps) => (
  <Flex
    backgroundColor={theme.colors.veryLightGrey}
    flexDirection="column"
    borderRadius={0}
    addCss={css`
      min-height: 100vh;
      min-width: 100vw;
      margin: 0;
    `}
    p={0}
  >
    <Flex
      overflow="hidden"
      flexDirection="row"
      borderRadius="0"
      flex="1"
      m="0"
      p="0"
    >
      {children}
    </Flex>
    {isFirstPage && (
      <Footer
        buttonText="START YOUR FREE TRIAL"
        type="submit"
        onClick={onClick}
        disabled={disabled}
      />
    )}
    {isLastPage && (
      <Footer
        buttonText="WELCOME TO VINCII"
        type="submit"
        onClick={onClick}
        disabled={disabled}
        isLoading={isLoading}
      />
    )}
    {!isFirstPage && !isLastPage && (
      <Footer
        buttonText="NEXT"
        type="submit"
        onClick={onClick}
        disabled={disabled}
      />
    )}
  </Flex>
);

export default OnboardingPageLayout;
