import { appApi } from '../app-api';
import { IRetailerStoreResponse } from '../onboarding/onboarding-types';

export const retailerStoreApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getStoreOverview: builder.query<IRetailerStoreResponse, string>({
      query: (requestID) => {
        return {
          url: 'retailer-stores',
          method: 'get',
          params: {
            id: requestID,
          },
        };
      },
    }),
    getStoreDetails: builder.query<any, string>({
      query: (requestID) => {
        return {
          url: 'retailer-details',
          method: 'get',
          params: {
            id: requestID,
          },
        };
      },
    }),
  }),
});

export const { useGetStoreOverviewQuery, useGetStoreDetailsQuery } =
  retailerStoreApi;
