import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const PrivateRoute = ({
  role,
  children,
  fallbackTo,
}: {
  children?: any;
  fallbackTo: string;
  role: 'user' | 'client' | 'verification' | 'retailer';
}) => {
  const { user, pendingUser, verifiedStatus, retailer } = useAuth();
  if (role === 'user') {
    const expiryTime = localStorage.getItem('expiration')
      ? parseInt(localStorage.getItem('expiration')!)
      : 0;
    if (Date.now() > expiryTime) {
      //Token has expired
      localStorage.clear();
      return <Navigate to={fallbackTo} />;
    }
    if (retailer) {
      return <Navigate to={`/my-shop/products/${retailer}`} />;
    }
    return user ? children : <Navigate to={fallbackTo} />;
  } else if (role === 'client') {
    return pendingUser ? children : <Navigate to={fallbackTo} />;
  } else if (role === 'retailer') {
    return retailer ? children : <Navigate to={fallbackTo} />;
  } else {
    return verifiedStatus === 'pending' ? (
      children
    ) : (
      <Navigate to={fallbackTo} />
    );
  }
};

export default PrivateRoute;
