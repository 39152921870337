export const OnboardingDocuments = {
  retailerVerification: {
    prefix: 'retailer-verification-documents',
    bucket: process.env.REACT_APP_AWS_S3_RETVALDOCS_BUCKET,
    name: 'retailer-verification-documents',
  },
  proofOfBankDetails: {
    prefix: 'proof-of-bank-details',
    bucket: process.env.REACT_APP_AWS_S3_RETVALDOCS_BUCKET,
    name: 'proof-of-bank-details',
  },
  sizingGuide: {
    prefix: 'store-sizing-guides',
    bucket: process.env.REACT_APP_AWS_S3_PUBLIC_BUCKET,
    name: 'store-sizing-guides',
  },
};
