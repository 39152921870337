import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { appApi } from '../app-api';
import { IRetailerStoreResponse } from '../onboarding/onboarding-types';
import {
  IRetailerInformation,
  IUpdateManageStoreDetails,
  IUpdateRetailerRequest,
} from './types';

export const retailerStoreApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRetailerAccountInformation: builder.mutation<
      any,
      IUpdateRetailerRequest
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const retailerStores = await fetchWithBQ({
          method: 'put',
          url: 'retailer-stores',
          params: {
            id: _arg.storeID,
          },
          body: {
            storeAddress: {
              province: _arg.data.shopSuburb,
              city: _arg.data.shopCity,
            },
          },
        });

        if (retailerStores.error)
          return { error: retailerStores.error as FetchBaseQueryError };
        const retailerDetails = await fetchWithBQ({
          method: 'put',
          url: `retailer-details`,
          params: {
            id: _arg.retailerDetailsID,
          },
          body: {
            address: {
              streetNumber: _arg.data.shopStreetNumber,
              suburb: _arg.data.shopSuburb,
              city: _arg.data.shopCity,
              zipCode: _arg.data.shopZipCode,
            },
            shopContactNumber: _arg.data.shopContactNumber,
            shopEmail: _arg.data.shopEmail,
            financialDetails: {
              bankName: _arg.data.bankName,
              accountNumber: _arg.data.accountNumber,
              branchCode: _arg.data.branchCode,
              accountHolder: _arg.data.accountHolder,
            },
          },
        });
        if (retailerDetails.error)
          return { error: retailerDetails.error as FetchBaseQueryError };

        return {
          data: {
            status: 'success',
          },
          error: undefined,
        };
      },
    }),

    updateRetailerManageStore: builder.mutation<
      any,
      IUpdateRetailerRequest<IUpdateManageStoreDetails>
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const retailerStores = await fetchWithBQ({
          method: 'put',
          url: 'retailer-stores',
          params: {
            id: _arg.storeID,
          },
          body: {
            storeName: _arg.data.shopName,
            status: _arg.data.status,
            website: _arg.data.website,
            instagram: _arg.data.instagram,
            facebook: _arg.data.instagram,
            storeBackground: _arg.data.storeBackground,
            storeBio: _arg.data.storeBio,
            useBrandColour: _arg.data.useBrandColour,
          },
        });
        if (retailerStores.error)
          return { error: retailerStores.error as FetchBaseQueryError };
        const retailerDetails = await fetchWithBQ({
          method: 'put',
          url: `retailer-details`,
          params: {
            id: _arg.retailerDetailsID,
          },
          body: {
            shopContactNumber: _arg.data.shopContactNumber,
            shopEmail: _arg.data.shopEmail,
          },
        });
        if (retailerDetails.error)
          return { error: retailerDetails.error as FetchBaseQueryError };

        return {
          data: {
            status: 'success',
          },
          error: undefined,
        };
      },
    }),
    getManageStore: builder.query<IRetailerInformation, string>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeID, retailerDetailsID } = JSON.parse(_arg);

        const retailerStores = await fetchWithBQ({
          method: 'get',
          url: 'retailer-stores',
          params: {
            id: storeID,
          },
        });
        if (retailerStores.error)
          return { error: retailerStores.error as FetchBaseQueryError };
        const retailerDetails = await fetchWithBQ({
          method: 'get',
          url: `retailer-details`,
          params: {
            id: retailerDetailsID,
          },
        });
        if (retailerDetails.error)
          return { error: retailerDetails.error as FetchBaseQueryError };

        const result = {
          data: {
            ...(retailerStores.data as any),
            ...(retailerDetails.data as any),
          },
        };
        return result;
      },
    }),

    getStoreOverview: builder.query<IRetailerStoreResponse, string>({
      query: (requestID) => {
        return {
          url: 'retailer-stores',
          method: 'get',
          params: {
            id: requestID,
          },
        };
      },
    }),
    getStoreDetails: builder.query<any, string>({
      query: (requestID) => {
        return {
          url: 'retailer-details',
          method: 'get',
          params: {
            id: requestID,
          },
        };
      },
    }),
  }),
});

export const {
  useGetStoreOverviewQuery,
  useGetStoreDetailsQuery,
  useLazyGetManageStoreQuery,
  useUpdateRetailerManageStoreMutation,
  useUpdateRetailerAccountInformationMutation,
} = retailerStoreApi;
