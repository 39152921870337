/** @jsxImportSource @emotion/react */
import Grid from '../../standard-components/layout/grid';
import Paragraph from '../../standard-components/typography/paragraph';
import { TiArrowUnsorted } from 'react-icons/ti';
import { css } from '@emotion/react';
import theme from '../../../theme/theme';
import Button from '../../standard-components/elements/button';
import useHttp from '../../../hooks/useHttp';
import { Dialog } from '@material-ui/core';
import { useState } from 'react';
import Heading from '../../standard-components/typography/heading';

const OrderHeaderRowItem = ({
  name,
  item,
  handleOnClick,
}: {
  name: string;
  item: string;
  handleOnClick: (name: string) => void;
}) => {
  return (
    <Paragraph
      borderRadius="0"
      m="0"
      px="0"
      variant="grid_header_row"
      addCss={css`
        &:hover {
          cursor: pointer;
          background-color: ${theme.colors.lineGrey};
        }
      `}
    >
      <div
        onClick={() => handleOnClick(name)}
        css={css`
          height: 100%auto;
          display: flex;
          justify-content: center;
        `}
      >
        <span
          css={css`
            margin-right: 5px;
          `}
        >
          {item}
        </span>
        <span>
          <TiArrowUnsorted size=".8rem" />
        </span>
      </div>
    </Paragraph>
  );
};
const OrderHeaderRow = ({
  handleSortParameter,
  detailsFK,
}: {
  handleSortParameter: (name: string) => void;
  detailsFK?: string;
}) => {
  const { post, isLoading: HTTPIsLoading } = useHttp(
    `${process.env.REACT_APP_API_HOST}`
  );
  const [payoutResponse, setPayoutResponse] = useState<string | null>(null);
  return (
    <>
      <Dialog open={!!payoutResponse} onClose={() => setPayoutResponse(null)}>
        <Heading variant="card_item_heading">Payout Request Submitted.</Heading>
        <Paragraph>
          {payoutResponse}. Payouts occur a minimum of 3 days after the item has
          been delivered. Click anywhere to dismiss this message.
        </Paragraph>
      </Dialog>
      <Grid
        m="0"
        gridColumnGap="1.5rem"
        gridTemplateColumns="repeat(6, 1fr) 8rem"
        alignItems="center"
        pb="0"
      >
        <OrderHeaderRowItem
          handleOnClick={handleSortParameter}
          item="Ref Code"
          name="referenceNumber"
        />

        <OrderHeaderRowItem
          handleOnClick={handleSortParameter}
          item="Order Date"
          name="timestamp.date"
        />

        <OrderHeaderRowItem
          handleOnClick={handleSortParameter}
          item="Status"
          name="status"
        />
        <OrderHeaderRowItem
          handleOnClick={handleSortParameter}
          item="No. of Items"
          name="products.length"
        />
        <OrderHeaderRowItem
          handleOnClick={handleSortParameter}
          item="Total"
          name="orderTotal"
        />
        <Paragraph
          m="0"
          p="0"
          variant="grid_header_row"
          justifySelf="flex-start"
        >
          Waybill
        </Paragraph>
        <Button
          backgroundColor={theme.colors.primaryCta}
          border="none"
          isLoading={HTTPIsLoading}
          disabled={!detailsFK}
          onClick={() => {
            post({
              url: `/retailer-payout?detailsFK=${detailsFK}`,
              data: null,
              options: null,
            })
              .then((res) => setPayoutResponse(res.msg))
              .catch((err) => console.log(err));
          }}
        >
          PAYOUT
        </Button>
        {/* <Button
        backgroundColor={theme.colors.primaryCta}
        border="none"
        isLoading={HTTPIsLoading}
      >
        TESTING
      </Button> */}
      </Grid>
    </>
  );
};

export default OrderHeaderRow;
