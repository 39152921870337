import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { onboardingApi } from '../onboarding/onboarding-api';
import { authApi } from './auth-api';
import { IAuthState, IRegisterResponse, ILoginResponse } from './auth-types';

const initialState: IAuthState = {
  verified_status: null,
  email: null,
  token: localStorage.getItem('token') || '',
  retailerDetailsID: localStorage.getItem('retailerDetailsID') || '',
  user: null,
  storeName: localStorage.getItem('storeName') || null,
  retailer: localStorage.getItem('retailer') || null,
  timeToTokenExpiry: localStorage.getItem('expiration') || null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.signinUser.matchFulfilled,
        (state, { payload }: PayloadAction<ILoginResponse>) => {
          localStorage.clear();

          state.token = null;
          state.verified_status = null;
          state.user = null;
          state.storeName = null;
          state.retailer = null;
          state.timeToTokenExpiry = null;
          state.retailerDetailsID = null;

          const timeToTokenExpiry = Date.now() + payload.expiresIn * 1000;
          localStorage.setItem('token', payload.token);
          localStorage.setItem('expiration', `${timeToTokenExpiry}`);
          payload.msg.retailerStore &&
            localStorage.setItem('retailer', payload.msg.retailerStore);
          payload.msg.retailerStoreName &&
            localStorage.setItem('storeName', payload.msg.retailerStoreName);
          payload.msg.retailerDetails &&
            localStorage.setItem(
              'retailerDetailsID',
              payload.msg.retailerDetails
            );

          state.timeToTokenExpiry = `${timeToTokenExpiry}`;
          state.token = payload.token;
          state.verified_status = 'active';
          state.retailer = payload.msg.retailerStore ?? null;
          state.retailerDetailsID = payload.msg.retailerDetails ?? null;
          state.storeName = payload.msg.retailerStoreName ?? null;
          state.user = {
            firstName: payload.msg.firstName,
            lastName: payload.msg.lastName,
            email: payload.msg.email,
            addresses: payload.msg.addresses,
            cart: payload.msg.cart,
            wishlist: payload.msg.wishlist,
          };
        }
      )
      .addMatcher(
        authApi.endpoints.signinUser.matchRejected,
        (state, { payload }: PayloadAction<ILoginResponse>) => {
          localStorage.removeItem('token');
          localStorage.removeItem('expiration');
          localStorage.removeItem('retailer');
          localStorage.removeItem('storeName');
          localStorage.removeItem('retailerDetailsID');

          state.token = null;
          state.verified_status = null;
          state.user = null;
          state.storeName = null;
          state.timeToTokenExpiry = null;
          state.retailerDetailsID = null;
        }
      )
      .addMatcher(
        authApi.endpoints.signupUser.matchRejected,
        (state, { payload }: PayloadAction<ILoginResponse>) => {
          state.token = null;
          state.verified_status = null;
          state.user = null;
        }
      )
      .addMatcher(
        authApi.endpoints.signupUser.matchFulfilled,
        (state, { payload }: PayloadAction<IRegisterResponse>) => {
          state.verified_status = payload.msg.verified_status;
          state.user = {
            firstName: payload.msg.firstName,
            lastName: payload.msg.lastName,
            email: payload.msg.email,
            addresses: payload.msg.addresses,
            cart: payload.msg.cart,
            wishlist: payload.msg.wishlist,
          };
        }
      )
      .addMatcher(
        onboardingApi.endpoints.updateRetailerDetails.matchFulfilled,
        (state, { payload }) => {
          state.retailer = payload.msg.retailerStore;
        }
      )
      .addMatcher(authApi.endpoints.signout.matchFulfilled, (state) => {
        localStorage.clear();

        state.token = null;
        state.verified_status = null;
        state.user = null;
        state.storeName = null;
        state.timeToTokenExpiry = null;
        state.retailerDetailsID = null;
      });
  },
});

export const selectCurrentUser = (state: RootState) => state.auth.token;
export const selectRetailerVerifyToken = (state: RootState) =>
  state.auth.retailerDetailsID;
export const selectPendingVerificationUser = (state: RootState) => ({
  user: state.auth.user?.email,
  verifiedStatus: state.auth.verified_status,
  retailer: state.auth.retailer,
  storeName: state.auth.storeName,
  timeToTokenExpiry: state.auth.timeToTokenExpiry,
  retailerDetailsID: state.auth.retailerDetailsID,
});

export default authSlice.reducer;
