import Card from '../standard-components/elements/card';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import Button from '../standard-components/elements/button';
import theme from '../../theme/theme';

const Marketing = () => (
  <Flex flexDirection="column" overflowY="hidden" m="0" width="100%">
    <Card heading="Marketing Support">
      <Paragraph>
        Have a new product line and you need a photo shoot? Perhaps you'd like
        some advice with brand strategy?
        <strong> We are here to help.</strong>
      </Paragraph>
      <Paragraph>
        We have a network of content and marketing experts on call.
      </Paragraph>
      <Paragraph>
        <strong>Unlock the power of your brand.</strong> Click below to get in
        touch with our marketing team.
      </Paragraph>
      <a
        href="https://app.pipefy.com/public/form/vWHq0UAK"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          color={theme.colors.primaryCta}
          fontSize={theme.fontSizes.medium}
        >
          MARKETING
        </Button>
      </a>
    </Card>
  </Flex>
);

export default Marketing;
