export enum ETargetGroup {
  men = 'Men',
  women = 'Women',
  unisex = 'Unisex',
  kids = 'Kids',
  babies = 'Babies',
}

export enum EPurpose {
  festival = 'Festival',
  casual = 'Casual',
  streetwear = 'Streetwear',
  formal = 'Formal',
  sleep = 'Sleep',
  sports = 'Sports',
  beach = 'Beach',
}

export enum EApparelType {
  tops = 'Tops',
  shorts = 'Shorts',
  dresses = 'Dresses',
  footwear = 'Footwear',
  trousers = 'Trousers',
  hats = 'Hats',
  socks = 'Socks',
  belts = 'Belts',
  wallets = 'Wallets',
  purses = 'Purses',
  handBag = 'Hand Bags',
  ties = 'Ties',
  scarves = 'Scarves',
  skirts = 'Skirts',
  fullTracksuits = 'Full tracksuits',
  tracksuitBottoms = 'Tracksuit-bottoms',
  tracksuitTops = 'Tracksuit-tops',
}

export enum EColour {
  black = 'Black',
  brown = 'Brown',
  grey = 'Grey',
  white = 'White',
  blue = 'Blue',
  green = 'Green',
  purple = 'Purple',
  orange = 'Orange',
  yellow = 'Yellow',
  red = 'Red',
  pink = 'Pink',
  multi = 'Multi-colored',
  cream = 'Cream',
  offWhite = 'Off-white',
}

export enum EFabric {
  braided = 'Braided',
  cashmere = 'Cashmere',
  cashmere100 = 'Cashmere (100%)',
  chiffon = 'Chiffon',
  cord = 'Cord',
  cotton = 'Cotton',
  cotton100 = 'Cotton (100%)',
  crocheted = 'Crocheted',
  denim = 'Denim',
  feathers = 'Feathers',
  flannelette = 'Flannelette',
  fleece = 'Fleece',
  hardshell = 'Hardshell',
  imitationLeather = 'Imitation leather',
  jersey = 'Jersey',
  lace = 'Lace',
  leather = 'Leather',
  linen = 'Linen',
  linen100 = 'Linen (100%)',
  lyocell = 'Lyocell',
  mesh = 'Mesh',
  mohair = 'Mohair',
  other = 'Other',
  polyester = 'Polyester',
  ribbed = 'Ribbed',
  satin = 'Satin',
  sequins = 'Sequins',
  silk = 'Silk',
  silk100 = 'Silk (100%)',
  softshell = 'Softshell',
  sweat = 'Sweat',
  synthetic = 'Synthetic',
  textile = 'Textile',
  viscose = 'Viscose',
  viscose100 = 'Viscose (100%)',
  wool = 'Wool',
  wool100 = 'Wool (100%)',
}

export interface ISize {
  EU?: string;
  UK: string;
  US?: string;
}
export interface ICommodityGroup {
  targetGroup: Array<ETargetGroup>;
  purpose: Array<EPurpose>;
  apparelType: EApparelType;
}

export interface IVariant {
  colour: string;
  fabric: string;
  quantity?: string;
  size: ISize;
}
export interface IMTOVariant {
  colour: string[];
  fabric: string;
  leadTime?: string;
  size: string;
}

export interface IReview {
  rating: number;
  description: string;
  timeStamp: string;
}

export interface IProduct {
  productName: string;
  variants?: IVariant[];
  storeName?: string;
  catalogueImage?: string;
  cropFrontView?: string;
  cropBackView?: string;
  detailView?: string;
  packshotView?: string;
  productPrice: number | string;
  primaryTag?: string;
  productDescription: string;
  storeFK: string;
  resellerID?: string;
  reviews?: IReview;
  commodityGroups: ICommodityGroup;
  tags?: string;
  makeToOrderVariants?: IMTOVariant[];
}
