import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Tabs } from '../../pages/my-shop';
import { useLazyGetManageStoreQuery } from '../../store/retailer-store/retailer-store-api';
import ContactUs from './contact-us';
import Feedback from './feedback';
import Orders from './orders';
import Products from './products';
import Manufacturing from './manufacturing';
import Marketing from './marketing';

import AccountDetails from './retailer-store/account-details';
import ManageStore from './retailer-store/manage-store';
import { ContactSupportOutlined } from '@material-ui/icons';
const MyShopContent = ({ activeTab }: { activeTab: Tabs }) => {
  const { storeID } = useParams();

  const { retailerDetailsID } = useAuth();
  const [refetch, setRefetch] = useState(false);
  const [fetchData, { data }] = useLazyGetManageStoreQuery();
  useEffect(() => {
    if (retailerDetailsID) {
      fetchData(
        JSON.stringify({
          retailerDetailsID,
          storeID,
        })
      );
    }
  }, [retailerDetailsID, storeID, fetchData, refetch]);
  console.log('Other', data);
  if (storeID) {
    switch (activeTab) {
      case 'products':
        return <Products storeID={storeID} />;
      case 'orders':
        return <Orders storeID={storeID} />;
      case 'account-details':
        return (
          <AccountDetails
            storeID={storeID}
            retailerDetailsID={retailerDetailsID}
            defaultData={data}
            refetch={() => setRefetch((currentState) => !currentState)}
          />
        );
      case 'manage-store':
        return (
          <ManageStore
            storeID={storeID}
            retailerDetailsID={retailerDetailsID}
            defaultData={data}
            refetch={() => setRefetch((currentState) => !currentState)}
          />
        );
      case 'feedback':
        return <Feedback />;
      case 'contact-us':
        return <ContactUs />;

      case 'manufacturing':
        return <Manufacturing />;
      case 'marketing':
        return <Marketing />;
      default:
        return <Products storeID={storeID} />;
    }
  } else {
    <Navigate to="/store-not-found" />;
  }
  return <></>;
};

export default MyShopContent;
