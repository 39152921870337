import Card from '../standard-components/elements/card';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';
import Button from '../standard-components/elements/button';
import theme from '../../../src/theme/theme';

const Manufacturing = () => (
  <Flex flexDirection="column" overflowY="hidden" m="0" width="100%">
    <Card heading="Manufacturing Support">
      <Paragraph>
        Overwhelmed with scaling your in-house manufacturing? Perhaps you've
        received a lot of orders recently?
        <strong> We are here to help.</strong>
      </Paragraph>
      <Paragraph>
        Together with our partners, we handle everything from fabric sourcing to
        production and shipping.
      </Paragraph>
      <Paragraph>
        <strong> Your designs, your brand, your customers</strong>. We take care
        of the rest.
      </Paragraph>
      <a
        href="https://app.pipefy.com/public/form/ESUIdcD_"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          color={theme.colors.primaryCta}
          fontSize={theme.fontSizes.medium}
        >
          MANUFACTURING
        </Button>
      </a>
    </Card>
  </Flex>
);

export default Manufacturing;
