/** @jsxImportSource @emotion/react */
import theme from '../../theme/theme';
import { css } from '@emotion/react';
import LandingPage from '../../components/landing-page-layout/landing-page';
import { useSignupUserMutation } from '../../store/auth/auth-api';
import useInput from '../../hooks/useInput';
import patterns from '../../constants/validation-regex';
import { useNavigate } from 'react-router-dom';
import ErrorHandler from '../../utils/formSubmitErrorHandler';
import ErrorMessage from '../../components/standard-components/elements/error';
import InputField from '../../components/standard-components/elements/input-field';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import Paragraph from '../../components/standard-components/typography/paragraph';
import ParagraphLink from '../../components/standard-components/typography/paragraph-link';
import Form from '../../components/standard-components/elements/form';
import Button from '../../components/standard-components/elements/button';

const Signup = () => {
  const navigate = useNavigate();
  const [signupUser, { isLoading, error }] = useSignupUserMutation();
  const { values, submit, errors, register } = useInput();

  const handleOnFormSubmit = async () => {
    console.log('here');
    await signupUser({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    }).unwrap();
    console.log('here');
    navigate('/send-verify-mail');
  };
  return (
    <LandingPage
      heroImage="landing-hero.jpg"
      heroTitle="Become a Vincii Retailer"
      heroSubTitle="Join the community Today."
    >
      <Flex
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Get Started
        </Heading>
        <Heading variant="hero_sub_heading" level="sub" mt="0" mb="0">
          Create Your Account
        </Heading>
        {/* ------------------------------------------------------Single Sign in Buttons ---------------------------- */}
        {/* <Flex m="0">
          <Button
            variant="flat"
            alignItems="center"
            backgroundColor={theme.colors.primaryCta}
            minWidth="76px"
          >
            <CgFacebook
              color={theme.colors.white}
              fontSize={theme.fontSizes.large}
            />
          </Button>
          <Button
            variant="flat"
            backgroundColor={theme.colors.primaryCta}
            minWidth="76px"
          >
            <CgGoogle
              color={theme.colors.white}
              fontSize={theme.fontSizes.large}
            />
          </Button>
          <Button
            variant="flat"
            backgroundColor={theme.colors.primaryCta}
            minWidth="76px"
          >
            <SiApple
              color={theme.colors.white}
              fontSize={theme.fontSizes.large}
            />
          </Button>
        </Flex> */}
        {/* <Heading
          variant="hero_sub_heading"
          level="sub"
          m="0"
          addCss={css`
            display: flex;
            &::after,
            &::before {
              content: '';
              flex: 1;
              border-bottom: 1px solid;
              margin: auto;
            }
            &::after {
              margin-left: ${theme.margins.s};
            }
            &::before {
              margin-right: ${theme.margins.s};
            }
          `}
        >
          Register  
        </Heading> */}
        <Form
          buttonText="REGISTER"
          color={theme.colors.white}
          hideSubmitButton
          mb={theme.margins.xs}
          position="relative"
        >
          <Paragraph
            m="0"
            p="0"
            mb={theme.margins.s}
            variant="supporting"
            color={theme.colors.supportingText}
          >
            First Name
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter First Name"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('firstName', { required: true })}
          >
            {errors.firstName && <ErrorMessage error={errors.firstName} />}
          </InputField>
          <Paragraph
            m="0"
            p="0"
            mb={theme.margins.s}
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Last Name
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Last Name"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('lastName', { required: true })}
          >
            {errors.lastName && <ErrorMessage error={errors.lastName} />}
          </InputField>
          <Paragraph
            m="0"
            p="0"
            mb={theme.margins.s}
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Email
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Email"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('email', { required: true, pattern: patterns.email })}
          >
            {errors.email && <ErrorMessage error={errors.email} />}
          </InputField>
          <Paragraph
            m="0"
            mb={theme.margins.s}
            p="0"
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Password
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Password"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('password', { required: true, min: 5 })}
          >
            {errors.password && <ErrorMessage error={errors.password} />}
          </InputField>
          <ErrorHandler error={error} />
        </Form>
        <Button
          variant="outlined"
          mt="0"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
          onClick={() => submit(handleOnFormSubmit)}
          isLoading={isLoading}
        >
          REGISTER
        </Button>
        <ParagraphLink to="/login" text="Already have an account? ">
          Sign In
        </ParagraphLink>
      </Flex>
    </LandingPage>
  );
};

export default Signup;
