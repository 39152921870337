import { css } from '@emotion/react';
import { ReactNodeArray } from 'react';
import theme from '../../theme/theme';
import { Flex } from '../standard-components/layout/flex-box';

const MyShopPageLayout = ({
  children,
  heading,
}: {
  heading?: string;
  children: ReactNodeArray;
}) => {
  return (
    <Flex
      backgroundColor={theme.colors.veryLightGrey}
      flexDirection="column"
      borderRadius={0}
      addCss={css`
        min-height: 100vh;
        min-width: 100vw;
        margin: 0;
      `}
      p={0}
    >
      <Flex
        backgroundColor={theme.colors.BGAdmin}
        overflow="hidden"
        flexDirection="row"
        borderRadius="0"
        flex="1"
        m="0"
        p="0"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default MyShopPageLayout;
