import {
  useGetAllProductsQuery,
  useSearchProuctsMutation,
} from '../../store/products/product-api';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import UploadProduct from '../upload-product';
import ListProducts from '../list-products';
import { MyShopContext } from '../../context/my-shop/MyShopContext';
import EditProduct from '../edit-product';

const Products = ({ storeID }: { storeID: string }) => {
  const [searchInput, setSearchInput] = useState('');

  const { productPageToShow, dispatch, productEditDefault } =
    useContext(MyShopContext)!;
  const [searchProducts, { isLoading }] = useSearchProuctsMutation();
  const { isFetching } = useGetAllProductsQuery(storeID);
  const handleOnSearchSubmit: MouseEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (searchInput.length > 0 && storeID) {
      searchProducts({
        search: searchInput,
        storeID,
        filters: '',
      });
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: 'LIST',
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (searchInput.length === 0) {
      searchProducts({
        search: '',
        storeID,
        filters: '',
      });
    }
  }, [searchInput]);
  const handleSearchInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
  };

  const displayProductComponent = () => {
    switch (productPageToShow) {
      case 'ADD PRODUCT':
        return <UploadProduct storeID={storeID!} />;
      case 'EDIT PRODUCT':
        return (
          <EditProduct
            storeID={storeID!}
            productEditDefault={productEditDefault}
          />
        );
      case 'LIST PRODUCTS':
        return (
          <ListProducts
            isLoading={isLoading || isFetching}
            handleOnSearchSubmit={handleOnSearchSubmit}
            handleSearchInput={handleSearchInput}
            storeID={storeID!}
            searchInput={searchInput}
          />
        );
    }
  };

  return <>{displayProductComponent()}</>;
};

export default Products;
