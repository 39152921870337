/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { MouseEventHandler } from 'react';
import ReactLoading from 'react-loading';
import StandardCSS from '../../../../theme/standard-css';
import DefaultCss from '../../../../theme/standard-default-css';
import StandardProps from '../../../../theme/standard-props';
import theme from '../../../../theme/theme';
import Container from '../../layout/container';
import { Flex } from '../../layout/flex-box';
import Paragraph from '../../typography/paragraph';

const Button = ({
  type,
  addCss,
  variant,
  children,
  disabled,
  isLoading,
  noDefaultStyle,
  onClick,
  ...styles
}: {
  children?: any;
  type?: 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  noDefaultStyle?: boolean;
  addCss?: SerializedStyles;
  variant?: 'flat' | 'outlined' | 'back-arrow' | 'icon';
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
} & StandardProps) => {
  return variant === 'icon' ? (
    <button
      onClick={onClick}
      css={css`
        border: none;
        background-color: transparent;
        cursor: ${!disabled && 'pointer'};
        ${!noDefaultStyle && DefaultCss()}
        ${StandardCSS(styles)};
        ${addCss};
      `}
    >
      {children}
    </button>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      css={css`
        cursor: ${!disabled && 'pointer'};
        ${variant === 'outlined' &&
        css`
          min-width: 192px;
          min-height: 40px;
          border: ${theme.borders.normal};
          border-color: ${theme.colors.background};
          background-color: none;
        `}

        ${variant === 'flat' &&
        css`
          border: none;
          min-width: 192px;
          min-height: 40px;
          background-color: none;
        `}
        ${!noDefaultStyle && DefaultCss()}
        ${StandardCSS(styles)}
        ${addCss}
      `}
    >
      <Flex
        flexDirection="row"
        m="0"
        p="0"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        position="relative"
        width="100%"
      >
        {isLoading ? (
          // <Paragraph
          //   m="0"
          //   ml={theme.margins.s}
          //   p="0"
          //   width="1.4rem"
          //   variant="button"
          // >
          <ReactLoading
            type="spinningBubbles"
            height="20%"
            width="20%"
            color={theme.colors.white}
          />
        ) : // </Paragraph>

        typeof children === 'string' ? (
          <Paragraph
            m="0"
            p="0"
            variant="button"
            textAlign="right"
            flex="1"
            addCss={css`
              font-size: ${styles.fontSize};
            `}
            color={styles.color ? styles.color : theme.colors.white}
          >
            {children}
          </Paragraph>
        ) : (
          <Container
            noDefaultStyle
            m="0"
            p="0"
            textAlign="right"
            flex="1"
            addCss={css`
              font-size: ${styles.fontSize};
            `}
            color={styles.color ? styles.color : theme.colors.white}
          >
            {children}
          </Container>
        )}
      </Flex>
    </button>
  );
};

export default Button;

//Old button stylesheet, we can copy and paste what we still need from here.
/* 
.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #6125C4;
    color: white;
    background: #6125C4;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: ${!disabled && 'pointer' };
    border-radius: 8px;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: #6125C4;
    border-color: #6125C4;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
  }

  .button.disabled:focus {
    outline: none;
  }

  .button.disabled:hover {
    background: #ccc;
    border-color: #ccc;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
  }

  .button.disabled:active {
    background: #ccc;
    border-color: #ccc;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
  }
*/
