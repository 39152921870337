import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LandingPage from '../../components/landing-page-layout/landing-page';
import Button from '../../components/standard-components/elements/button';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import Paragraph from '../../components/standard-components/typography/paragraph';
import { useVerifyUserMutation } from '../../store/auth/auth-api';
import theme from '../../theme/theme';

const ComfirmVerification = () => {
  const { confirmation_code } = useParams();
  const [verifyUser, { isError }] = useVerifyUserMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (confirmation_code) {
      try {
        verifyUser({
          confirmationCode: confirmation_code,
        })
          .unwrap()
          .then((data) => console.log(data));
      } catch (err) {
        console.log(err);
      }
    }
  }, [confirmation_code, verifyUser]);

  return isError ? (
    <LandingPage heroImage="verification-hero.jpg">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Verification Incomplete
        </Heading>
        <Paragraph variant="hero_body" textAlign="center">
          The verification code is incorrect,
          <br /> please check your verification code
        </Paragraph>
        <Button
          mt={theme.margins.m}
          minWidth="240px"
          variant="outlined"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
          onClick={() => navigate('/')}
        >
          GO BACK TO HOME
        </Button>
      </Flex>
    </LandingPage>
  ) : (
    <LandingPage heroImage="verification-hero.jpg">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Verification Complete
        </Heading>
        <Paragraph variant="hero_body" textAlign="center">
          You can now setup your Vincii Profile.
        </Paragraph>
        <Button
          mt={theme.margins.m}
          minWidth="240px"
          variant="outlined"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
          onClick={() => navigate('/onboarding')}
        >
          SETUP MY ACCOUNT
        </Button>
      </Flex>
    </LandingPage>
  );
};

export default ComfirmVerification;
