/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import useOutsideClick from '../../hooks/use-outside-click';
import theme from '../../theme/theme';
import Button from '../standard-components/elements/button';
import CheckBox from '../standard-components/elements/check-box';
import Container from '../standard-components/layout/container';
import { Flex } from '../standard-components/layout/flex-box';
import Paragraph from '../standard-components/typography/paragraph';

const MultipleSelectionDropdownMenuItem = ({
  option,
  callback,
  active,
}: {
  option: string;
  callback?: () => void;
  active?: boolean;
}) => (
  <Container
    borderRadius={0}
    m={0}
    mb="1px"
    width="100%"
    backgroundColor="white"
  >
    <CheckBox label={option} isChecked={active} onClick={callback} />
  </Container>
);

const MultipleSelectionDropdownMenu = ({
  title,
  options,
  currentValues,
  callback,
}: {
  currentValues: string[];
  options: string[];
  title: string;
  callback?: (currentValues: string[], clickedItem: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  const handleItemClick = (item: string) => {
    if (callback) {
      callback(currentValues, item);
    }
  };

  return (
    <Flex p={0} m={0} position="relative" width="100%">
      <div
        ref={wrapperRef}
        css={css`
          width: 100%;
        `}
      >
        <Button
          width="100%"
          variant="flat"
          p={0}
          m="0"
          mt="12px"
          borderRadius={0}
          backgroundColor={theme.colors.white}
          onClick={() => setIsOpen(!isOpen)}
          borderB={`1px solid ${theme.colors.lineGrey}`}
        >
          <Flex p={0} m={0} justifyContent="space-evenly" width="100%">
            <Paragraph
              variant="card_body"
              flex="1"
              m="0"
              p="5px"
              color={theme.colors.dark}
            >
              {title}
            </Paragraph>
            <RiArrowDownSLine
              color={theme.colors.supportingText}
              size="2.5em"
            />
          </Flex>
        </Button>
        {isOpen && (
          <Flex
            flexDirection="column"
            m={0}
            p="3px"
            position="absolute"
            right="0"
            zIndex={100}
            mt="5px"
            ml="10px"
            width="50%"
            height="300%"
            border={`1px solid rgba(0,0,0,.14)`}
            borderRadius="0"
            backgroundColor={theme.colors.lightGrey}
            addCss={css`
              overflow-y: scroll;
            `}
          >
            {options.map((item, index) => {
              return (
                <MultipleSelectionDropdownMenuItem
                  key={item}
                  option={item}
                  callback={() => handleItemClick(item)}
                  active={currentValues.includes(item)}
                />
              );
            })}
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default MultipleSelectionDropdownMenu;
