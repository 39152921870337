import React, { forwardRef, ReactNode } from 'react';
import StandardProps from '../../theme/standard-props';
import theme from '../../theme/theme';
import ErrorMessage from '../standard-components/elements/error';
import InputField from '../standard-components/elements/input-field';
import InputLabel from '../standard-components/elements/input-label';

const ProductInputField = forwardRef<
  HTMLInputElement,
  {
    name: string;
    error: string;
    label: string;
    placeholder: string;
    children?: ReactNode;
    value?: string;
    defaultValue?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  } & StandardProps
>(
  (
    {
      name,
      label,
      value,
      error,
      onBlur,
      onFocus,
      onChange,
      children,
      placeholder,
      defaultValue,
      ...styles
    },
    ref
  ) => {
    return (
      <InputField
        m="0"
        p="0"
        mt={theme.margins.m}
        mb={theme.margins.m}
        pb={theme.margins.xs}
        fontSize={theme.fontSizes.medium}
        htmlId={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value && value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        name={name}
        ref={ref}
        {...styles}
      >
        {error && <ErrorMessage error={error} />}
        {children}
        <InputLabel
          p="0"
          label={label}
          htmlFor={name}
          position="absolute"
          top="-4px"
          ml="0"
          fontSize={theme.fontSizes.small}
          fontWeight={theme.fontWeights.bold}
          left="0"
        />
      </InputField>
    );
  }
);

export default ProductInputField;
