import React from 'react';
import Button from '../standard-components/elements/button';
import { Flex } from '../standard-components/layout/flex-box';
import Heading from '../standard-components/typography/heading';
import Paragraph from '../standard-components/typography/paragraph';
import theme from '../../theme/theme';
import LandingPage from '../landing-page-layout/landing-page';
import { useParams } from 'react-router-dom';

const OnboardingSuccess = () => {
  const { storeID } = useParams();
  return (
    <LandingPage heroImage="onboarding-complete-hero.jpg">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Success
        </Heading>
        <Paragraph variant="hero_body" textAlign="center">
          Your vendor profile has been created.
        </Paragraph>
        <Button
          onClick={() =>
            (window.location.href = `/my-shop/products/${storeID}`)
          }
          mt={theme.margins.m}
          minWidth="240px"
          variant="outlined"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
        >
          ADD PRODUCTS
        </Button>
      </Flex>
    </LandingPage>
  );
};

export default OnboardingSuccess;
