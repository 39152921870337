/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import patterns from '../../constants/validation-regex';
import useInput from '../../hooks/useInput';
import LandingPage from '../../components/landing-page-layout/landing-page';
import { useSigninUserMutation } from '../../store/auth/auth-api';
import theme from '../../theme/theme';
import ErrorHandler from '../../utils/formSubmitErrorHandler';
import Button from '../../components/standard-components/elements/button';
import ErrorMessage from '../../components/standard-components/elements/error';
import Form from '../../components/standard-components/elements/form';
import InputField from '../../components/standard-components/elements/input-field';
import { Flex } from '../../components/standard-components/layout/flex-box';
import Heading from '../../components/standard-components/typography/heading';
import Paragraph from '../../components/standard-components/typography/paragraph';
import ParagraphLink from '../../components/standard-components/typography/paragraph-link';
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';

const Login = () => {
  const navigate = useNavigate();
  const { values, errors, submit, register } = useInput();
  const [signinUser, { error, isLoading, isSuccess }] = useSigninUserMutation();

  const { retailer } = useAuth();
  const handleOnFormSubmit = async () => {
    await signinUser({
      email: values.email,
      password: values.password,
    }).unwrap();
  };
  useEffect(() => {
    isSuccess && navigate('/onboarding', { replace: true });
  }, [isSuccess, navigate, retailer]);

  return (
    <LandingPage
      heroTitle="Become a Vincii Retailer"
      heroSubTitle="Join the Community Today."
      heroImage="landing-hero.jpg"
    >
      <Flex
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        color={theme.colors.white}
      >
        <Heading variant="hero_heading" level="main">
          Welcome Back
        </Heading>
        <Heading variant="hero_sub_heading" level="sub" mt="0" mb="0">
          Sign In To Your Account
        </Heading>
        <Form
          buttonText="LOGIN"
          color={theme.colors.white}
          hideSubmitButton
          mb="0"
          position="relative"
        >
          <Paragraph
            m="0"
            p="0"
            mb={theme.margins.s}
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Email
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Email"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('email', { required: true, pattern: patterns.email })}
          >
            {errors.email && <ErrorMessage error={errors.email} />}
          </InputField>
          <Paragraph
            m="0"
            mb={theme.margins.s}
            p="0"
            variant="supporting"
            color={theme.colors.supportingText}
          >
            Password
          </Paragraph>
          <InputField
            m="0"
            p="0"
            mb={theme.margins.m}
            placeholder="Enter Password"
            backgroundColor="transparent"
            borderRadius="0"
            fontSize={theme.fontSizes.normal}
            addCss={css`
              border-bottom-color: ${theme.colors.underline};
              &:focus {
                color: ${theme.colors.white};
                background-color: transparent;
                border-bottom-color: ${theme.colors.white};
              }
            `}
            {...register('password', { required: true, min: 5 })}
          >
            {errors.password && <ErrorMessage error={errors.password} />}
          </InputField>

          <ErrorHandler error={error} />
        </Form>
        <Button
          variant="outlined"
          mt="0"
          pl={theme.padding.xl}
          pr={theme.padding.xl}
          backgroundColor={theme.colors.primaryCta}
          onClick={() => submit(handleOnFormSubmit)}
          isLoading={isLoading}
        >
          LOGIN
        </Button>

        <ParagraphLink to="/signup" text="Don’t have an account?  ">
          Register Here
        </ParagraphLink>
      </Flex>
    </LandingPage>
  );
};

export default Login;
